import IconBtn from 'components/fragment/iconBtn';
import {
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CloseButton = styled.button`
    border: 1px solid #292929; /* 테두리 색상 및 두께 */
    border-radius: 0px;
    background-color: transparent;
    padding: 0 8px; /* 테두리와 X 간의 간격 */
    cursor: pointer; /* 포인터 커서 */
    position: absolute; /* 절대 위치 */
    top: 50px; /* 상단에서 50px 떨어진 위치 */
    right: 50px; /* 오른쪽 끝에서 50px 떨어진 위치 */

    .btnIcon {
        font-size: 1rem; /* X의 굵기 조절 */
        font-weight: normal; /* 굵게 설정 */
        color: #292929; /* 아이콘 색상 */
        margin: 0px; /* X와 테두리 사이 간격 */
    }
`;

const CloseIconButton = ({ type, ariaLabel, onClick }) => {
    return (
        <CloseButton onClick={onClick} aria-label={ariaLabel}>
            <span className="btnIcon">X</span> {/* X 모양 아이콘 */}
        </CloseButton>
    );
};

const AddDeviceLine = styled.div`
    border: none !important; /* 기본 테두리 제거 */
    box-shadow: 0 0 0 0.1px #191919 !important; /* 테두리 색상 및 두께 */
    position: absolute; /* 절대 위치로 설정 */
    top: 166px; /* 모달 상단에서 166px 떨어진 위치 */
    left: 50px; /* 왼쪽에서 50px 여백 */
    right: 50px; /* 오른쪽에서 50px 여백 */
`;

const PositionedIconBtn = styled(IconBtn)`
    position: absolute;
    top: 50px; /* 상단에서 50px 떨어진 위치 */
    left: 50px; /* 왼쪽에서 50px 떨어진 위치 */
`;

const StyledModalBody = styled(MDBModalBody)`
    place-items: center;      /* 수평 및 수직 중앙 정렬 */
`;

const AddDeviceModal = (props) => {
    const {
        modalTitle,
        showModal,
        toggleModalHandler,
        moveToPrevHandler,
        activePrev,
        btnText,
        setShowModal,
        maxWidth,
    } = props;

    const { t, i18n } = useTranslation(['trans']);

    return (
        <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
            <MDBModalDialog
                centered
                size='lg'
                scrollable
                style={{
                    maxWidth: '1000px',
                }}
            >
                <MDBModalContent
                style={{
                    borderRadius: '0',
                    padding: '80px 26px',
                    /* position: 'relative', */
                    whiteSpace: 'nowrap',
                    }}>
                    <MDBModalHeader className='modal-header' 
                    style={{
                        display: 'flex',
                        justifyContent: 'center', // 가운데 정렬
                           border: 'none'
                        }}
                    >
                        {activePrev ? (
                            <PositionedIconBtn /* <IconBtn */
                            type='prev' onClick={moveToPrevHandler}
                            />
                        ) : (
                            <span></span>
                        )}
                        <MDBModalTitle className='modal-title'
                        style={{
                            fontSize:'2.6rem',
                            color: '#191919',
                            fontWeight: 'bold',
                            }}>
                            {t(modalTitle)}
                        </MDBModalTitle>
                        </MDBModalHeader>
                    <StyledModalBody className='p-4'>
                        {props.children}
                    </StyledModalBody>
                    <CloseIconButton
                            type='close'
                            ariaLabel='Close'
                            onClick={toggleModalHandler}
                        />
                    <AddDeviceLine />
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default AddDeviceModal;
