import { MDBBtn, MDBCardFooter, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface PaymentPlanListProps {
    planList: string[];
    checkIconColor: string;
    buttonColor:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'light'
        | 'dark'
        | 'muted'
        | 'white'
        | 'info'
        | 'none'
        | 'link';
    buttonText: string;
    buttonClickHandler: (event?) => void;
    rating: string;
    planItem;
}

const PaymentPlanList = ({
    planList,
    checkIconColor,
    buttonText,
    buttonColor,
    rating,
    planItem,
    buttonClickHandler,
}: PaymentPlanListProps) => {
    const { t, i18n } = useTranslation(['trans']);

    const planListDisplay =
        planList &&
        planList.map((plan, index) => (
            <li key={plan + index} className='my-3'>
                <MDBIcon
                    fas
                    icon='check'
                    className={`text-${checkIconColor} me-3`}
                />
                {t(plan)}
            </li>
        ));

    return (
        <>
            <ol className='h-100 list-unstyled mb-0 px-4'>{planListDisplay}</ol>
            <MDBCardFooter>
                <div className='text-center'>
                    <MDBBtn
                        color={buttonColor}
                        onClick={buttonClickHandler}
                        id={rating}
                    >
                        {t(buttonText)}
                    </MDBBtn>
                </div>
            </MDBCardFooter>
        </>
    );
};

export default PaymentPlanList;
