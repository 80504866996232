import { PaymentParams, queryPaymentSubscriptionGet } from 'api/payment';
import { useAppSelector } from 'app/hooks';
import UserProfileDisplay from 'components/display/userProfileDisplay';

import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProfileAlarmSettingContainer from './profile/profileAlarmSettingContainer';
import ProfileChangePasswordContainer from './profile/profileChangePasswordContainer';
import ProfileDetailsContainer from './profile/profileDetailsContainer';
import ProfilePaymentContainer from './profile/profilePaymentContainer';

export interface UserProfileContainerProps {
    principalId: string;
}

export interface ValidationObject {
    [prop: string]: {
        validation: boolean;
        loading: boolean;
        message: string;
    };
}

const UserProfileContainer = (props: UserProfileContainerProps) => {
    /* from redux */
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const userData = useAppSelector((state) => state.authState.userData);
    const language = useAppSelector((state) => state.language.language);
    const devices = useAppSelector((state) => state.display.allDeviceList);

    /* local state */
    const [userPayment, setUserPayment] = useState(null);

    useEffect(() => {
        //navigate('/profile');
    }, []);

    async function getPaymentStatus(principalId: string) {
        const paymentResult = await queryPaymentSubscriptionGet(principalId).catch((error) => console.info(error));

        return (paymentResult?.data as PaymentParams) || null;
    }

    useEffect(() => {
        async function run() {
            const result = await getPaymentStatus(checkedUser?.principalId);

            result && setUserPayment(result);
        }

        checkedUser && run();
    }, [checkedUser]);

    return (
        <UserProfileDisplay
            checkedUser={checkedUser}
            userData={userData}
            userPayment={userPayment}
            language={language}
            devices={devices}
        />
    );
};

export default UserProfileContainer;
