import { Document } from '@react-pdf/renderer';
import { Blob } from 'buffer';
import IconBtn from 'components/fragment/iconBtn';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
} from 'mdb-react-ui-kit';

interface PreviewPdfDisplayProps {
    showModal: boolean;
    setShowModal;
    modalTitle: string;
    toggleModalHandler: () => void;
    file: Blob;
}

const PreviewPdfDisplay = (props: PreviewPdfDisplayProps) => {
    const { showModal, setShowModal, modalTitle, toggleModalHandler, file } =
        props;

    return (
        <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
            <MDBModalDialog
                centered
                size='lg'
                scrollable
                style={{
                    maxWidth: '1000px',
                }}
            >
                <MDBModalContent>
                    <MDBModalHeader className='modal-header'>
                        <span></span>
                        <MDBModalTitle className='modal-title'>
                            Report Viewer
                        </MDBModalTitle>
                        <IconBtn
                            type='close'
                            ariaLabel='Close'
                            onClick={toggleModalHandler}
                        />
                    </MDBModalHeader>
                    <MDBModalBody
                        className='px-4 py-0'
                        style={{ minHeight: '1000px' }}
                    >
                        {/* <Document></Document> */}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default PreviewPdfDisplay;
