import { MDBDatatable } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ReportSecondTableProps {
    body;
    loader?: boolean;
    rowSelectClickHandler?;
}

const ReportSecondTable = (props: ReportSecondTableProps) => {
    const { body, loader } = props;

    const { t, i18n } = useTranslation(["trans"]);
    
    const [maxHeight, setMaxHeight] = useState<string>('500px');
    const [sm, setSm] = useState<boolean>(false);

    // @ts-ignore
    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-height: 800px)').matches) {
            setMaxHeight('250px');
            setSm(true);
        } else {
            setMaxHeight('500px');
            setSm(false);
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    const actionData = {
        columns: [
            { label: t("LOCAD0026", "타입"), field: "label" },
            { label: t("LOCAD0027", "평균값"), field: "average" },
            {
                label: t("LOCAD0028", "최소값"),
                field: "min",
            },
            { label: t("LOCAD0029", "최대값 "), field: "max" },
        ],
        rows: (body ? body : []).map((row, index: number) => {
            return { ...row, 
                average: typeof row.average === 'number' ? row.average.toFixed(2) : "-",
                min: typeof row.min === 'number' ? row.min.toFixed(2) : "-",
                max: typeof row.max === 'number' ? row.max.toFixed(2) : "-",
             };
        }),
    };

    return (
        <MDBDatatable
            maxHeight={maxHeight}
            isLoading={loader}
            fixedHeader
            pagination={false}
            hover
            sm={sm}
            data={actionData}
            dark
            className='custom-dark-table'
        />
    );
};

export default ReportSecondTable;
