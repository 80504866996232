/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

const config = {
    // TODO: release
    version: "2.1.0",

    // TODO: new device
    models: {
        "r9iot-thermostat": {
            traitFilter: ["heating", "power", "curtemp", "settemp"],
            alarmFilter: ["curtemp", "settemp"],
        },
        "r9iot-thermometer": {
            traitFilter: ["temperature", "humidity"],
            alarmFilter: ["temperature", "humidity"],
        },
        "r9iot-generalio": {
            traitFilter: ["on1", "on2"],
            alarmFilter: [],
        },
        "r9iot-farmcontroller": {
            traitFilter: ["setposition1", "setposition2", "setposition3", "setposition4"],
            alarmFilter: [],
        },
        "r9iot-heatcable": {
            traitFilter: ["on1", "on2", "curtemp1", "curtemp2"],
            alarmFilter: ["on1", "on2", "curtemp1", "curtemp2", "ext_curtemp"],
        },
        "r9iot-smartsensor": {
            traitFilter: ["temperature", "humidity", "co2", "hn3"],
            alarmFilter: [],
        },
        "r9iot-ssphs": {
            traitFilter: ["temperature", "humidity", "sensor1", "sensor2", "sensor3", "sensor4"],
            alarmFilter: ["temperature", "humidity", "sensor1", "sensor2", "sensor3", "sensor4"],
        },
    },

    googleCloudPlatform: {
        key: "AIzaSyBMXQrZ-wbfePansUYxeqsAoVXZUkNxoYk",
    },

    awsAuth: {
        // REQUIRED - Amazon Cognito Region
        region: "ap-northeast-2",

        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: "ap-northeast-2:462c423a-da32-4792-9eb9-d40a5a700988",
        // identityPoolId: 'ap-northeast-2:ea5ddaff-6144-468b-99a7-31ed7ae26513',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: "ap-northeast-2",

        // User Pools를 통해서 로그인(Hosted UI)시 필요
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "ap-northeast-2_CIZiQJpK6",

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "3c3jciscp94392rdk6ojeq5ug7",
        // userPoolWebClientId: 'bdmj3ld4n1cb1untkmdqqvgmt',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        /*
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },
    */

        // OPTIONAL - customized storage object
        // storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH'

        /*
    oauth: {
      domain: 'dev-lb.raonix.com',
      responseType: 'code',
      scope: ['openid', 'email'],
      redirectSignIn: 'https://nemoahn.auth.ap-northeast-2.amazoncognito.com/oauth2/idpresponse',
      redirectSignOut: 'http://localhost:3000',
    },
     */
    },

    awsAPI: {
        endpoints: [
            {
                name: "RaonixCloud",
                // TODO: release
                endpoint: "https://api.raonix.com/cloud/v1",
                // TODO: debug
                // endpoint: 'https://api.raonix.com/cloud/dev',
                region: "ap-northeast-2",
            },
        ],
    },

    awsStorage: {
        AWSS3: {
            bucket: "raonix-cloud", //REQUIRED -  Amazon S3 bucket
            region: "ap-northeast-2",
        },
    },
};

export default config;
