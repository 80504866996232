import {
    MDBBtn,
    MDBIcon,
    MDBPopconfirm,
    MDBPopconfirmMessage,
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
    btnText?: string;
    btnClassName?: string;
    children?: any;
    btnClick: (event?) => void;
    confirmClick;
    confirmMessage?: string;
}

const ConfirmModal = (props: ConfirmModalProps) => {
    const { t, i18n } = useTranslation(["trans"]);
    const { btnClassName, children='MODAL', btnClick, confirmClick, confirmMessage } =
        props;

    return (
        <MDBPopconfirm
            onClick={btnClick}
            modal
            btnClassName={btnClassName}
            btnChildren={children}
            onConfirm={confirmClick}
            confirmBtnText={t("LOCAD0001","네")}
            cancelBtnText={t("LOCAD0002","아니오")}
        >
            <MDBPopconfirmMessage>
                {confirmMessage || 'Are you sure?'}
            </MDBPopconfirmMessage>
        </MDBPopconfirm>
    );
};

export default ConfirmModal;
