import { queryUserPut } from 'api/user';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ProfileAlarmSettingDisplay from 'components/display/profileAlarmSettingDisplay';
import StackingAlertContainer from 'containers/stackingAlertContainer';
import { CheckedUser, updateUserData, UserData } from 'features/user-slice';
import { useEffect, useState } from 'react';

interface ProfileAlarmSettingProps {
    checkedUser: CheckedUser;
    language: string;
}

const ProfileAlarmSettingContainer = (props: ProfileAlarmSettingProps) => {
    const { checkedUser, language } = props;

    /* from redux */
    const dispatch = useAppDispatch();
    const userData = useAppSelector((state) => state.authState.userData);

    /* local state */
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [stackingCount, setStackingCount] = useState<number>(0);
    const [alertName, setAlertName] = useState<string>('');

    useEffect(() => {
        if (!errorMessage) return;

        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (showError) return;
        setErrorMessage('');
    }, [showError]);

    useEffect(() => {}, [checkedUser]);

    /* handler */
    async function toggleAlarmSettingHandler(event, label) {
        const inputElement = event.target;

        const userPutResult = await queryUserPut(checkedUser.principalId, {
            [inputElement.name]: inputElement.checked,
        }).catch((error) => {
            console.info(error);
        });

        if (!userPutResult) return;

        setStackingCount(stackingCount + 1);
        setAlertName(label);
        dispatch(updateUserData({ ...(userPutResult.data as UserData) }));
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    return (
        <>
            <StackingAlertContainer
                stackingCount={stackingCount}
                name={alertName}
            />
            <ProfileAlarmSettingDisplay
                checkedUser={checkedUser}
                showError={showError}
                errorMessage={errorMessage}
                userData={userData}
                toggleAlarmSetting={toggleAlarmSettingHandler}
                closeError={closeErrorHandler}
                language={language}
            />
        </>
    );
};

export default ProfileAlarmSettingContainer;
