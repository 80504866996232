import { PlaceParams, queryPlacesPost, queryPutPlace } from 'api/place';
import { useAppSelector } from 'app/hooks';
import UpdateLocationInputDisplay from 'components/display/addLocationInputDisplay';
import ConfirmModal from 'components/layout/confirmModal';
import UpdateLocationModal from 'components/layout/updateLocationModal';
import { application } from 'constants/application';
import { putPlaceList, updatePlaceList } from 'features/display-slice';
import { MDBPopconfirm, MDBPopconfirmMessage } from 'mdb-react-ui-kit';
import { geocodeByPlaceId, getLatLng } from 'PlacesAutocomplete/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export interface UpdateLocationContainerProps {
    showModal: boolean;
    setShowModal: any;
    toggleModalHandler: () => void;
    placeList?: PlaceParams[];
    btnText?: string;
    modalTitle?: string;
    edit?: boolean;
    currentPlace?: PlaceParams;
}

export interface Inputs {
    nickname?: string;
    address?: string;
    application?: string;
    addressDetail?: string;
}

export interface GooglePlaceInfo {
    placeId: string;
    address: string;
    latitude: number | string;
    longitude: number | string;
    isGeocoding?: boolean;
}

const UpdateLocationContainer = (props: UpdateLocationContainerProps) => {
    /* state from redux */
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(['trans']);

    const confirmMessage = t("LOCAL0008","Do you want add location?");

    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    /* display state */
    const { showModal, setShowModal, toggleModalHandler, edit, modalTitle, btnText, currentPlace } = props;

    /* funtional state */
    const [inputs, setInputs] = useState<Inputs>();
    const [googlePlaceInfo, setGooglePlaceInfo] = useState<GooglePlaceInfo>(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [postPlaceFn, setPostPlaceFn] = useState(null);

    useEffect(() => {
        setInputs({
            ...inputs,
            application: currentPlace?.application,
            nickname: currentPlace?.nickname,
            address: currentPlace?.address,
        });
    }, [currentPlace, showModal]);

    /* event handler */
    //change select application handler
    function locationInputChangeHandler(event) {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value,
        });
    }

    // location 등록
    // edit prop이 false 일 때, addSubmitHandler가 호출됨
    function addSubmitHandler(event) {
        event.preventDefault();

         if (!window.confirm(confirmMessage)) return;
         /**
          * 여기를  postPlace() 안으로 집어넣고
          * ConfirmModal을 보여줘서 이쁘게 할려고 했는데 ConfirmModal이 안뜬다. 
          * 향후에 시간될때 수정,
          */
         const placeParams: PlaceParams | GooglePlaceInfo = !!googlePlaceInfo
             ? { ...googlePlaceInfo }
             : {
                   nickname: "",
                   address: "",
                   placeId: "",
                   longitude: null,
                   application: "default",
                   latitude: null,
               };

         /* change placeParams if element name is equal with props  */
         [...event.target.elements].forEach((element) => {
             if (!element?.name) return;

             placeParams[element?.name] = element.value;
         });

         async function postPlace() {
             await queryPlacesPost(checkedUser.principalId, placeParams)
                 .then((result) => {
                     //alert("Location has been added successfully.");

                     const { uuid, nickname, placeId, address, latitude, longitude, application }: PlaceParams =
                         result.data;

                     const placeListItem = {
                         uuid,
                         nickname,
                         placeId,
                         address,
                         latitude,
                         longitude,
                         application,
                     };

                     dispatch(updatePlaceList(placeListItem));
                     setShowModal(!showModal);

                     //clear all inputs inside of form element
                     event.target.reset();
                 })
                 .catch((error) => {
                     console.info(error);
                 });
         }

        postPlace();
//        setPostPlaceFn(() => postPlace);
//        setShowModal(true);
    }

    // location 수정
    // edit prop이 true 일 때 호출
    function editSubmitHandler(event) {
        event.preventDefault();

        const confirmMessage = t("LOCAL0004", "장소를 변경하시겠습니까?");

        // if (!googlePlaceInfo) return alert('Select address first.');

        if (!window.confirm(confirmMessage)) return;

        const placeParams: PlaceParams | GooglePlaceInfo = !!googlePlaceInfo
            ? { ...googlePlaceInfo }
            : {
                  nickname: "",
                  address: "",
                  placeId: "",
                  longitude: null,
                  application: "default",
                  latitude: null,
              };

        /* change placeParams if element name is equal with props  */
        [...event.target.elements].forEach((element) => {
            if (!element?.name) return;

            placeParams[element?.name] = element.value;
        });

        async function putPlace() {
            await queryPutPlace(checkedUser.principalId, currentPlace.uuid, placeParams)
                .then((result) => {
                    alert(t("LOCAL0005","Location has been edited successfully."));

                    const { uuid, nickname, placeId, address, latitude, longitude, application }: PlaceParams =
                        result.data;

                    const placeListItem = {
                        uuid,
                        nickname,
                        placeId,
                        address,
                        latitude,
                        longitude,
                        application,
                    };

                    setShowModal(!showModal);
                    dispatch(putPlaceList(placeListItem));
                    //clear all inputs inside of form element
                    event.target.reset();
                })
                .catch((error) => {
                    console.info(error);
                });
        }

        putPlace();
    }

    // address input 값을 변경하면 호출
    function addressChangeHandler(inputValue: string) {
        setInputs({ ...inputs, address: inputValue });
        setGooglePlaceInfo(null);
    }

    // 구글 장소 dropdown item을 클릭할 때 호출
    async function addressSelectHandler(address: string, placeId: string) {
        setInputs({ ...inputs, address: address });

        const searchGeocodeResult = await geocodeByPlaceId(placeId).catch((error) => console.info(error));

        if (!searchGeocodeResult) return;

        interface GeocodeObject {
            lat: number;
            lng: number;
        }

        const geocode: GeocodeObject = await getLatLng(searchGeocodeResult[0]);

        setGooglePlaceInfo({
            placeId,
            address,
            latitude: geocode.lat,
            longitude: geocode.lng,
            // isGeocoding: false,
        });
    }

    return (
        <>
            <UpdateLocationModal
                showModal={showModal}
                setShowModal={setShowModal}
                toggleModalHandler={toggleModalHandler}
                modalTitle={modalTitle}
                btnText={btnText}
                addLocationSubmit={addSubmitHandler}
                editLocationSubmit={editSubmitHandler}
                edit={edit}
            >
                <UpdateLocationInputDisplay
                    data={application}
                    currentPlace={currentPlace}
                    inputChange={locationInputChangeHandler}
                    inputs={inputs}
                    addressChange={addressChangeHandler}
                    addressSelect={addressSelectHandler}
                    edit={edit}
                />
            </UpdateLocationModal>

            {showConfirmModal && (
                /**
                <ConfirmModal
                    btnClick={null}
                    confirmClick={postPlaceFn}
                    confirmMessage={confirmMessage}
                    btnClassName="ms-3 btn-sm btn-danger btn-floating"
                />
                 */
                <MDBPopconfirm
                    open={showConfirmModal}
                    onClick={postPlaceFn}
                    onClose={() => setShowConfirmModal(false)}
                    placement="bottom"
                    btnChildren="DEFAULT"
                >
                    <MDBPopconfirmMessage>This is example</MDBPopconfirmMessage>
                </MDBPopconfirm>
            )}
        </>
    );
};

export default UpdateLocationContainer;
