import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* interface User {
    authState: string;
    userInfo?: Object;
    checkedUser
} */

export type ratingProps = 'free' | 'basic' | 'premium';

export interface UserData {
    uuid: string;
    id: string;
    type: string;
    rating: ratingProps;
    timeZone: string;
    emailNotification: number;
    pushNotification: number;
    smsNotification: number;
    smsCount: number;
    smsMax: number;
    smsCharge: number;
    createdAt: string | Date;
    updatedAt: string | Date;
    aud: string | number;
}

export interface CheckedUser {
    username: string;
    name?: string;
    principalId: string;
    email?: string;
    email_verified?: boolean;
    phone_number?: string;
    phone_number_verified?: boolean;
    sub?: string;
    address?: string;
}

interface UserInfo {
    username: string;
}

export interface UserState {
    authState: string;
    userInfo: any;
    checkedUser: CheckedUser;
    currentLocationId: string;
    userData: UserData;
}

const initialState: UserState = {
    // 현재 접속 상태를 저장함
    authState: '',

    // 사용처 없음
    userInfo: null,

    // user 정보 (전역에서 사용됨)
    checkedUser: null,
    
    // 현재 장소의 uuid
    currentLocationId: '',

    // user 정보 (profile에서 사용됨)
    userData: null,
};

const userSlice = createSlice({
    name: 'authState',
    initialState,
    reducers: {
        //change Auth State
        changeAuthState(state, action: PayloadAction<string>) {
            state.authState = action.payload;
        },

        updateUserInfo(state, action: PayloadAction<any>) {
            state.userInfo = action.payload;
        },

        setCheckedUser(state, action: PayloadAction<CheckedUser>) {
            state.checkedUser = action.payload;
        },

        setCurrentLocationId(state, action: PayloadAction<string>) {
            state.currentLocationId = action.payload;
        },

        updateUserData(state, action: PayloadAction<UserData>) {
            state.userData = action.payload;
        },
    },
});

export const {
    changeAuthState,
    updateUserInfo,
    setCheckedUser,
    setCurrentLocationId,
    updateUserData,
} = userSlice.actions;
export default userSlice.reducer;
