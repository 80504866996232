import {
    DeviceParams,
    DeviceToPlaceParams,
    getDeviceDetailList,
    getDeviceDetailTraits,
    TraitsResult,
} from 'api/device';
import { getPlaceDevices, PlaceParams, queryDeletePlace } from 'api/place';
import { queryWeather } from 'api/weather';
import { useAppSelector } from 'app/hooks';
import LocationDeviceDisplay from 'components/display/locationDeviceDisplay';
import { OutletContext } from 'components/layout/dashBoard';
import DeviceSettingsContainer from 'containers/deviceSettingsContainer';
import UpdateLocationContainer from 'containers/updateLocationContainer';
import {
    deletePlace,
    updateCurrentDevice,
    updateCurrentPlace,
    updateDeviceDetailList,
    updateDeviceList,
    updateDeviceTraits,
} from 'features/display-slice';
import { CheckedUser } from 'features/user-slice';
import useInterval from 'hooks/useInterval';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

/**
 * deviceDetailList 와 traitList 전부 fetch 됐을 때 배열을 반환함.
 * 둘 중 하나만 fetch 된 상태 => null(로딩으로 표시)
 * 둘 다 fetch 된 상태 => array 반환 (두 array가 전부 빈 배열일 경우 빈 array 반환)
 * 
 * @param deviceDetailList
 * @param traitList
 * @returns
 */
export function deviceDetailAndTraitCombine(deviceDetailList: DeviceParams[], traitList: TraitsResult[]): DeviceParams[] {
    if (deviceDetailList?.length <= 0 && traitList?.length <= 0) {
        return [];
    }

    if (deviceDetailList?.length <= 0 || traitList?.length <= 0) {
        return null;
    }

    //console.log("traitList", traitList);

    return deviceDetailList?.map((device) => {
        const findTrait: TraitsResult = (Array.isArray(traitList) ? traitList : [])?.find(
            (find) => find.uuid === device.uuid
        );

        const newTraits = {};

        Object.keys(device.traits).forEach((key) => {
            newTraits[key] = {
                ...device.traits[key],
            };
        });

        if (!findTrait) return null;

        Object.keys(findTrait).map((key: string) => {
            if (key === "uuid") return key;
            return (newTraits[key] = {
                ...newTraits[key],
                ...findTrait[key],
            });
        });

        return { ...device, traits: newTraits };
    });
}

const LocationContainer = (props) => {
    /* props from outlet */
    const context = useOutletContext<OutletContext>();

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    /* props from redux */
    const dispatch = useDispatch();
    const checkedUser: CheckedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentPlaceDeviceList: DeviceToPlaceParams[] = useAppSelector(
        (state) => state.display.currentPlaceDeviceList
    );

    const currentPlaceDeviceDetailList: DeviceParams[] = useAppSelector(
        (state) => state.display.currentPlaceDeviceDetailList
    );
    //useEffect(() => console.log("currentPlaceDeviceList", currentPlaceDeviceList), [currentPlaceDeviceList]);
    //useEffect(() => console.log("currentPlaceDeviceDetailList", currentPlaceDeviceDetailList), [currentPlaceDeviceDetailList]);
    const currentPlaceDeviceTraits: TraitsResult[] = useAppSelector((state) => state.display.currentPlaceDeviceTraits);
    const language = useAppSelector((state) => state.language.language);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    /* props from redux */

    /* props from react-router */
    const navigate = useNavigate();

    /* display state start */
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    /* display state end */

    /* functional state start */
    const [currentPlace, setCurrentPlace] = useState<PlaceParams>(null);
    const [currentDevice, setCurrentDevice] = useState<DeviceParams>(null);
    const [weather, setWeather] = useState(null);

    /* functional state end */

    useEffect(() => {
        if (!_.isEmpty(context.placeList)) {
            const place: PlaceParams = context.placeList.find((place) => place.uuid === context.locationId);

            setCurrentPlace(place);
            dispatch(updateCurrentPlace(place));
        }
    }, [context, dispatch]);

    // currentPlace가 변경될 때마다 날씨정보를 불러옴
    useEffect(() => {
        async function run() {
            const result = await queryWeather(currentPlace?.latitude, currentPlace?.longitude).catch((error) =>
                console.info(error)
            );

            setWeather(result || null);
        }

        currentPlace && run();
    }, [currentPlace]);

    /**
     * deviceList
     * deviceList에 있는 각 device의 uuid로 device detail data를 조회하여 array로 반환
     */
    const setDeviceDetailList = useCallback(
        async (deviceList: DeviceToPlaceParams[]) => {
            const principalId = checkedUser?.principalId;
            if (principalId) {
                const newDetailList = await getDeviceDetailList(checkedUser?.principalId, deviceList);

                return newDetailList || null;
            }
            return null;
        },
        [checkedUser]
    );

    /**
     * deviceList
     * deviceList에 있는 각 device의 uuid로 device의 온도, 습도 data를 조회하여 array로 반환(detail 정보는 포함되지 않음)
     */
    const setDeviceDetailTraits = useCallback(
        async (deviceList: DeviceToPlaceParams[]) => {
            const newTraitResult = await getDeviceDetailTraits(checkedUser?.principalId, deviceList);

            return newTraitResult || null;
        },
        [checkedUser, dispatch]
    );

    /* fetch devices from current place */
    const updatePlaceDevices = useCallback(async () => {
        if (!checkedUser?.principalId || !currentPlace?.uuid) return;

        const result = await getPlaceDevices(checkedUser.principalId, currentPlace?.uuid).catch((error) => {
            console.info("Fail to fetch place devices");
            console.info(error);
        });

        if (!result) return;

        dispatch(updateDeviceList(result));
    }, [checkedUser, currentPlace, dispatch]);

    /* dispatching device list to redux state from current place */
    // 현재 장소가 변경 될 때 마다 device list를 갱신함,
    useEffect(() => {
        async function run() {
            setIsLoading(true);
            await updatePlaceDevices();
        }

        run();
    }, [checkedUser, currentPlace, updatePlaceDevices]);

    const loaderOff = () => {
        if (currentPlaceDeviceDetailList?.length === currentPlaceDeviceTraits?.length) {
            setIsLoading(false);
        } else if (!currentPlaceDeviceDetailList || !currentPlaceDeviceTraits) {
            setIsLoading(false);
        }
    };

    /* As device list is fetched, dispatching device details and detail traits to redux state */
    // device list가 갱신되면, device detail list와 device traits list를 갱신함
    useEffect(() => {
        async function run() {
            const detailResult = await setDeviceDetailList(currentPlaceDeviceList);
            const traitResult = await setDeviceDetailTraits(currentPlaceDeviceList);

            dispatch(updateDeviceDetailList(detailResult));
            dispatch(updateDeviceTraits(traitResult));

            loaderOff();
        }
        run();
    }, [currentPlaceDeviceList, setDeviceDetailList]);

    /* handler start */
    /* thermometer modal display toggle */
    function toggleSettingsModalHandler(_, device: DeviceParams) {
        setShowSettingsModal(!showSettingsModal);
        setCurrentDevice(device ?? null);
    }

    /* set current device state to redux state when device navigation icon is clicked*/
    function deviceButtonClickHandler(device: DeviceParams) {
        dispatch(updateCurrentDevice(device));
    }

    // 장소 추가/수정 modal
    function updateLocationModalClickHandler() {
        setShowUpdateModal(!showUpdateModal);
    }

    // 장소 삭제
    function deleteClickHandler() {
        const message = t("LOCAL0006", "장소를 삭제하시겠습니까?");

        if (!window.confirm(message)) return;

        async function requestDeletePlace() {
            await queryDeletePlace(checkedUser.principalId, currentPlace.uuid)
                .then((result) => {
                    dispatch(deletePlace(currentPlace.uuid));
                    alert(t("LOCAL0007", "장소가 삭제되었습니다."));

                    /* navigate to index after delete */
                    navigate("index", { replace: true });
                })
                .catch((error) => console.info(error));
        }

        requestDeletePlace();
    }
    /* handler end */

    /* 30초에 한 번씩 현재 location의 device data를 갱신함*/
    useInterval(() => {
        setDeviceDetailTraits(currentPlaceDeviceList);
    }, 30000);

    let combinedDeviceDetail = deviceDetailAndTraitCombine(currentPlaceDeviceDetailList, currentPlaceDeviceTraits);

    /**
     * 현재 버그있음
     * 화면 전환 이후 navbar를 통해 다시 이 container를 render 할 경우
     * currentPlaceDeviceList와 currentPlaceDeviceDetailList이 연동되지않음
     */
    if (combinedDeviceDetail && currentPlaceDeviceList.length === 0) {
        combinedDeviceDetail = [];
    }

    return (
        <>
            <LocationDeviceDisplay
                isLoading={isLoading}
                currentPlaceDetailList={combinedDeviceDetail}
                placeList={context.placeList}
                updateLocationModalClick={updateLocationModalClickHandler}
                deleteClick={deleteClickHandler}
                currentPlace={currentPlace}
                toggleSettingsModal={toggleSettingsModalHandler}
                deviceButtonClick={deviceButtonClickHandler}
                weather={weather}
                language={language}
            />
            {context.placeList.length <= 0 ? (
                <UpdateLocationContainer
                    toggleModalHandler={updateLocationModalClickHandler}
                    showModal={showUpdateModal}
                    setShowModal={setShowUpdateModal}
                    modalTitle="장소 추가"
                    btnText="저장"
                />
            ) : (
                <UpdateLocationContainer
                    toggleModalHandler={updateLocationModalClickHandler}
                    showModal={showUpdateModal}
                    setShowModal={setShowUpdateModal}
                    modalTitle={`${currentPlace?.nickname}`}
                    btnText="수정"
                    currentPlace={currentPlace}
                    edit
                />
            )}
        </>
    );
};

export default LocationContainer;
