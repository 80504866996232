import { DeviceParams } from 'api/device';
import { ReportParams } from 'api/report';
import ConfirmModal from 'components/layout/confirmModal';
import ReportAutoSetContainer from 'containers/reportAutoSetContainer';
import { MDBBtn, MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icButtonGraph from 'assets/img/icons/ic_button_graph.png';
import icButtonSetting from 'assets/img/icons/ic_button_setting.png';

interface ReportListTableProps {
    body: any;
    loader: boolean;
    moveClick;
    rowSelectClickHandler;
}

const ReportListTable = (props: ReportListTableProps) => {
    const { body, loader, moveClick, rowSelectClickHandler } = props;

    console.log("body", body);
    const { t, i18n } = useTranslation(['trans']);
    const [maxHeight, setMaxHeight] = useState<string>('500px');
    const [sm, setSm] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    // @ts-ignore
    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-height: 800px)').matches) {
            setMaxHeight('250px');
            setSm(true);
        } else {
            setMaxHeight('500px');
            setSm(false);
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    const dummy = [
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
    ];

    const toggleSettingModalHandler = () => {
        setShowModal(!showModal);
    }
    const actionData = {
        columns: [
            { label: '#', field: 'index' },
            { label: t("LOCAD0040", "이름"), field: "title" },
            { label: t("LOCAD0041", "구분"), field: "deviceType" },
            { label: t("LOCAD0042", "시작일"), field: "startDate" },
            { label: t("LOCAD0043", "종료일"), field: "endDate" },
            { label: "", field: "buttons", sort: false },
        ],
        rows: (body ? body : []).map((row: ReportParams, index: number) => {
            return {
                index: index+1,
                ...row,
                startDate: new Date(row.startDate).toLocaleString(),
                endDate: new Date(row.endDate).toLocaleString(),
                buttons: (
                    <>
                        <MDBBtn
                            outline
                            size="sm"
                            floating
                            className="call-btn"
                            onClick={(event) => {
                                moveClick(row.reportUuid);
                            }}
                            style={{
                                background: "transparent",
                                boxShadow: "none",
                                width: "32px",
                                height: "32px",
                                border: "none"
                            }}
                        >
                            {/**
                            <MDBIcon fas icon="arrow-right" />
                             */}
                             <img src={icButtonGraph} alt="" />
                        </MDBBtn>
                        <MDBBtn
                            size="sm"
                            className="ms-3"
                            floating
                            onClick={() => {
                                // currentDeviceSettingClickHandler(row);
                                toggleSettingModalHandler();
                            }}
                            style={{
                                background: "transparent",
                                boxShadow: "none",
                                width: "32px",
                                height: "32px",
                                border: "none"
                            }}
                        >
                            {/**
                            <MDBIcon fas icon="cog" />
                             */}
                             <img src={icButtonSetting} alt="" />
                        </MDBBtn>
                    </>
                ),
            };
        }),
    };

    return (
        <>
            <MDBDatatable
                // search
                multi
                selectable
                searchInputProps={{ className: "m-0" }}
                maxHeight={maxHeight}
                isLoading={loader}
                fixedHeader
                fullPagination
                hover
                sm={sm}
                data={actionData}
                onSelectRow={rowSelectClickHandler}
                dark
                className='custom-dark-table'
            />
            <ReportAutoSetContainer
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={"자동 리포트"}
                toggleModalHandler={toggleSettingModalHandler}
            />
        </>
    );
};

export default ReportListTable;
