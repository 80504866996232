import { DeviceParams, Traits, TraitsResult } from 'api/device';
import { MDBBtn, MDBCard, MDBCardFooter, MDBCardTitle, MDBIcon, MDBSwitch } from 'mdb-react-ui-kit';
import { mollier } from 'mollier';
import React, {useState, useEffect, useMemo} from 'react';
import { NavLink } from 'react-router-dom';
import { findTrait } from 'utils';
import IconBtn from '../iconBtn';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import config from 'config';
import r9iot, { getChildState } from 'api/r9iot';
import { Icon } from '@mui/material';

export interface HeatcableFragProps {
    device?: DeviceParams;
    deviceTraits?: TraitsResult;
    toggleSettingsModalHandler?: (event?) => void;
    deviceButtonClick?: (event?) => void;
    hover?: boolean;
}

const GeneralIOInitialData = {
    curtemp: null,
    switches: []
}
const GeneralioFrag = (props: HeatcableFragProps) => {
    const { toggleSettingsModalHandler, hover, device, deviceButtonClick } =
        props;

    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    const [childState, setChildState] = useState(GeneralIOInitialData);

    const curtemp1: Traits = findTrait(device?.traits, 'curtemp1');
    const curtemp2: Traits = findTrait(device?.traits, 'curtemp2');
    const on1: Traits = findTrait(device?.traits, 'on1');
    const on2: Traits = findTrait(device?.traits, 'on2');

    const mollierResult = mollier(
        curtemp2?.currentValue,
        curtemp1?.currentValue
    );

    const getState = () => {
        getChildState(checkedUser.principalId, device.id)
        .then (response => {
            if (response.status === 200) {
                setChildState(response?.data);
            }
        }).catch(error => {
            console.error(error);
            setChildState(GeneralIOInitialData);
        })
    };

    useEffect(() => {
        getState();
    }, [checkedUser, device]);

    const handleChange = (name, value) => {
        let model;

        if (config.models.hasOwnProperty(device.modelCode)) {
            model = config.models[device.modelCode];
        }

        if (model.traitFilter.includes(name)) {
            let swidx = parseInt(name.match(/\d+/g));
            let key = name.match(/[a-zA-Z]+/g);

            const params = {
                switches: [
                    {
                        swidx,
                        [key]: value,
                    },
                ],
            };
            r9iot.setChildState(checkedUser.principalId, device.id, params)
            .then(() => {
                getState();
            })
            .catch((error) => console.error(error));
        }
    };

    const renderTraitOutput = output => {
        return (
            <div className="p-2">
                <small>
                    <strong>Output{output.swidx}</strong>
                </small>
                <MDBSwitch
                    id="flexSwitchCheckDefault"
                    defaultChecked={output.on}
                    onClick={() => {
                        handleChange("on1", !output.on); // 현재상태와 반대값
                    }}
                />
                <MDBBtn onClick={() => {}}>
                    <MDBIcon far icon="calendar-alt" />
                </MDBBtn>
            </div>
        );
    };

    const outputs = useMemo(() => {
        if (childState.switches !== undefined && childState.switches.length > 0) {
            return childState.switches.map(output => renderTraitOutput(output));
        }
    }, [childState]);


    return (
        <MDBCard className="thermometer-frag border" style={{ width: "300px" }}>
            {/** 메인아이콘 */}
            <div className="text-center my-3">
                <i className="fas fa-rainbow icon-card-logo text-gray"></i>
            </div>

            {/** 장치 이름, 시리얼 */}
            <div className="text-center">
                <MDBCardTitle className="m-0">{device?.nickname}</MDBCardTitle>
                <small>serial: {device?.deviceInfo.serial}</small>
            </div>

            {/** currenttemp */}
            <div className="d-flex justify-content-evenly align-items-center text-center">
                <div className="p-2">
                    <small>
                        <strong>temp</strong>
                    </small>
                    <h2 className="text-primary">
                        <strong>{(childState?.curtemp + "°").toString()}</strong>
                    </h2>
                </div>
            </div>

            {/** traits */}
            <div className="d-flex justify-content-evenly align-items-center text-center">
                {outputs}
                {/**
                <div className="p-2">
                    <small>
                        <strong>Output1</strong>
                    </small>
                    <MDBSwitch
                        id="flexSwitchCheckDefault"
                        defaultChecked={on1?.currentValue === "true" ? true : false}
                        onClick={() => {
                            handleChange("on1", on1?.currentValue === "true" ? false : true); // 현재상태와 반대값
                        }}
                    />
                </div>
                <div className="p-2">
                    <small>
                        <strong>Output2</strong>
                    </small>
                    <MDBSwitch
                        id="flexSwitchCheckDefault"
                        defaultChecked={on2?.currentValue === "true" ? true : false}
                        onClick={() => {
                            handleChange("on2", on1?.currentValue === "true" ? false : true); // 현재상태와 반대값
                        }}
                    />
                </div>
                 */}
            </div>

            {/** 하단 버튼 그룹 */}
            <MDBCardFooter className="justify-content-evenly p-0">
                <NavLink to="/dashboard/alarms">
                    <IconBtn onClick={deviceButtonClick} type="alarm" size="lg"></IconBtn>
                </NavLink>
                <NavLink to="/dashboard/reports">
                    <IconBtn onClick={deviceButtonClick} type="report" size="lg"></IconBtn>
                </NavLink>
                <IconBtn size="lg" onClick={toggleSettingsModalHandler} type="settings"></IconBtn>
            </MDBCardFooter>
        </MDBCard>
    );
};

export default React.memo(GeneralioFrag);
