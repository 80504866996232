import { NavLink } from 'react-router-dom';

const NotSupportedDisplay = (props) => {
    return (
        <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-center'>
                <p>Not supported display.</p>
                <NavLink to='/'>
                    <button className='btn btn-warning'>Home</button>
                </NavLink>
            </div>
        </div>
    );
};

export default NotSupportedDisplay;
