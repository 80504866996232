import { API } from 'aws-amplify';

const apiName = 'RaonixCloud';

export interface HubParams {
    uuid: string;
    id: string;
    type: string;
    idx: number;
    subidx: number;
    name: string;
    jid: number;
}

export interface HubChildMode {
    enable?: number;
    mintemp?: number;
    maxtemp?: number;
    minhumi?: number;
    maxhumi?: number;
    deltatemp?: number;
    deltahumidity?: number;
    reporttime?: number;
    wakeuptime?: number;
    rfwakeuptime?: number;
    rfduration?: number;
    mask?;
}

export interface HubChildState {
    idx: number;
    devid: string;
    connected: number;
    initialized: number;
    rssi: number;
    alarmmintemp: number;
    alarmmaxtemp: number;
    alarmminhumi: number;
    alarmmaxhumi: number;
    alarmlowvoltage: number;
    temperature: number;
    humidity: number;
    batteryvoltage: number;
    mode: HubChildMode;
}

export type HubDeviceParams = HubParams;

export function getHub(principalId, hubId) {
    let uri = `/r9iot/hubs/${hubId}`;

    return API.get(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

/**
 *
 * @param principalId
 * @param hubId
 * @returns HubParams
 */
export function getHubDevices(principalId, hubId) {
    let uri = `/r9iot/hubs/${hubId}/devices`;

    return API.get(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

export function getHubChildren(principalId, hubId) {
    let uri = `/r9iot/hubs/${hubId}/children`;

    return API.get(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

export function deleteHubChildren(principalId, id, unpairing = true) {
    const encodedId = encodeURIComponent(id);
    const uri = `/r9iot/children/${encodedId}`;
    return API.del(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        queryStringParameters: {
            unpairing,
        },
        response: true,
    });
}

export function getChildState(principalId, id) {
    const encodedId = encodeURIComponent(id);
    let uri = `/r9iot/children/${encodedId}/state`;
    return API.get(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

export function setChildState(principalId, id, params) {
    const encodedId = encodeURIComponent(id);
    let uri = `/r9iot/children/${encodedId}/state`;
    return API.put(apiName, uri, {
        headers: {
            principalId,
        },
        response: true,
        body: params,
    });
}

export default {
    getHub,
    getHubDevices,
    getHubChildren,
    deleteHubChildren,
    getChildState,
    setChildState,
};
