import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ThemeProvider, createTheme, useColorScheme } from '@mui/material/styles';
import { Application } from 'constants/application';
import { MDBInputGroup } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface NormalInputSectionProps {
    id: string;
    label: string;
    type: string;
    from?: string;
    name: string;
    placeholder?: string;
    value?: string | number;
    defaultValue?: string | number;
    required?: boolean;
    horizontal?: boolean;
    small?: boolean;
    readonly?: boolean;
    data?: Application[];
    message?: string;
    messageClassName?: string;
    children?: React.ReactNode;
    inputChange?: (event?, name?: string) => void;
    valueChanged?: (event) => void;
    focusOut?: (event, name: string) => void;
    inputClick?: (event, callback?) => void;
    inputElementInput?: (event) => void;
    disabled?: boolean;
}

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: "transparent",
    color: "#fff",
    borderColor: "#fff",
    borderRadius: "0 !important",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff !important",
        borderWidth: "1px !important",
    },
    "& .MuiSelect-icon": {
        color: "#fff",
    },
}));

const StyledInput = styled.input`
`

const NormalInputSection = (props: NormalInputSectionProps) => {
    const {
        id,
        label,
        type,
        from,
        name,
        placeholder,
        value,
        required,
        horizontal,
        small,
        readonly,
        data,
        defaultValue,
        message,
        children,
        messageClassName,
        inputChange,
        valueChanged,
        focusOut,
        inputClick,
        inputElementInput,
        disabled = false,
    }: NormalInputSectionProps = props;

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    let defaultClassName = "input-section";
    let additionalStyles = {
        justifyContent: 'flex-start',
        gap: '1rem'
    };

    if (horizontal) {
        defaultClassName += " horizontal";
    } else if (small) {
        defaultClassName += " small";
    }

    let typeInput;

    switch (type) {
        case "number":
            typeInput = (
                <StyledInput
                    type={type}
                    className="form-control"
                    name={name}
                    id={id}
                    placeholder={t(placeholder)}
                    required={required}
                    min={0}
                    defaultValue={defaultValue}
                    value={value}
                    readOnly={readonly}
                    onChange={(event) => (inputChange ? inputChange(event, name) : null)}
                    onBlur={(event) => (focusOut ? focusOut(event, name) : null)}
                    disabled={disabled}
                />
            );
            break;
        case "select":
            typeInput = (
                <FormControl className="m-0 w-100" size="small">
                    {/**
                    <InputLabel id={`select-${name}`}>{t(label)}</InputLabel>
                     */}
                    <StyledSelect
                        labelId={`select-${name}`}
                        id={name}
                        inputProps={{ name }}
                        defaultValue={value}
                        value={value}
                        //label={t(label)}
                        label={""}
                        className="w-100"
                        onChange={valueChanged}
                        style={{color:"#fff"}}
                        disabled={disabled}
                    >
                        {data?.map((item: Application, index: number) => {
                            return (
                                <MenuItem value={item.value} key={item.value + "_" + index}>
                                    {item.text}
                                </MenuItem>
                            );
                        })}
                    </StyledSelect>
                </FormControl>
            );
            break;
        case "location-select":
            typeInput = (
                <FormControl className="m-0 w-100" size="small">
                    <InputLabel id="select-application">{t(label)}</InputLabel>
                    <Select
                        labelId="select-application"
                        id="application"
                        inputProps={{ name: "application" }}
                        value={value}
                        label={t(label)}
                        className="w-100"
                        onChange={valueChanged}
                        disabled={disabled}
                    >
                        {data.map((item: Application, index: number) => {
                            return (
                                <MenuItem value={item.value} key={item.value + "_" + index}>
                                    {item.text}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            );
            break;
        case "text":
        default:
            typeInput = (
                <StyledInput
                    type={type}
                    className="form-control"
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    required={required}
                    value={value}
                    defaultValue={defaultValue}
                    readOnly={readonly}
                    onChange={(event) => (inputChange ? inputChange(event, name) : null)}
                    onBlur={(event) => (focusOut ? focusOut(event, name) : null)}
                    onClick={inputClick}
                    onInput={inputElementInput}
                    disabled={disabled}
                />
            );
    }

    return (
        <div className={defaultClassName} style={additionalStyles}>
            <label
                className="form-label m-0 mb-1"
                htmlFor={id}
                style={{
                    color: from === "setting" ? "#fff" : "#000",
                }}
            >
                {t(label)}
            </label>
            <MDBInputGroup>
                {typeInput}
                {children ? children : ""}
            </MDBInputGroup>
            {message ? <small className={messageClassName || "text-danger"}>{message}</small> : ""}
        </div>
    );
};

export default NormalInputSection;
