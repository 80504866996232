import { TraitsResult } from 'api/device';
import {
    queryGetReports,
    queryPostReport,
    queryReportRecords,
    ReportParams,
} from 'api/report';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ReportDisplay from 'components/display/reportDisplay';
import { DateSearchQuery } from 'containers/dateSearchContainer';
import dayjs from 'dayjs';
import { updateCurrentDevice } from 'features/display-slice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTraitByName } from 'utils/report';
import PreviewPdfContainer from './previewPdfContainer';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

const needTrait = ['temperature', 'humidity'];

const WRITERS = {
    thermometer: 'ThermometerTemperatureHumidity',
    heatcable: 'HeatcableTemperature',
};

const ListReportContainer = (props) => {
    const { t } = useTranslation(['trans']);
    /* from redux */
    const navigate = useNavigate();

    /* from redux */
    const dispatch = useAppDispatch();
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentDevice = useAppSelector(
        (state) => state.display.currentDevice
    );
    const currentReport = useAppSelector(
        (state) => state.display.currentReport
    );

    /* display state */
    const [report, setReport] = useState<ReportParams>({
        description: '',
        deviceType: '',
        startDate: '',
        endDate: '',
        title: '',
        traits: {},
        records: {},
        writer: '',
    });
    const [reportList, setReportList] = useState<{
        [prop: string]: ReportParams;
    }>();
    const [loader, setLoader] = useState<boolean>(false);
    const [tempReport, setTempReport] = useState<ReportParams>();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(updateCurrentDevice(null));
    }, []);

    useEffect(() => {
        if (!currentReport) {
            navigate(-1);
        }
    }, [currentReport, navigate]);

    /**
     * report Params에 맞게 traits를 변형시킴
     */
    async function getReportRecords(principalId: string, report: ReportParams) {
        const recordsResult = await queryReportRecords(
            principalId,
            report
        ).catch((error) => console.info(error));

        return recordsResult || null;
    }

    useEffect(() => {
        if (!currentReport) return;

        console.log("current report", currentReport);

        async function run() {
            const recordsResult = await getReportRecords(
                checkedUser.principalId,
                currentReport
            );

            setReport({
                ...currentReport,
                records: { ...recordsResult },
            });
        }

        currentReport && run();
    }, [currentReport]);

    async function deleteReport(traits: TraitsResult) {
        //모든 report를 먼저 조회함

        let findDeleteUuid: string;

        // console.log(findDeleteUuid);
    }
    /* handler start */
    async function reportSearchSubmitHandler(event, query: DateSearchQuery) {
        event.preventDefault();

        const newParams: ReportParams = {
            ...report,
            records: null,
            startDate: query.start,
            endDate: query.end,
        };

        // 30일 이상, 조건을 알 수 없음. 그러나 wide range date, 혹은 data를 post 할 경우 413 error 발생 - 논의 필요
        // records 데이터를 초기화 하지 않고 newParams를 입력하기에 현재 데이터 초과 오류 발생함
        //todo -- 만약 같은 startdate, enddate, trait uuid 가 존재한다면, post 하지않고 get으로 데이터 가져오기
        const reportPostResult = await queryPostReport(
            checkedUser.principalId,
            newParams
        ).catch((error) => console.info(error));

        if (!reportPostResult) return;

        const recordsResult = await queryReportRecords(
            checkedUser.principalId,
            reportPostResult.data
        ).catch((error) => console.info(error));

        setReport({
            ...report,
            records: { ...recordsResult },
            startDate: query.start,
            endDate: query.end,
        });
    }

    function toggleModalHandler() {
        setShowModal(!showModal);
    }

    function csvDownloadClickHandler(event) {
        if (window.confirm('Download csv file?')) {
            return true;
        }

        return false;
    }

    const csvFileName = 'report_' + dayjs().format();
    /* handler end */

    return (
        <>
            <ReportDisplay
                currentDevice={currentDevice}
                reportSearchSubmit={reportSearchSubmitHandler}
                report={report}
                toggleModalHandler={toggleModalHandler}
                csvDownloadClickHandler={csvDownloadClickHandler}
                csvFileName={csvFileName}
                // reportListItemClick={reportListItemClickHandler}
            ></ReportDisplay>
            <PreviewPdfContainer
                //preview={tempReport}
                preview={currentReport}
                showModal={showModal}
                setShowModal={setShowModal}
                toggleModalHandler={toggleModalHandler}
            />
        </>
    );
};

export default ListReportContainer;
