import { queryDeleteReport, queryGetReports, ReportParams } from 'api/report';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ReportListDisplay from 'components/display/reportListDisplay';
import { updateCurrentReport } from 'features/display-slice';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ReportListContainer = (props) => {
    /* from react-router */
    const navigate = useNavigate();

    /* from redux */
    const dispatch = useAppDispatch();
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    /* local state */
    const [reportList, setReportList] = useState<ReportParams[]>();
    const [reportListObject, setReportListObject] = useState<{
        [reporUuid: string]: ReportParams;
    }>(null);
    const [selectedRow, setSelectedRow] = useState<ReportParams[]>();
    const [deleted, setDeleted] = useState<boolean>(false);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    async function getAllReports() {
        const reportGetResult = await queryGetReports(
            checkedUser.principalId
        ).catch((error) => console.info(error));

        if (!reportGetResult?.data) return null;

        return reportGetResult.data;
    }

    useEffect(() => {
        async function run() {
            const result = await getAllReports();

            if (!result) return;

            const arrayed = Object.keys(result).map((key) => {
                return {
                    ...result[key],
                    reportUuid: key,
                };
            });

            setReportListObject(result);
            setReportList(arrayed);
        }

        checkedUser && run();
        deleted && run();
    }, [checkedUser, deleted]);

    /* handler */
    function reportMoveClickHandler(reportUuid: string) {
        dispatch(updateCurrentReport({...reportListObject[reportUuid], uuid:reportUuid}));
        navigate('report');
    }

    function rowSelectClickHandler(rows, _, allSelected: boolean) {
        setSelectedRow(rows);
        setAllSelected(allSelected);
    }

    async function deleteReport(principalId: string, reportUuid: string) {
        const deleteResult = await queryDeleteReport(
            principalId,
            reportUuid
        ).catch((error) => console.info(error));

        return deleteResult || null;
    }

    async function deleteReportHandler() {
        setDeleted(false);

        if (!selectedRow || selectedRow?.length <= 0)
            return alert('Please check Report first.');

        if (!window.confirm('Are you sure to delete selected reports? '))
            return;

        const promiseList = selectedRow.map((row) => {
            return deleteReport(checkedUser.principalId, row.reportUuid).catch(
                (error) => console.info(error)
            );
        });

        const result = await Promise.all(promiseList);

        if (_.isEmpty(result)) return;

        setDeleted(true);
        alert('Deleted successfully.');

        // 강제로 전체선택을 두번 클릭함
        const allSelectButton = document.querySelectorAll(
            '[type=checkbox]'
        )[0] as HTMLElement;
        if (allSelected) {
            allSelectButton.click();
        } else {
            allSelectButton.click();
            allSelectButton.click();
        }
    }

    return (
        <ReportListDisplay
            reportList={reportList}
            loader={false}
            moveClickHandler={reportMoveClickHandler}
            rowSelectClickHandler={rowSelectClickHandler}
            deleteReportHandler={deleteReportHandler}
        />
    );
};

export default ReportListContainer;
