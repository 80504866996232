import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface BillingCardProps {
    title: string;
    headerColor: string;
    children?;
    className?: string;
    style?: Object;
}

const headerColorType = {
    primary: 'primary',
    danger: 'danger',
    secondary: 'secondary',
};

const BillingCard = ({
    title,
    headerColor,
    children,
    className,
    style,
}: BillingCardProps) => {
    const { t, i18n } = useTranslation(['trans']);

    return (
        <MDBCard className={`paymentCard ${className}`}>
            <MDBCardHeader
                className={`bg-${
                    headerColorType[headerColor] || 'primary'
                } text-white`}
            >
                {t(title)}
            </MDBCardHeader>
            <MDBCardBody className='p-0' style={style}>
                {children}
            </MDBCardBody>
        </MDBCard>
    );
};

export default BillingCard;
