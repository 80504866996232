import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentParams } from 'api/payment';

export interface PaymentState {
    paymentData: PaymentParams;
}

const initialState: PaymentState = { paymentData: null };

// 사용되지 않음
const paymentSlice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        updatePaymentData(state, action: PayloadAction<PaymentParams>) {
            state.paymentData = action.payload;
        },
    },
});

export const { updatePaymentData } = paymentSlice.actions;
export default paymentSlice.reducer;
