import { PlaceParams } from 'api/place';
import AddressInputSelect from 'components/fragment/addressSelectInput';
import NormalInputSection from 'components/fragment/normalInputSection';
import { Application } from 'constants/application';
import { Inputs } from 'containers/updateLocationContainer';
import { useTranslation } from 'react-i18next';

export interface UpdateLocationInputDisplayProps {
    data: Application[];
    inputs?: Inputs;
    valueChanged?: (event) => void;
    currentPlace?: PlaceParams;
    defaultInputs?: any;
    inputChange?: (event?, _?) => void;
    addressChange?;
    addressSelect?;
    edit: boolean;
}

const UpdateLocationInputDisplay = (props: UpdateLocationInputDisplayProps) => {
    const { data, inputs, inputChange, addressChange, addressSelect, edit } =
        props;
    const { t, i18n } = useTranslation(['trans']);

    return (
        <>
            <NormalInputSection
                id='locationName'
                label={'Name'}
                type={'text'}
                name={'nickname'}
                value={inputs?.nickname}
                placeholder={`${t('Location')} ${t('Name')}`}
                horizontal
                inputChange={(event) => inputChange(event)}
                required
            />
            <NormalInputSection
                id='locationType'
                label={'Location-Type'}
                type={'location-select'}
                name={'application'}
                data={data}
                horizontal
                required
                valueChanged={inputChange}
                value={inputs?.application}
            />
            <AddressInputSelect
                value={inputs?.address}
                searchOptions={{ types: ['geocode'] }}
                onChange={addressChange}
                onSelect={addressSelect}
                edit={edit}
            />
        </>
    );
};

export default UpdateLocationInputDisplay;
