import { useMemo, useEffect, useState } from "react";
import { DeviceParams, TraitsResult } from "api/device";
import { PlaceParams } from "api/place";
import CurrentTemperature from "components/fragment/currentTemperature";
import NoResult from "components/fragment/noResult";
import Spinner from "components/fragment/spinner";
import ThermometerFrag from "components/fragment/device/ThermometerFrag";
import AddDeviceContainer from "containers/location/addDeviceContainer";
import { MDBBtn, MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
//import HeatcableFrag from "components/fragment/device/HeatcableFrag";
import GeneralioFrag from "components/fragment/device/GeneralioFrag";
import SsphsFrag from "components/fragment/device/SsphsFrag";
import styled from "@emotion/styled";
import { COLOR_MAIN_BG } from "constants/common";
import HeatcableFragNew from "components/fragment/device/HeatcableFragNew";

export interface LocationDeviceDisplayProps {
    placeList?: PlaceParams[];
    updateLocationModalClick?: (event) => void;
    deleteClick?: (event) => void;
    deviceButtonClick?: (event) => void;
    currentPlace: PlaceParams;
    toggleSettingsModal?: (event, device) => void;
    currentPlaceDetailList?: DeviceParams[];
    currentPlaceDeviceTraits?: TraitsResult[];
    isLoading?: boolean;
    weather?;
    language: string;
}

const BackgroundContainer = styled.div`
    background-color: ${COLOR_MAIN_BG}; /* 배경색을 회색으로 설정 */
    //min-height: 100vh; /* 최소 높이를 설정하여 전체 화면을 채우도록 설정 */
    //display: flex;
    //justify-content: center;
    //align-items: center;
`;

const StyledMDBBtn = styled(MDBBtn)`
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    padding: 0 !important;
    box-shadow: none !important; /* 그림자 제거 */
    //margin: 0.5rem;
    &:hover {
        background-color: transparent !important;
    }
    font-size: 1rem;
    font-weight: bold;
`;

const Spacer = styled.span`
    margin: 0 0.5rem; /* 양쪽에 0.5rem 공백 추가 */
    font-size: 1rem;
    font-weight: bold;
`;

// style from .label-header
const StyledMainTitle = styled.div`
    height: 70px;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    //border-bottom: 1px solid #e0e0e0 !important;
    margin-bottom: 0rem;
`;

const StyledPlaceNickname = styled.h1`
    width: 100%;
    font-size: 3rem;
    font-weight: bold;
    //border: 1px solid yellow;

    white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
    //overflow: hidden; /* 넘치는 텍스트 숨기기 (선택사항) */
    //text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 표시 (선택사항) */
`;

const LocationDeviceDisplay = (props: LocationDeviceDisplayProps) => {
    const {
        placeList,
        updateLocationModalClick,
        deleteClick,
        currentPlace,
        toggleSettingsModal,
        deviceButtonClick,
        currentPlaceDetailList,
        isLoading,
        weather,
        language,
    } = props;

    const { t, i18n } = useTranslation(["trans"]);

    const deviceDisplay = useMemo(() => {
        if (isLoading) {
            return <Spinner size="lg" fluid />;
        } else if (!isLoading && currentPlaceDetailList?.length > 0) {
            return currentPlaceDetailList.map((device: DeviceParams) => {
                if (!device.hasOwnProperty("traits") && !device.traits) {
                    return <></>;
                } else {
                    const deviceProps = {
                        device,
                        //key: `device_${device?.uuid}`,
                        deviceButtonClick: () => deviceButtonClick(device),
                        toggleSettingsModalHandler: (event) => toggleSettingsModal(event, device),
                        className: "device-card", // device-card 클래스 추가
                    };

                    switch (device?.type) {
                        /*
                              case "thermometer":
                                  return <ThermometerFrag {...deviceProps} />;
                                  */
                        case "heatcable":
                            return <HeatcableFragNew key={`device_${device?.uuid}`} {...deviceProps} />;
                        case "generalio":
                            return <GeneralioFrag key={`device_${device?.uuid}`} {...deviceProps} />;
                        case "ssphs":
                        case "thermometer": // 기본 온습도계도 같은 레이아웃 사용
                            return <SsphsFrag key={`device_${device?.uuid}`} {...deviceProps} />;
                        default:
                            return <SsphsFrag key={`device_${device?.uuid}`} {...deviceProps} />;
                    }
                }
            });
        } else if (!isLoading && currentPlaceDetailList?.length <= 0) {
            return (
                <NoResult>
                    <p>{t("추가된 장치가 없습니다.")}</p>
                    <p>{t("장치를 먼저 추가해 주십시오.")}</p>
                </NoResult>
            );
        }
    }, [currentPlaceDetailList, deviceButtonClick, isLoading, t, toggleSettingsModal]);

    const [showModal, setShowModal] = useState(false);
    function toggleModalHandler() {
        setShowModal(!showModal);
    }

    const placeDeviceListRender = useMemo(
        () => (
            <MDBContainer fluid className="main-container pt-4 px-lg-5">
                <div
                    className="flex-container"
                    style={{
                        //border: "1px solid white",
                        position: "relative",
                        marginLeft: "40px",
                        marginRight: "40px",
                    }}
                >
                    <div
                        style={{
                            width: "85%",
                            //border: "1px solid white"
                        }}
                    >
                        {/** 메인 타이틀 */}
                        {/**
                             * 202408 요청사항에 삭제해달라고 함
                            <StyledMainTitle>{t("장소") + ">"}</StyledMainTitle>
                             */}

                        {/** 설정된 주소정보로 날씨를 보여줌. 주소가 잘못되면 -- 표시 */}

                        {/**
                            <CurrentTemperature weather={weather} />
                            */}
                    </div>
                    <div
                        className="d-flex flex-column align-items-center position-relative mb-3"
                        style={{
                            width: "100%",
                            //border: "1px solid yellow",
                        }}
                    >
                        {/** 장소 제목  */}
                        <StyledPlaceNickname className="text-center mb-3">{currentPlace?.nickname}</StyledPlaceNickname>

                        {/** 버튼 그룹 */}
                        <div
                            className="d-flex align-items-center"
                            style={{
                                //border: "1px solid red",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            {/**
                            <StyledMDBBtn
                                rounded={false}
                                outline={false}
                                onClick={toggleModalHandler}
                                className="me-2"
                                //size="lg"
                                //className="mx-2"
                            >
                                {t("장치 추가")}
                            </StyledMDBBtn>
                            <Spacer>|</Spacer>
                             */}
                            <StyledMDBBtn
                                rounded={false}
                                outline={false}
                                onClick={updateLocationModalClick}
                                className="me-2"
                                size="sm"
                            >
                                {t("LOCAD0053", "장소수정")}
                            </StyledMDBBtn>
                            <Spacer>|</Spacer>
                            <StyledMDBBtn
                                rounded={false}
                                outline={false}
                                onClick={deleteClick}
                                className="me-2"
                                size="sm"
                            >
                                {t("삭제")}
                            </StyledMDBBtn>
                        </div>
                    </div>

                    {/** 장소에 추가되어있는 장치 목록 타일 뷰 */}
                    <div className="thermometer-container w-100">{deviceDisplay}</div>

                    {/** 장치추가 버튼과 모달 */}
                    <div className="text-center py-3">
                        <AddDeviceContainer
                            needTable={true}
                            needSubscribe={true} // 여기에서도 허브를 추가할 수 있도록  true를 전달.
                            toggleModalHandler={toggleModalHandler}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                    </div>
                </div>
            </MDBContainer>
        ),
        [currentPlace?.nickname, deleteClick, deviceDisplay, showModal, t, toggleModalHandler, updateLocationModalClick]
    );

    return (
        <BackgroundContainer>
            {placeList.length <= 0 ? (
                <div
                    className="h-100 d-flex justify-content-center align-items-center"
                    style={{
                        flexDirection: "column",
                        background: COLOR_MAIN_BG,
                        color: "white",
                    }}
                >
                    <p>{t("추가된 장소가 없습니다.")}</p>
                    <p>{t("장소를 먼저 추가해 주십시오.")}</p>
                    <div>
                        <button className="btn btn-lg btn-warning" onClick={updateLocationModalClick}>
                            + {t("장소 추가")}
                        </button>
                    </div>
                </div>
            ) : process.env.REACT_APP_DESIGN_VERSION === "1" ? (
                placeDeviceListRender
            ) : (
                <>
                    {/**
                     * TODO: 여기 주석 아래에 만드신 코드 넣어보시면 됩니다.
                     */}
                    TEST
                </>
            )}
        </BackgroundContainer>
    );
};

export default LocationDeviceDisplay;
