/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';
import { TraitsResult } from './device';
import { queryRecordsByTraitId } from './record';

const apiName = 'RaonixCloud';

export interface RecordProps {
    date: string;
    value: number;
}

export interface ReportParams {
    description: string;
    deviceType: string;
    endDate: string;
    startDate: string;
    title: string;
    traits: TraitsResult;
    writer?: string;
    uuid?: string;
    reportUuid?: string;
    records?: { [prop: string]: RecordProps[] };
}

export interface Records {
    [prop: string]: RecordProps[];
}

export function queryPostReport(principalId, params: ReportParams) {
    let path = `/reports`;

    return API.post(apiName, path, {
        headers: {
            principalId: principalId,
        },
        response: true,
        body: params,
    });
}

export function queryPutReport(principalId, uuid, params: ReportParams) {
    let uri = `/reports/${uuid}`;

    return API.put(apiName, uri, {
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
        response: true,
        body: params,
    });
}

//get all reports
export function queryGetReports(principalId) {
    let uri = `/reports`;

    return API.get(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

//get reports by report uuid
export function queryGetReport(principalId, reportUuid) {
    let uri = `/reports/${reportUuid}`;

    return API.get(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

//delete delete
export function queryDeleteReport(principalId, uuid) {
    let uri = `/reports/${uuid}`;

    return API.del(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

export function queryReportRecords(principalId, report) {
    const { traits, startDate, endDate } = report;

    let records = {};
    let ps = [];
    for (let traitUuid in traits) {
        let params = {
            uuid: traitUuid,
            start: startDate,
            end: endDate,
        };

        ps.push(
            queryRecordsByTraitId(principalId, params).then((result) => {
                records[traitUuid] = result;
            })
        );
    }

    return Promise.all(ps).then((result) => {
        return records;
    });
}

export function queryWriteReport(principalId, uuid, writer, params) {
    let uri = `/reports/${uuid}/${writer}`;

    return API.post(apiName, uri, {
        headers: {
            principalId: principalId,
        },
        response: true,
        body: params,
        responseType: 'blob',
    });
    //    .then(response => response.data);
}
