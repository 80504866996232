
export interface PasswordResetInputListProps {
    type: string;
    id: string;
    name: string;
    label: string;
    placeholder: string;
    required: boolean;
    value: string;
    message?: string;
    errorMessage?: string;
    validation?: boolean;
    addressFind?: boolean;
    children?: React.ReactElement;
    codeSent?: boolean;
}

const passwordResetInputs: PasswordResetInputListProps[] = [
    {
        type: 'email',
        id: 'email',
        name: 'username',
        label: 'Email',
        placeholder: 'email@email.com',
        required: true,
        value: '',
        validation: null,
        message: '',
        errorMessage: '',
    },

    {
        type: 'password',
        id: 'newPassword',
        name: 'password',
        label: 'New Password',
        placeholder: 'New Password',
        required: true,
        message: '',
        value: '',
        validation: null,
        codeSent: false,
    },
    {
        type: 'password',
        id: 'passwordConfirm',
        name: 'passwordConfirm',
        label: 'Password Confirm',
        placeholder: 'Password Confirm',
        required: true,
        message: '',
        value: '',
        validation: null,
        codeSent: false,
    },
    {
        type: 'text',
        id: 'confirmationCode',
        name: 'confirmationCode',
        label: 'Confirmation Code',
        placeholder: 'Confirmation Code',
        required: true,
        message: '',
        value: '',
        validation: null,
        codeSent: false,
    },
];

export { passwordResetInputs };

