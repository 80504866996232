import dayjs from 'dayjs';
import { Component } from 'react';
import { getEventDatasets } from 'utils/report';
import { chartColors, rgb2rgba } from 'utils/utils';
import ThermometerChart from './ThermometerChart';

class Chart extends Component {
    render() {
        const { report } = this.props;
        let datasets = getEventDatasets(report);
        if (datasets === undefined) {
            return null;
        }
        let labels = [];
        let data = { labels };

        datasets[0]?.data.forEach((label) =>
            labels.push(dayjs(label.x).format('M/D, hh:mm:ss'))
            //labels.push(dayjs(label.x).format('D, hA'))
        );

        data.datasets = datasets.map((e, index) => {
            const keys = Object.keys(chartColors);
            // const key = keys[~~(index / 2) % keys.length];
            const key = keys[index];
            const color = chartColors[key];
            const type = e.label.includes('온도');
            const order = type ? 1 : 2;
            const yAxis = type ? 'temperature' : 'humidity';

            return {
                type: type ? 'line' : 'bar',
                fill: false,
                lineTension: 0,
                order: order,
                borderWidth: 2,
                borderColor: color,
                backgroundColor: rgb2rgba(color, 0.5),
                label: e.label,
                data: e.data,
                /* data: e.data.map((axis) =>
                    type ? { temperature: axis.y, date: axis.x } : axis.y
                ), */
                yAxisId: yAxis,
                xAxisId: 'date',
            };
        });

        return (
            <ThermometerChart
                data={data}
                type={ThermometerTemperatureHumidity.name}
            />
        );
    }
}

const ThermometerTemperatureHumidity = {
    nickname: '온습도 리포트',
    name: 'ThermometerTemperatureHumidity',
    Chart,
};

export default ThermometerTemperatureHumidity;
