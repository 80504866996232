import { API } from 'aws-amplify';

const apiName = 'RaonixCloud';

export interface AlarmQuery {
    uuid?: string;
    start?: string;
    end?: string;
    offset?: number;
    limit?: number;
    checked?: boolean;
}

export interface AlarmListParams {
    checked: boolean;
    date: string;
    deviceUuid?: string;
    message: string;
    localeDate: string;
}

export function queryRecordDevices(principalId) {
    let path = '/records/devices';

    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
        response: true,
    });
}

export const queryRecordDeviceUsersDelete = (principalId, deviceUuid) => {
    let path = `/records/devices/${deviceUuid}/users`;

    return API.del(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
        response: true,
    });
};

export function queryRecordsByTraitId(principalId, params) {
    let path = `/records/traits/${params.uuid}/state`;
    return API.get(apiName, path, {
        response: true,
        queryStringParameters: {
            start: params.start,
            end: params.end,
        },
        headers: {
            principalId: principalId,
        },
    }).then((response) => response.data);
}

/**
 * Alarms
 */
export const queryRecordAlarmCountGet = (
    principalId,
    query: AlarmQuery = {}
) => {
    let path = `/records/alarm/count`;

    return API.get(apiName, path, {
        queryStringParameters: {
            ...query,
        },
        headers: {
            principalId: principalId,
        },
        response: true,
    });
};

export const queryRecordAlarmGet = (principalId, query: AlarmQuery = {}) => {
    let path = `/records/alarm`;

    return API.get(apiName, path, {
        queryStringParameters: {
            ...query,
        },
        headers: {
            principalId: principalId,
        },
        response: true,
    });
};

export const queryRecordAlarmDelete = (principalId, query = {}, params) => {
    let path = `/records/alarm`;

    return API.del(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
        response: true,
        body: params,
    });
};

export const queryRecordAlarmPut = (principalId, params) => {
    let path = `/records/alarm`;

    return API.put(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
        response: true,
        body: params,
    });
};
