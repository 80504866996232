import {
    AlarmListParams,
    AlarmQuery,
    queryRecordAlarmCountGet,
    queryRecordAlarmGet,
    queryRecordAlarmPut
} from 'api/record';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlarmDisplay from 'components/display/alarmDisplay';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AlarmContainer = (props) => {
    /* from react-router */
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    /* from redux */
    const currentDevice = useAppSelector(
        (state) => state.display.currentDevice
    );
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const startDate = useAppSelector((state) => state.search.startDate);
    const endDate = useAppSelector((state) => state.search.endDate);

    /* local state */
    const [alarmCount, setAlarmCount] = useState<number>(0);
    const [alarmList, setAlarmList] = useState<AlarmListParams[]>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const [checkedAlarms, setCheckedAlarms] = useState<AlarmListParams[]>(null);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    /**
     * currentDevice가 없으면 뒤로가기
     */
    useEffect(() => {
        if (!currentDevice) navigate(-1);
    }, [currentDevice, navigate]);

    /**
     * 해당하는 device uuid에 대한 모든 알람 갯수를 return 함
     * @returns number
     */
    async function getAlarmCount(query: AlarmQuery) {
        const alarmCount: number = await queryRecordAlarmCountGet(
            checkedUser?.principalId,
            { uuid: query.uuid }
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => console.info(error));

        return alarmCount || null;
    }

    /**
     * 해당하는 device uuid에 대한 모든 알람을 return 함
     * @param query 
     * @returns 
     */
    async function getAlarmList(query: AlarmQuery) {
        const alarmList: AlarmListParams[] = await queryRecordAlarmGet(
            checkedUser?.principalId,
            { uuid: query.uuid, start: query.start, end: query.end }
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.info(error);
            });

        return alarmList || null;
    }

    /**
     *
     */
    useEffect(() => {
        async function run() {
            const alarmCount: number = await getAlarmCount({
                uuid: currentDevice?.uuid,
            });

            setAlarmCount(alarmCount);
        }

        checkedUser && currentDevice?.uuid && run();
    }, [checkedUser, currentDevice]);

    /**
     * alarm object에 localeDate를 추가함
     * @param alarmList
     * @returns AlarmListParams
     */
    function addLocaleDate(alarmList: AlarmListParams[]) {
        return (
            alarmList?.map((alarm) => {
                return {
                    ...alarm,
                    localeDate: new Date(alarm.date).toLocaleString(),
                };
            }) || null
        );
    }

    /* 첫 render */
    useEffect(() => {
        async function run() {
            setLoader(true);

            const alarmList: AlarmListParams[] = await getAlarmList({
                uuid: currentDevice?.uuid,
                start: startDate?.date,
                end: endDate?.date,
            });

            setAlarmList(addLocaleDate(alarmList));

            setLoader(false);
        }

        if (alarmList) return;

        currentDevice && startDate && endDate && run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDevice, startDate, endDate]);

    // 검색
    async function alarmSearchSubmitHandler(event, query: AlarmQuery) {
       // event.preventDefault();

        setLoader(true);

        const alarmCount: number = await getAlarmCount(query);

        if (alarmCount > 0) {
            const alarmList: AlarmListParams[] = await getAlarmList(query);

            setAlarmCount(alarmCount);
            setAlarmList(addLocaleDate(alarmList));
        }

        setLoader(false);
    }

    /* handler */
    // row의 checkbox를 선택할 시 호출
    function rowSelectClickHandler(
        row: AlarmListParams[],
        _,
        allSelected: boolean
    ) {
        setCheckedAlarms(row);
        setAllSelected(allSelected);
    }

    // 알람을 check(읽기)할 때 호출
    const checkAlarmHandler = useCallback(async () => {
        if (_.isEmpty(checkedAlarms)) return;

        const promiseList = checkedAlarms.map((alarm) => {
            let params = {
                date: alarm.date,
                checked: true,
            };

            return queryRecordAlarmPut(checkedUser.principalId, params);
        });

        const result = await Promise.all(promiseList).catch((error) => {
            console.info(error);
        });

        if (!result) return;

        const alarmListResult = await getAlarmList({
            start: startDate?.date || null,
            end: endDate?.date || null,
            uuid: currentDevice.uuid,
        });

        setAlarmList(addLocaleDate(alarmListResult));

        // 강제로 전체선택을 두번 클릭함
        const allSelectButton = document.querySelectorAll(
            '[type=checkbox]'
        )[0] as HTMLElement;
        if (allSelected) {
            allSelectButton.click();
        } else {
            allSelectButton.click();
            allSelectButton.click();
        }
    }, [checkedAlarms, getAlarmList, startDate?.date, endDate?.date, currentDevice?.uuid, allSelected, checkedUser?.principalId]);

    // excel 파일 다운로드
    function csvDownloadClickHandler(event) {
        if (alarmList?.length <= 0) {
            alert('Alarm not found.');
            return false;
        }

        if (window.confirm('Download csv file?')) {
            return true;
        }

        return false;
    }

    const csvFileName = 'alarm_' + dayjs().format();

    return (
        <>
            <AlarmDisplay
                currentDevice={currentDevice}
                alarmCount={alarmCount}
                body={alarmList}
                loader={loader}
                alarmSearchSubmit={alarmSearchSubmitHandler}
                rowSelectClickHandler={rowSelectClickHandler}
                checkAlarmHandler={checkAlarmHandler}
                csvDownloadClickHandler={csvDownloadClickHandler}
                csvFileName={csvFileName}
            ></AlarmDisplay>
        </>
    );
};

export default AlarmContainer;
