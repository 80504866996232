import IconBtn from "components/fragment/iconBtn";
import { MDBBtn, MDBCheckbox, MDBCol, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect } from "mdb-react-ui-kit";
import { useTranslation } from 'react-i18next';

const ReportAutoSetContainer = (props) => {
    const {showModal, setShowModal, modalTitle, toggleModalHandler} = props;
    const { t, i18n } = useTranslation(["trans"]);

    return (
        <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
            <MDBModalDialog size="lg" centered scrollable>
                <MDBModalContent>
                    <div className="border-bottom">
                        <MDBModalHeader className="modal-header border-bottom-0">
                            <span></span>
                            <MDBModalTitle className="modal-title">{modalTitle}</MDBModalTitle>
                            <IconBtn type="close" ariaLabel="Close" onClick={toggleModalHandler} />
                        </MDBModalHeader>
                    </div>

                    <MDBModalBody>
                        <MDBRow className="mb-3">
                            <MDBCol md="1"></MDBCol>
                            <MDBCol md="2">일간</MDBCol>
                            <MDBCol md="9">
                                <MDBCheckbox name="flexCheck" value="" id="daylyCheckbox" label="" />
                                <MDBSelect
                                    data={[
                                        { text: "0시", value: 0, secondaryText: "이전일 0시 ~ 다음일 23시" },
                                        { text: "1시", value: 1, secondaryText: "이전일 1시 ~ 다음일 0시" },
                                        { text: "2시", value: 2, secondaryText: "이전일 2시 ~ 다음일 1시" },
                                        { text: "3시", value: 3, secondaryText: "이전일 3시 ~ 다음일 2시" },
                                        { text: "4시", value: 4, secondaryText: "이전일 4시 ~ 다음일 3시" },
                                        { text: "5시", value: 5, secondaryText: "이전일 5시 ~ 다음일 4시" },
                                        { text: "6시", value: 6, secondaryText: "이전일 6시 ~ 다음일 5시" },
                                        { text: "7시", value: 7, secondaryText: "이전일 7시 ~ 다음일 6시" },
                                        { text: "8시", value: 8, secondaryText: "이전일 8시 ~ 다음일 7시" },
                                        { text: "9시", value: 9, secondaryText: "이전일 9시 ~ 다음일 8시" },
                                        { text: "10시", value: 10, secondaryText: "이전일 10시 ~ 다음일 9시" },
                                        { text: "11시", value: 11, secondaryText: "이전일 11시 ~ 다음일 10시" },
                                        { text: "12시", value: 12, secondaryText: "이전일 12시 ~ 다음일 11시" },
                                        { text: "13시", value: 13, secondaryText: "이전일 13시 ~ 다음일 12시" },
                                        { text: "14시", value: 14, secondaryText: "이전일 14시 ~ 다음일 13시" },
                                        { text: "15시", value: 15, secondaryText: "이전일 15시 ~ 다음일 14시" },
                                        { text: "16시", value: 16, secondaryText: "이전일 16시 ~ 다음일 15시" },
                                        { text: "17시", value: 17, secondaryText: "이전일 17시 ~ 다음일 16시" },
                                        { text: "18시", value: 18, secondaryText: "이전일 18시 ~ 다음일 17시" },
                                        { text: "19시", value: 19, secondaryText: "이전일 19시 ~ 다음일 18시" },
                                        { text: "20시", value: 20, secondaryText: "이전일 20시 ~ 다음일 19시" },
                                        { text: "21시", value: 21, secondaryText: "이전일 21시 ~ 다음일 20시" },
                                        { text: "22시", value: 22, secondaryText: "이전일 22시 ~ 다음일 21시" },
                                        { text: "23시", value: 23, secondaryText: "이전일 23시 ~ 다음일 22시" },
                                    ]}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-3">
                            <MDBCol md="1"></MDBCol>
                            <MDBCol md="2">주간</MDBCol>
                            <MDBCol md="9">
                                <MDBCheckbox name="flexCheck" value="" id="weeklyCheckbox" label="" />
                                <MDBSelect
                                    data={[
                                        { text: "매주 월요일 ~ 일요일", value: "1" },
                                        { text: "매주 화요일 ~ 월요일", value: "2" },
                                        { text: "매주 수요일 ~ 화요일", value: "3" },
                                        { text: "매주 목요일 ~ 수요일", value: "4" },
                                        { text: "매주 금요일 ~ 목요일", value: "5" },
                                        { text: "매주 토요일 ~ 금요일", value: "6" },
                                        { text: "매주 일요일 ~ 토요일", value: "7" },
                                    ]}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-3">
                            <MDBCol md="1"></MDBCol>
                            <MDBCol md="2">월간</MDBCol>
                            <MDBCol md="9">
                                <MDBCheckbox name="flexCheck" value="" id="monthlyCheckbox" label="" />
                                <MDBSelect
                                    data={[
                                        { text: "1일", value: 1, secondaryText: "이전달 1일 ~ 다음일 말일" },
                                        { text: "2일", value: 2, secondaryText: "이전달 2일 ~ 다음일 1일" },
                                        { text: "3일", value: 2, secondaryText: "이전달 3일 ~ 다음일 2일" },
                                        { text: "4일", value: 2, secondaryText: "이전달 4일 ~ 다음일 3일" },
                                        { text: "5일", value: 2, secondaryText: "이전달 5일 ~ 다음일 4일" },
                                        { text: "6일", value: 2, secondaryText: "이전달 6일 ~ 다음일 5일" },
                                        { text: "7일", value: 2, secondaryText: "이전달 7일 ~ 다음일 6일" },
                                        { text: "8일", value: 2, secondaryText: "이전달 8일 ~ 다음일 7일" },
                                        { text: "9일", value: 2, secondaryText: "이전달 9일 ~ 다음일 8일" },
                                        { text: "10일", value: 10, secondaryText: "이전달 10일 ~ 다음일 9일" },
                                        { text: "11일", value: 11, secondaryText: "이전달 11일 ~ 다음일 10일" },
                                        { text: "12일", value: 12, secondaryText: "이전달 12일 ~ 다음일 11일" },
                                        { text: "13일", value: 13, secondaryText: "이전달 13일 ~ 다음일 12일" },
                                        { text: "14일", value: 14, secondaryText: "이전달 14일 ~ 다음일 13일" },
                                        { text: "15일", value: 15, secondaryText: "이전달 15일 ~ 다음일 14일" },
                                        { text: "16일", value: 16, secondaryText: "이전달 16일 ~ 다음일 15일" },
                                        { text: "17일", value: 17, secondaryText: "이전달 17일 ~ 다음일 16일" },
                                        { text: "18일", value: 18, secondaryText: "이전달 18일 ~ 다음일 17일" },
                                        { text: "19일", value: 19, secondaryText: "이전달 19일 ~ 다음일 18일" },
                                        { text: "20일", value: 20, secondaryText: "이전달 20일 ~ 다음일 19일" },
                                        { text: "21일", value: 21, secondaryText: "이전달 21일 ~ 다음일 20일" },
                                        { text: "22일", value: 22, secondaryText: "이전달 22일 ~ 다음일 21일" },
                                        { text: "23일", value: 23, secondaryText: "이전달 23일 ~ 다음일 22일" },
                                        { text: "24일", value: 24, secondaryText: "이전달 24일 ~ 다음일 23일" },
                                        { text: "25일", value: 25, secondaryText: "이전달 25일 ~ 다음일 24일" },
                                        { text: "26일", value: 26, secondaryText: "이전달 26일 ~ 다음일 25일" },
                                        { text: "27일", value: 27, secondaryText: "이전달 27일 ~ 다음일 26일" },
                                        { text: "28일", value: 28, secondaryText: "이전달 28일 ~ 다음일 27일" },
                                        { text: "29일", value: 29, secondaryText: "이전달 29일 ~ 다음일 28일" },
                                        { text: "30일", value: 30, secondaryText: "이전달 30일 ~ 다음일 29일" },
                                        { text: "31일", value: 31, secondaryText: "이전달 31일 ~ 다음일 30일" },
                                    ]}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn type="submit" color="primary" onClick={toggleModalHandler}>
                            {t("저장")}
                        </MDBBtn>
                        <MDBBtn color="secondary" onClick={toggleModalHandler}>
                            {t("취소")}
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
}
export default ReportAutoSetContainer;