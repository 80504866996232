import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DeviceParams,
    DeviceToPlaceParams, TraitsResult
} from 'api/device';
import { PlaceParams } from 'api/place';
import { HubChildState, HubParams } from 'api/r9iot';
import { AlarmListParams } from 'api/record';
import { ReportParams } from 'api/report';
import _ from 'lodash';

export interface DisplayState {
    placeList: PlaceParams[];
    addedHub: HubParams;
    allDeviceList: DeviceParams[];
    currentDevice: DeviceParams;
    currentPlace: PlaceParams;
    currentPlaceDeviceList: DeviceToPlaceParams[];
    currentPlaceDeviceDetailList: DeviceParams[];
    currentPlaceDeviceTraits: TraitsResult[];
    currentDeviceState: HubChildState;
    alarmList: AlarmListParams[];
    currentReport: ReportParams;
}

const initialState: DisplayState = {
    // 계정에 저장된 장소목록(navbar)
    placeList: [],
    
    // 추가된 허브 목록
    addedHub: null,

    // 계정에 저장된 모든 기기
    allDeviceList: [],

    // 현재 장소 정보
    currentPlace: null,

    // 현재 장소의 기기 리스트 (uuid 정보만 존재)
    currentPlaceDeviceList: [],
    
    // 현재 장소의 각 device의 detail, traits 리스트(온도, 습도 수치는 포함되어 있지 않음)
    currentPlaceDeviceDetailList: [],
    
    // 현재 장소의 device의 온도, 습도 정보 리스트(detail 정보는 포함되어 있지 않음, key값은 currentPlaceDeviceDetailList-traits와 매칭된다)
    currentPlaceDeviceTraits: [],

    // 현재 장소에서 선택된 기기의 detail 정보
    currentDevice: null,

    // 현재 장소에서 선택된 기기의 설정 변경을 위한 세부정보
    currentDeviceState: null,

    // 모든 알람 목록
    alarmList: null,

    // 현재 선택된 리포트
    currentReport: null,
};

const displaySlice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        updatePlaceList(
            state,
            action: PayloadAction<PlaceParams | PlaceParams[]>
        ) {
            if (_.isArray(action.payload)) {
                state.placeList = action.payload;
            } else {
                state.placeList.push(action.payload);
            }
        },

        updateCurrentPlace(state, action: PayloadAction<PlaceParams>) {
            state.currentPlace = action.payload;
        },

        deletePlace(state, action: PayloadAction<string>) {
            state.placeList = state.placeList.filter((place: PlaceParams) => {
                return place.uuid !== action.payload;
            });
        },

        /**
         * @param state
         * @param action
         */
        putPlaceList(state, action: PayloadAction<PlaceParams>) {
            state.placeList = state.placeList.map((placeItem) => {
                if (placeItem.uuid !== action.payload.uuid) return placeItem;

                placeItem = action.payload;

                return placeItem;
            });
            /* state.placeList */
        },

        /**
         * place 저장된 device list (uuid형태)를 가져옴
         * useEffect refresh 용도로도 사용함
         * @param state
         * @param action
         */
        updateDeviceList(state, action: PayloadAction<DeviceToPlaceParams[]>) {
            state.currentPlaceDeviceList = action.payload;
        },

        updateDeviceDetailList(state, action: PayloadAction<DeviceParams[]>) {
            state.currentPlaceDeviceDetailList = action.payload;
        },

        updateDeviceTraits(state, action: PayloadAction<TraitsResult[]>) {
            state.currentPlaceDeviceTraits = action.payload;
        },

        updateCurrentDevice(state, action: PayloadAction<DeviceParams>) {
            state.currentDevice = action.payload;
        },

        updateCurrentDeviceState(state, action: PayloadAction<HubChildState>) {
            state.currentDeviceState = action.payload;
        },

        updateAllDeviceList(state, action: PayloadAction<DeviceParams[]>) {
            state.allDeviceList = action.payload;
        },

        updateAddedHub(state, action: PayloadAction<HubParams>) {
            state.addedHub = action.payload;
        },

        updateAlarmList(state, action: PayloadAction<AlarmListParams[]>) {
            state.alarmList = action.payload;
        },

        updateCurrentReport(state, action: PayloadAction<ReportParams>) {
            state.currentReport = action.payload;
        },
    },
});

export const {
    updatePlaceList,
    deletePlace,
    putPlaceList,
    updateDeviceList,
    updateDeviceDetailList,
    updateDeviceTraits,
    updateCurrentDevice,
    updateCurrentDeviceState,
    updateCurrentPlace,
    updateAllDeviceList,
    updateAddedHub,
    updateAlarmList,
    updateCurrentReport,
} = displaySlice.actions;
export default displaySlice.reducer;
