import ReportListTable from 'components/fragment/reportListTable';
import { MDBBtn, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

export interface ReportListDisplayProps {
    loader: boolean;
    reportList: Object[];
    moveClickHandler;
    rowSelectClickHandler;
    deleteReportHandler;
}
const buttonStyle = {
    border: "1px solid grey", // 테두리 색상
    backgroundColor: "transparent", // 배경 투명
    color: "lightgray", // 텍스트 색상
    margin: "0 0.5rem",
    padding: "0 0.5rem", // 여백
    cursor: "pointer", // 마우스 포인터
    borderRadius: "4px", // 버튼 모서리 둥글게
    transition: "background-color 0.3s, color 0.3s", // 애니메이션 효과
    fontSize: "0.8rem", // 텍스트 크기 작게 설정
    height: "30px", // 칩의 고정 높이 설정
    display: "flex", // 칩 내부 요소 정렬
    alignItems: "center", // 수직 중앙 정렬
    justifyContent: "center", // 가로 중앙 정렬
    boxShadow: "none"
};

const ReportListDisplay = (props: ReportListDisplayProps) => {
    const {
        loader,
        reportList,
        moveClickHandler,
        rowSelectClickHandler,
        deleteReportHandler,
    } = props;

    const { t, i18n } = useTranslation(['trans']);

    return (
        <>
            <MDBContainer
                fluid
                className="subpage-container pt-2 px-lg-5"
            >
                <div className="label-header">
                    <div className="w-100">
                        <h2 className="m-0">{t("LOCAD0033", "보고서 목록")}</h2>
                    </div>
                </div>
                <div className="table-box">
                    <div className="d-flex justify-content-end mb-2">
                        <MDBBtn
                            // className='btn-danger'
                            onClick={deleteReportHandler}
                            style={buttonStyle}
                        >
                            {t("LOCAD0034","삭제")}
                        </MDBBtn>
                    </div>
                    <ReportListTable
                        loader={loader}
                        body={reportList}
                        moveClick={moveClickHandler}
                        rowSelectClickHandler={rowSelectClickHandler}
                    />
                </div>
            </MDBContainer>
        </>
    );
};

export default ReportListDisplay;
