import styled from '@emotion/styled';
import { PlaceParams, initialPlaceParams } from 'api/place';
import { useAppSelector } from 'app/hooks';
import { COLOR_SIDE_NAV_BG, COLOR_SIDE_NAV_BUTTON_BG, SIDENAV_WIDTH } from 'constants/common';
import {
    MDBSideNav,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBSideNavMenu
} from 'mdb-react-ui-kit';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface LocationSideNavProps {
    navigateClick: (location: PlaceParams) => void;
    navList: PlaceParams[];
    toggleModal: (event?) => void;
    showMapButton: boolean;
}

const StyledMDBSideNav = styled(MDBSideNav)`
    //top: 125px !important; /* Adjust this value to match the height of your navbar */
    width: ${SIDENAV_WIDTH} !important;
    background: ${COLOR_SIDE_NAV_BG};
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20%;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    height: calc(100% - 280px);
`;
const StyledMDBSideNavMenu = styled(MDBSideNavMenu)`
    overflow: auto; /* 스크롤 가능하게 설정 */
    border-top: 1px solid grey;
    height: calc(100% - 80px);
    min-height: 200px;
    padding-top: 2rem;
`;
const ButtonContainer = styled.div`
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 20px;
    align-items: center;
    border-top: 1px solid grey;
`;

const StyledMDBSideNavItem = styled(MDBSideNavItem)`
    margin: 0;
    border-bottom: none;
    padding: 0px 0px;
    display: flex;
    justify-content: flex-start; /* 왼쪽정렬, (가운데정렬 : center)*/
    align-items: center;
    transition: none !important;

    &.selected {
        color: white !important;
        font-weight: bold !important;
        span {
            font-size: 1.8rem !important;
        }
    }

    &.not-selected {
        color: white !important;
        font-weight: normal !important;
        span {
            font-size: 1.5rem !important;
        }
    }

    &:hover {
        background-color: transparent !important;
        font-weight: bold !important;
    }
`;


const StyledMDBSideNavLink = styled(MDBSideNavLink)`
    width: 100%;
    text-align: left;
    transition: none !important;

    &:hover {
        background-color: transparent !important;
    }
`;

// 공통 버튼 스타일 정의
const commonButtonStyles = `
    width: 60%;
    padding: 5px;
    margin: 5px 0;
    background-color: ${COLOR_SIDE_NAV_BUTTON_BG};
    border: none;
    border-radius: 1px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:hover {
        background-color: #fff; /* 호버 시 버튼 배경색 설정 */
        color: #191919;
    }
`;

const StyledButton = styled.button`
    ${commonButtonStyles}
`;

const StyledNavLinkButton = styled(NavLink)`
    ${commonButtonStyles}
`;

const StyledSideNavUsername = styled.span`
    color: white;
    font-size: 2.5rem;
    font-weight: bold;

    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 2rem;
    white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 표시 */
    max-width: 100%; /* 필요한 경우 원하는 너비를 설정 */
    height: 84px;
`;


const LocationSideNav = (props: LocationSideNavProps) => {
    const { navList, navigateClick, showMapButton, toggleModal } = props;


    /* react router */
    const { locationId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    /* props from redux */
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentLocationId = useAppSelector((state) => state.authState.currentLocationId);

    const [basicOpen, setBasicOpen] = useState(true);
    const [mode, setMode] = useState("side");
    const [display, setDisplay] = useState("block");

    const handleDisplay = useCallback(() => {
        if (window.matchMedia("(max-width: 1000px)").matches) {
            //setDisplay("none");
            setDisplay("block");
        } else {
            setDisplay("block");
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener("resize", handleDisplay);
    }, []);

    // navList display
    const displayNavList = navList?.map((navItem: PlaceParams, index: number) => {
        const className =
            //navItem.uuid === locationId || navItem.uuid === currentLocationId ? " text-white bg-primary" : "";
            navItem.uuid === locationId || navItem.uuid === currentLocationId ? "selected" : "not-selected";

        return (
            <StyledMDBSideNavItem
                //className={"border-bottom" + className}
                className={className}
                key={`${navItem.uuid}_${index}`}
            >
                <StyledMDBSideNavLink onClick={() => navigateClick(navItem)}>
                    <span
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            padding: "0px"
                        }}
                    >
                        {navItem.nickname}
                    </span>
                </StyledMDBSideNavLink>
            </StyledMDBSideNavItem>
        );
    });

    const unsetPlaceNavItem = () => {
        const className = "" === locationId || "" === currentLocationId ? " text-white bg-primary" : "";
        return (
            <MDBSideNavItem className={"border-bottom" + className} key={"unknown"}>
                <MDBSideNavLink onClick={() => navigateClick(initialPlaceParams)}>
                    <span
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                        }}
                    >
                        장소없음
                    </span>
                </MDBSideNavLink>
            </MDBSideNavItem>
        );
    };

    // 맵으로 이동 버튼을 (비)활성화
    let checkIfLocationMapDisplay;

    if (location.pathname.includes("location-map")) {
        checkIfLocationMapDisplay = <StyledButton onClick={() => navigate(-1)}>{t("LOCAD0049","목록으로 이동")}</StyledButton>;
    } else if (showMapButton) {
        checkIfLocationMapDisplay = (
            <StyledNavLinkButton to={`/dashboard/location-map`}>{t("LOCAD0048","맵으로 이동")}</StyledNavLinkButton>
        );
    }

    const test = false;
    return (
        <>
            {test ? null : (
                <StyledMDBSideNav
                    backdrop={false}
                    mode={mode}
                    isOpen={basicOpen}
                    getOpenState={(e) => setBasicOpen(e)}
                    color="light"
                    className="sidenav"
                    style={{
                        display,
                        textAlign: "center",
                        //border: "1px solid red",
                    }}
                    closeOnEsc={false}
                >
                    <StyledSideNavUsername>
                        {typeof checkedUser?.name === "string" ? (checkedUser?.name).toUpperCase() : checkedUser?.name}
                    </StyledSideNavUsername>

                    <ContentContainer>
                        <StyledMDBSideNavMenu
                        >
                            {navList?.length <= 0 ? (
                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                    {t("추가된 장소가 없습니다.")}
                                </div>
                            ) : (
                                // for test
                                //[...Array(10)].map((_, index) => displayNavList)
                                displayNavList
                            )}
                        </StyledMDBSideNavMenu>
                        <ButtonContainer>
                            {/**
                            <StyledButton onClick={toggleModal}>{t("장소 추가")}</StyledButton>
                             */}
                            {navList?.length <= 0 ? "" : checkIfLocationMapDisplay}
                        </ButtonContainer>
                    </ContentContainer>
                </StyledMDBSideNav>
            )}
        </>
    );
};

export default memo(LocationSideNav);
