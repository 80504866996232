import React from 'react';

interface ColorPickerProps {
    currentColor?: string;
    colorChange?: (event?) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
    const defaultColor = '#546D64';

    return (
        <div>
            <input
                type='color'
                className='form-control form-control-color'
                id='colorPicker'
                defaultValue={defaultColor}
                value={props.currentColor}
                title='Choose your color'
                onChange={props.colorChange}
            />
        </div>
    );
};
export default ColorPicker;
