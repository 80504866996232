import { MDBSwitch } from 'mdb-react-ui-kit';

interface LabeledSwitchSectionProps {
    label: string;
    name: string;
    checked?: Boolean;
    id: string;
    children?: any;
    toggleSwitchHandler?: (event) => void;
}

const LabeledSwitchSection = (props: LabeledSwitchSectionProps) => {
    const {
        id,
        label,
        name,
        toggleSwitchHandler,
        checked,
    }: LabeledSwitchSectionProps = props;

    return (
        <div className="labeled-switch-section w-50">
            <div className="d-flex justify-content-between">
                <label htmlFor={id} style={{ flex: "0 0 40%" }}>
                    {label}
                </label>
                <div style={{ flex: "1 0 60%" }}>
                    <MDBSwitch
                        id={id}
                        inline={true}
                        // label={label}
                        name={name}
                        onChange={toggleSwitchHandler}
                        defaultChecked={checked ? true : false}
                    />
                </div>
            </div>
        </div>
    );
};

export default LabeledSwitchSection;
