import { MDBChip } from 'mdb-react-ui-kit';
import { forwardRef, LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ChipsProps {
    chipClickHandler?: (event, number?) => void;
    children?: any;
    number?: number;
    defaultChip: boolean;
    selectedChip?:  any;
}

const Chips = forwardRef((props: ChipsProps, ref: LegacyRef<HTMLDivElement>) => {
    const { chipClickHandler, children, selectedChip } = props;

    const { t } = useTranslation(["trans"]);

    // 칩의 기본 버튼 스타일
    const defaultStyle = {
        border: "1px solid grey", // 테두리 색상
        backgroundColor: "transparent", // 배경 투명
        color: "lightgray", // 텍스트 색상
        margin: 0,
        padding: "0 0.1rem", // 여백
        cursor: "pointer", // 마우스 포인터
        borderRadius: "4px", // 버튼 모서리 둥글게
        transition: "background-color 0.3s, color 0.3s", // 애니메이션 효과
        fontSize: "0.8rem", // 텍스트 크기 작게 설정
        height: "24px", // 칩의 고정 높이 설정
        display: "flex", // 칩 내부 요소 정렬
        alignItems: "center", // 수직 중앙 정렬
        justifyContent: "center", // 가로 중앙 정렬
        width: "50px", // 최소 너비 설정
    };

    // 선택된 칩의 스타일
    const selectedStyle = {
        backgroundColor: "white", // 선택 시 배경색
        color: "#202020", // 선택 시 텍스트 색상
    };

    // 칩 스타일 결정 (선택 여부에 따라)
    const chipStyle = selectedChip === children ? { ...defaultStyle, ...selectedStyle } : defaultStyle;

    return (
        <div
            style={{ display: "inline-block", margin: "0 0.3rem" }} // 칩 사이 간격 조정
            onClick={chipClickHandler}
            ref={ref}
        >
            <MDBChip style={chipStyle}>{t(children)}</MDBChip>
        </div>
    );
});

/*
const Chips = forwardRef(
    (props: ChipsProps, ref: LegacyRef<HTMLDivElement>) => {
        const { chipClickHandler, children, number, defaultChip, selectedChip } = props;

        const { t, i18n } = useTranslation(["trans"]);

        const chipStyle = selectedChip === children ? { backgroundColor: "#0d6efd", color: "white" } : {};
        return (
            <div style={{ display: "inline-block" }} onClick={chipClickHandler} ref={ref}>
                <MDBChip style={{ width: "max-content", ...chipStyle }}>{t(children)}</MDBChip>
            </div>
        );
    }
);
*/

/* const Chips = (props: ChipsProps) => {
    return (
        <div
            style={{ display: 'inline-block' }}
            onClick={chipClickHandler}
            ref={ref}
        >
            <MDBChip style={{ width: 'max-content' }}>{children}</MDBChip>
        </div>
    );
}; */

export default Chips;
