import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DeviceModels } from 'constants/deviceModel';
import { MDBIcon, MDBInput, MDBValidationItem } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

const DeviceInputSection = (props) => {
    const {
        title,
        type,
        label,
        placeholder,
        btnText,
        moveToNextHandler,
        name,
        value,
        valueChanged,
        data,
        searchResult,
        deviceInput,
        deviceInputChange,
        invalid,
    } = props;

    const { t, i18n } = useTranslation(['trans']);

    const buttonActivated = !!deviceInput
        ? 'btn btn-primary'
        : 'btn btn-light border';

    let buttonByMethod;
    let refreshButton;

    if (btnText === '다음') {  /* if (btnText === 'NEXT') { */
        buttonByMethod = (
            <button
                type='button'
                className='btn btn-primary button'
                onClick={moveToNextHandler}
                style={{
                    width: '120px',
                    backgroundColor: '#292929',
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: '200',
                    borderRadius: '4px',
                    boxShadow: 'none',
                    padding: '0.4rem 1.4rem',

                }}
            >
                {btnText}
            </button>
        );
        refreshButton = '';
    } else if (btnText === 'SEARCH') {
        buttonByMethod = (
            <button
                type='submit'
                className={`button ${buttonActivated}`}
                disabled={!deviceInput}
            >
                <MDBIcon fas icon='search' />
            </button>
        );
        refreshButton = (
            <button className='btn btn-primary' disabled={!searchResult}>
                <MDBIcon fas icon='redo-alt' />
            </button>
        );
    }

    const displayType =
        type === 'select' ? (
            <FormControl className='m-0 w-50' size='small'>
                {/* <InputLabel id={`select-${name}`}>{t(label)}</InputLabel> */}
                <Select
                    labelId={`select-${name}`}
                    id={name}
                    inputProps={{ name }}
                    defaultValue={value || 'default'}
                    /* label={t(label)} */
                    className='w-100'
                    onChange={valueChanged}
                    sx={{
                        borderRadius: '0px',
                        '& .MuiOutlinedInput-notchedOutline': {
                        // borderColor: '#292929', 기본 테두리 색상
                        borderWidth: '1px', // 기본 테두리 두께
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                        // borderColor: 'black',  호버 시 테두리 색상
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#292929', //  포커스 시 테두리 색상
                        borderWidth: '1px',
                        },
                        '& .MuiSelect-icon': {  
                        color: '#292929',  // 드롭다운 삼각형 버튼 아이콘
                        },
                    }}
                >
                    {data?.map((item: DeviceModels, index: number) => {
                        return (
                            <MenuItem
                                value={item.value}
                                key={item.value + '_' + index}
                                disabled={!item.useYn}
                            >
                                {item.text}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        ) : (
            <>
                <MDBValidationItem
                    className='col-md-6'
                    feedback='Please provide a valid gateway(hub) id.'
                    invalid={true}
                >
                    <TextField /* <MDBInput */
                        type={type}
                        onChange={deviceInputChange}
                        required
                        /* label={t('허브(게이트웨이) ID')} */
                        placeholder={placeholder}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0px !important',
                                width: '350px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '0px !important',
                                borderColor: '#292929 !important',
                                borderWidth: '1px !important',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#292929 !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#292929 !important',
                                borderWidth: '1px !important',
                            }
                        }}
                    />
                </MDBValidationItem>
                {/* <input
                className='w-50 form-control'
                type={type}
                placeholder={placeholder}
                onChange={deviceInputChange}
            /> */}
            </>
        );

    return (
        <div className='addDevice-input-section'>
            {<label htmlFor='deviceModel'>{t(label)}</label>}
            {displayType}
            <div className='d-flex' style={{ gap: '5px' }}>
                {buttonByMethod}
                {/* {refreshButton} */}
            </div>
            {/* CSS 스타일 추가 */}
            <style>{`
                .button {
                    transition: all 0.1s ease; /* 마우스 오버, 클릭시 부드럽게 애니매이션 효과 */
                    box-sizing: border-box;
                    border: 1px solid transparent;
                    background-color: #292929;
                    box-shadow: none;
                }
                .button:hover {
                    /* background-color: #565656 !important; */
                    background-color: #fff !important;
                    color: #000 !important;
                    font-weight: 500 !important;
                    border: 1px solid #000 !important;
                    outline: none;
                    box-shadow: none !important;
                }
                .button:active {
                    background-color: #000 !important;
                    color: #fff !important;
                    font-weight: 500 !important;
                    /* border: 1px solid #292929 !important; */
                    /* transform: scale(0.96) !important; 96% */
                }
            `}</style>
        </div>
    );
};

export default DeviceInputSection;
