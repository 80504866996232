export interface NoResultProps {
    fluid?: boolean;
    message?: string;
    children?: any;
}

const NoResult = (props: NoResultProps) => {
    const defaultMessage = props.message || 'No Result Found';

    return (
        <div className='no-result'>
            {props.children ? props.children : <p>{defaultMessage}</p>}
        </div>
    );
};

export default NoResult;
