import { UserParam } from 'api/user';
import { useAppDispatch } from 'app/hooks';
import { Auth } from 'aws-amplify';
import ProfileChangePasswordDisplay from 'components/display/profileChangePasswordDisplay';
import {
    ChangePasswordInputListProps,
    changePasswordInputs
} from 'constants/changePasswordInputs';
import { changeAuthState, CheckedUser } from 'features/user-slice';
import _ from 'lodash';
import { useEffect, useState } from 'react';

interface ProfileChangePasswordProps {
    userInfo?: UserParam;
    checkedUser: CheckedUser;
    language: string;
}

interface changePasswordProps {
    oldPassword: string;
    newPassword: string;
}

const ProfileChangePasswordContainer = (props: ProfileChangePasswordProps) => {
    const { checkedUser, language } = props;

    /* from redux */
    const dispatch = useAppDispatch();

    /* local state */
    const [changePasswordInputList, setChangePasswordInputList] = useState<
        ChangePasswordInputListProps[]
    >([]);
    const [saveLoader, setSaveLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);

    useEffect(() => {
        if (!errorMessage) return;

        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (showError) return;
        setErrorMessage('');
    }, [showError]);

    useEffect(() => {
        if (_.isEmpty(checkedUser)) return;

        const newProfileDetailsInputList = _.cloneDeep(changePasswordInputs);

        Object.keys(checkedUser).forEach((key) => {
            const find = newProfileDetailsInputList.find(
                (inputProp) => inputProp.name === key
            );

            if (!find) return;

            find.value = checkedUser[key];
            find.validation = true;
        });

        setChangePasswordInputList(newProfileDetailsInputList);
    }, [checkedUser]);

    /* handler */

    /**
     * 비밀번호 validation
     * @param password 
     * @param passwordProp 
     */
    function validatePassword(
        password: string,
        passwordProp: ChangePasswordInputListProps
    ) {
        if (passwordProp.name === 'newPassword') {
            const strongRegex = new RegExp(
                '^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
            );

            if (!password.match(strongRegex)) {
                passwordProp.errorMessage =
                    '비밀번호는 반드시 8자 이상, 특수문자, 번호, 알파벳이 포함되어야 합니다.';
                passwordProp.validation = false;
            } else {
                passwordProp.errorMessage = '';
                passwordProp.validation = true;
            }
        }
    }

    /**
     * 비밀번호 재입력 validation
     * @param password 
     * @param passwordConfirm 
     * @param passwordConfirmProp 
     * @returns 
     */
    function validatePasswordConfirm(
        password: string,
        passwordConfirm: string,
        passwordConfirmProp: ChangePasswordInputListProps
    ) {
        if (passwordConfirmProp.name !== 'passwordConfirm') return;

        if (password === passwordConfirm) {
            passwordConfirmProp.errorMessage = '';
            passwordConfirmProp.validation = true;
        } else {
            passwordConfirmProp.errorMessage = '비밀번호가 일치하지 않습니다.';
            passwordConfirmProp.validation = false;
        }
    }

    /**
     * input element가 변화할 때마다 profileDetailsInputList를 update함
     * @param event
     */
    function inputChangeHandler(event?) {
        const newChangePasswordInputList = [...changePasswordInputList];
        const inputElement = event.target;

        const findIndex = newChangePasswordInputList.findIndex(
            (inputProp) => inputProp.name === inputElement.name
        );
        const find = { ...newChangePasswordInputList[findIndex] };

        find.value = event.target.value;
        find.validation = !!find.value;

        /* password 관련 */
        const passwordFind = newChangePasswordInputList.find(
            (inputProp) => inputProp.name === 'newPassword'
        );

        validatePassword(inputElement.value, find);
        validatePasswordConfirm(passwordFind.value, inputElement.value, find);
        /* password 관련 */

        newChangePasswordInputList[findIndex] = find;
        setChangePasswordInputList(newChangePasswordInputList);
    }

    // 비밀번호 변경 제출
    async function passwordChangeSubmitHandler(event) {
        event.preventDefault();

        setSaveLoader(true);
        setSaved(false);

        const changePasswordProps = changePasswordInputList.reduce(
            (acc, cur) => {
                acc[cur.name] = cur.value;
                return acc;
            },
            {}
        ) as changePasswordProps;

        const { oldPassword, newPassword } = changePasswordProps;

        let user = await Auth.currentAuthenticatedUser().catch((error) => {
            setErrorMessage(error.message || error);
        });

        let result = await Auth.changePassword(
            user,
            oldPassword,
            newPassword
        ).catch((error) => {
            setErrorMessage(error.message || error);
        });

        setSaveLoader(false);

        if (!result) return;
        setSaved(true);
        // 정보 변경이후 sign-in 화면으로 넘어가지 않게함
        dispatch(changeAuthState('signedIn'));
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    /**
     * validation이 하나라도 false가 있다면 button을 disable 함.
     */
    const activateSaveButton = !!changePasswordInputList.find(
        (inputProp) => !inputProp.validation
    );

    return (
        <ProfileChangePasswordDisplay
            language={language}
            checkedUser={props.checkedUser}
            inputChange={inputChangeHandler}
            inputList={changePasswordInputList}
            activateSaveButton={activateSaveButton}
            passwordChangeSubmit={passwordChangeSubmitHandler}
            closeError={closeErrorHandler}
            showError={showError}
            errorMessage={errorMessage}
            saveLoader={saveLoader}
            saved={saved}
        />
    );
};

export default ProfileChangePasswordContainer;
