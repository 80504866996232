import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LanguageState {
    language: string;
}

const initialState: LanguageState = {
    // display할 언어
    language: 'kor',
};

const languageSlice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        updateLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload;
        },
    },
});

export const { updateLanguage } = languageSlice.actions;
export default languageSlice.reducer;
