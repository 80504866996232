import {
    DeviceParams,
    DeviceToPlaceParams,
    getDeviceDetailList,
    getDeviceDetailTraits,
} from 'api/device';
import { getPlaceDevices } from 'api/place';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import LocationMapDisplay from 'components/display/locationMapDisplay';
import NotSupportedDisplay from 'components/display/notSupportedDisplay';
import {
    updateCurrentDevice,
    updateDeviceDetailList,
    updateDeviceList,
    updateDeviceTraits,
} from 'features/display-slice';
import useInterval from 'hooks/useInterval';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { deviceDetailAndTraitCombine } from './locationContainer';

interface LocationMapProps {}

const LocationMapContainer = (props: LocationMapProps) => {
    /* from react-router */
    const navigate = useNavigate();

    /* from redux */
    const dispatch = useAppDispatch();
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentPlaceDeviceList = useAppSelector(
        (state) => state.display.currentPlaceDeviceList
    );
    const currentPlaceDeviceDetailList = useAppSelector(
        (state) => state.display.currentPlaceDeviceDetailList
    );
    const currentPlaceDeviceTraits = useAppSelector(
        (state) => state.display.currentPlaceDeviceTraits
    );
    const currentDevice = useAppSelector(
        (state) => state.display.currentDevice
    );

    const currentPlace = useAppSelector((state) => state.display.currentPlace);

    /* display state */
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {});

    useEffect(() => {
        /* deviceDetailList 와 deviceList를 동기화함 */
        if (
            currentPlaceDeviceList.length <= 0 &&
            currentPlaceDeviceDetailList.length > 0
        ) {
            dispatch(updateDeviceDetailList([]));
        }
    }, [
        currentPlaceDeviceList,
        dispatch,
        currentPlaceDeviceTraits,
        currentPlaceDeviceDetailList.length,
    ]);

    useEffect(() => {
        const combinedDetailList: DeviceParams[] = deviceDetailAndTraitCombine(
            currentPlaceDeviceDetailList,
            currentPlaceDeviceTraits
        );

        // device popup 이 true 일 경우, setting을 변경하였을 때 즉시 적용된 display를 보여줌
        if (!_.isEmpty(combinedDetailList) && !_.isEmpty(currentDevice)) {
            const find: DeviceParams = combinedDetailList.find(
                (deviceDetail) => deviceDetail.uuid === currentDevice?.uuid
            );

            dispatch(updateCurrentDevice(find));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPlaceDeviceDetailList,
        currentPlaceDeviceList,
        currentPlaceDeviceTraits,
        dispatch,
    ]);

    /* fetch devices from current place */
    const updatePlaceDevices = useCallback(() => {
        async function updateDevices() {
            const result = await getPlaceDevices(
                checkedUser.principalId,
                currentPlace?.uuid
            ).catch((error) => {
                console.info('Fail to fetch place devices');
                console.info(error);
            });

            if (!result) return;

            dispatch(updateDeviceList(result));
        }

        updateDevices();
    }, [checkedUser, currentPlace, dispatch]);

    const setDeviceDetailList = useCallback(
        async (deviceList: DeviceToPlaceParams[]) => {
            const newDeviceDetailList = await getDeviceDetailList(
                checkedUser.principalId,
                deviceList
            );
            dispatch(updateDeviceDetailList(newDeviceDetailList));
        },
        [checkedUser, dispatch]
    );

    const setDeviceDetailTraits = useCallback(
        async (deviceList: DeviceToPlaceParams[]) => {
            const result = await getDeviceDetailTraits(
                checkedUser.principalId,
                deviceList
            );
            dispatch(updateDeviceTraits(result));
        },
        [checkedUser, dispatch]
    );

    /* As device list is fetched, dispatching device details to redux state */
    useEffect(() => {
        !_.isEmpty(currentPlaceDeviceList) &&
            setDeviceDetailList(currentPlaceDeviceList);
    }, [currentPlaceDeviceList, setDeviceDetailList]);

    /* As device detail list is fetched, add traits data */
    useEffect(() => {
        !_.isEmpty(currentPlaceDeviceList) &&
            setDeviceDetailTraits(currentPlaceDeviceList);
    }, [currentPlaceDeviceList, setDeviceDetailTraits]);

    /* dispatching device list to redux state from current place */
    useEffect(() => {
        if (!currentPlace) {
            navigate(-1);
        } else {
            updatePlaceDevices();
        }
    }, [checkedUser, currentPlace, navigate, updatePlaceDevices]);

    function objectDblClickHandler(uuid: string) {
        if (combinedDetailList?.length > 0) {
            const find = combinedDetailList.find(
                (deviceDetail) => deviceDetail.uuid === uuid
            );

            dispatch(updateCurrentDevice(find));
        }
    }

    /* thermometer modal display toggle */
    function toggleSettingsModalHandler() {
        setShowSettingsModal(!showSettingsModal);
        // setCurrentDevice(device ?? null);
    }

    /* 30초에 한 번씩 현재 location의 device data를 갱신함*/
    useInterval(() => {
        setDeviceDetailTraits(currentPlaceDeviceList);
    }, 30000);

    const combinedDetailList = deviceDetailAndTraitCombine(
        currentPlaceDeviceDetailList,
        currentPlaceDeviceTraits
    );

    /* 모바일 접속시 안내메시지 출력 */
    // @ts-ignore
    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-width: 800px)').matches) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    });

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    return (
        <>
            {isMobile ? (
                <NotSupportedDisplay />
            ) : (
                <LocationMapDisplay
                    currentPlace={currentPlace}
                    currentPlaceDeviceDetailList={combinedDetailList}
                    objectDblClick={objectDblClickHandler}
                    currentDevice={currentDevice}
                    toggleSettingsModal={toggleSettingsModalHandler}
                />
            )}
        </>
    );
};

export default LocationMapContainer;
