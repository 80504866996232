import PaymentModal from 'components/layout/paymentModal';
import { MDBModalFooter } from 'mdb-react-ui-kit';
import paymentImg from 'assets/img/payment_icon_yellow_large.png';
import { PaymentDetailProps } from 'containers/profile/profilePaymentContainer';
import { localized_language } from 'constants/localizer';
import { useTranslation } from 'react-i18next';

interface PaymentPopupDisplayProps {
    showModal: boolean;
    setShowModal;
    toggleModalHandler: () => void;
    paymentDetail: PaymentDetailProps;
    language: string;
    kakaoPaymentClickHandler: (payment) => void;
}

const PaymentPopupDisplay = ({
    showModal,
    setShowModal,
    toggleModalHandler,
    paymentDetail,
    language,
    kakaoPaymentClickHandler,
}: PaymentPopupDisplayProps) => {
    const { t, i18n } = useTranslation(['trans']);

    return (
        <PaymentModal
            modalTitle={localized_language[`${language}_payment_header`]}
            showModal={showModal}
            setShowModal={setShowModal}
            toggleModalHandler={toggleModalHandler}
            paymentModalSubmitHandler={function (event?: any): void {
                event.preventDefault();

                // throw new Error('Function not implemented.');
            }}
        >
            <ol className='list-unstyled mb-0'>
                <li className='my-3'>
                    {t('상품명')} : {paymentDetail?.productName}
                </li>
                <li className='my-3'>
                    {t('서비스 이름')} : {paymentDetail?.service}
                </li>
                <li className='my-3'>
                    {t('가격')} : {paymentDetail?.price}{' '}
                    {localized_language[`kor_price_unit`]}
                </li>
            </ol>
            <MDBModalFooter className='justify-content-center p-0'>
                <img
                    style={{
                        cursor: 'pointer',
                    }}
                    src={paymentImg}
                    alt=''
                    onClick={kakaoPaymentClickHandler}
                />
            </MDBModalFooter>
        </PaymentModal>
    );
};

export default PaymentPopupDisplay;
