import { DeviceParams } from 'api/device';
import DeviceSecondTable from 'components/fragment/deviceSecondTable';
import HubDeviceTable from 'components/fragment/hubDeviceTable';
import IconBtn from 'components/fragment/iconBtn';
import ConnectedDeviceModal from 'components/layout/connectedDeviceModal';
import AddDeviceContainer from 'containers/location/addDeviceContainer';
import { MDBBtn, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

export interface DeviceListProps {
    childDeviceList;
    allDeviceList: DeviceParams[];
    hubs: DeviceParams[];
    showModal: boolean;
    setShowModal;
    toggleModalHandler: (event?) => void;
    selectHubDeviceListHandler: (temp?) => void;
    loader: boolean;
    selectedHub: DeviceParams;
    currentHubDevices: DeviceParams[];
    unsubscribeClickHandler;
    subscribeClickHandler;
    hubDeleteClickHandler;
    currentDeviceSettingClickHandler;
    toggleSettingModalHandler;
}

const DeviceListDisplay = (props: DeviceListProps) => {
    const {
        childDeviceList: connectedDeviceList,
        allDeviceList,
        hubs,
        showModal,
        setShowModal,
        currentHubDevices,
        toggleModalHandler,
        selectHubDeviceListHandler,
        loader,
        selectedHub,
        subscribeClickHandler,
        unsubscribeClickHandler,
        hubDeleteClickHandler,
        currentDeviceSettingClickHandler,
        toggleSettingModalHandler,
    } = props;

    const { t, i18n } = useTranslation(['trans']);

    return (
        <>
            <MDBContainer
                fluid
                className="subpage-container pt-2 px-lg-5"
            >
                <div className="label-header">
                    <div className="w-100">
                        <h2 className="m-0">{t("LOCAD0032", "장치 목록")}</h2>
                    </div>
                </div>

                <div className="table-box">
                    <DeviceSecondTable
                        loader={loader}
                        body={hubs}
                        showModal={showModal}
                        toggleModalHandler={toggleModalHandler}
                        selectHubDeviceListHandler={selectHubDeviceListHandler}
                        hubDeleteClickHandler={hubDeleteClickHandler}
                    />
                </div>
            </MDBContainer>
            <ConnectedDeviceModal
                showModal={showModal}
                setShowModal={setShowModal}
                toggleModalHandler={toggleModalHandler}
                modalTitle={selectedHub?.nickname}
            >
                <HubDeviceTable
                    body={currentHubDevices}
                    subscribeClick={subscribeClickHandler}
                    unsubscribeClick={unsubscribeClickHandler}
                    currentDeviceSettingClickHandler={currentDeviceSettingClickHandler}
                    toggleSettingModalHandler={toggleSettingModalHandler}
                />
            </ConnectedDeviceModal>
        </>
    );
};

export default DeviceListDisplay;
