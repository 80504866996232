import { API } from 'aws-amplify';

const apiName = 'RaonixCloud';

export interface AlarmCondition {
    max?: number;
    min?: number;
}

export interface AlarmParams {
    alarm?: boolean;
    alarmCondition?: AlarmCondition;

    /* custom props. don't post/put with below props */
    traitUuid?: string;
    name?: string;
    unit?: string;
}

export function queryTraitAlarms(principalId, params) {
    let path = `/traits/${params.uuid}/alarm/records?date=${params.start}&date=${params.end}`;

    return API.get(apiName, path, {
        response: true,
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
    })
        .then((result) => {
            return result;
        })
        .catch((error) => {
            switch (error.response.status) {
                case 404:
                    return { data: [{ date: 'No Data.', value: 'No Data.' }] };
                default:
                    break;
            }
            return error;
        });
}

export function queryTraitReports(principalId, params) {
    let path = `/traits/${params.uuid}/record/records?date=${params.start}&date=${params.end}`;

    return API.get(apiName, path, {
        response: true,
        queryStringParameters: {},
        headers: {
            principalId: principalId,
        },
    })
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export function queryTraitGetAlarm(principalId, uuid) {
    let path = `/traits/${uuid}/alarm`;

    return API.get(apiName, path, {
        response: true,
        headers: {
            principalId: principalId,
        },
    });
}

export function queryTraitPutAlarm(principalId, uuid, params) {
    let path = `/traits/${uuid}/alarm`;

    return API.put(apiName, path, {
        headers: {
            principalId: principalId,
        },
        response: true,
        body: params,
    })
        .then((result) => result)
        .catch((error) => {
            console.error(error);
            return error;
        });
}
