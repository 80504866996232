import { queryPaymentReadyPost } from 'api/payment';
import { useAppSelector } from 'app/hooks';
import PaymentPopupDisplay from 'components/display/paymentPopupDisplay';
import ProfilePaymentDisplay from 'components/display/profilePaymentDisplay';
import { localized_language } from 'constants/localizer';
import { UserData } from 'features/user-slice';
import { useEffect, useState } from 'react';

interface ProfilePatmentContainerProps {
    userData: UserData;
    language: string;
}

export interface PaymentDetailProps {
    service: string;
    productName: string;
    price: string;
}

const ProfilePaymentContainer = ({
    userData,
    language,
}: ProfilePatmentContainerProps) => {
    /* from redux */
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    /* local state */
    // display
    const [showModal, setShowModal] = useState<boolean>(false);
    const [paymentType, setPaymentType] = useState(null);
    const [paymentDetail, setPaymentDetail] =
        useState<PaymentDetailProps>(null);

    useEffect(() => {
        if (!paymentType) return;

        const rating = paymentType.rating;

        const service = localized_language[`${language}_${rating}`];
        const productName = `${localized_language[`${language}_company`]} ${
            localized_language[`${language}_${rating}`]
        }`;
        const price =
            localized_language[`kor_${rating}_price`].toLocaleString();

        const payment: PaymentDetailProps = {
            service,
            productName,
            price,
        };

        setPaymentDetail(payment);
    }, [language, paymentType]);

    /* handler */
    // modal open
    function toggleModalHandler() {
        setShowModal(!showModal);
    }

    // 결제 버튼 클릭
    function paymentButtonClickHandler(planItem) {
        setPaymentType(planItem);
    }

    // kakao payment popup open
    function payRedirectPopup(url: string) {
        window.open(
            url,
            'window_name',
            'width=430,height=500,location=no,status=no,scrollbars=yes'
        );
    }

    async function kakaoPaymentClickHandler(event) {
        await queryPaymentReadyPost(checkedUser.principalId, paymentType.rating)
            .then((response) => {
                let body = response.data;
                payRedirectPopup(body.next_redirect_pc_url);
            })
            .catch((error) => console.info(error));
    }

    return (
        <>
            <ProfilePaymentDisplay
                toggleModalHandler={toggleModalHandler}
                paymentButtonClickHandler={paymentButtonClickHandler}
                userData={userData}
                language={language}
            />
            <PaymentPopupDisplay
                paymentDetail={paymentDetail}
                showModal={showModal}
                setShowModal={setShowModal}
                toggleModalHandler={toggleModalHandler}
                kakaoPaymentClickHandler={kakaoPaymentClickHandler}
                language={language}
            />
        </>
    );
};

export default ProfilePaymentContainer;
