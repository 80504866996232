import { useTranslation } from 'react-i18next';
import IntlTelInput, { CountryData } from 'react-intl-tel-input';

interface CountryCodeInputsProps {
    horizontal?: boolean;
    small?: boolean;
    label?: string;
    id?: string;
    inputChange?: (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string
    ) => void;
    message?: string;
    messageClassName?: string;
    defaultValue?: string;
    value?: string;
    flagChange?: (
        currentNumber: string,
        selectedCountryData: CountryData,
        fullNumber: string,
        isValid: boolean
    ) => void;
}

const CountryCodeInputs = (props: CountryCodeInputsProps) => {
    const {
        horizontal,
        small,
        label,
        id,
        inputChange,
        message,
        messageClassName,
        defaultValue,
        flagChange,
    } = props;

    /* from i18n */
    const { t, i18n } = useTranslation(['trans']);

    let defaultClassName = 'input-section';

    if (horizontal) {
        defaultClassName = defaultClassName + ' horizontal';
    } else if (small) {
        defaultClassName = defaultClassName + ' small';
    }

    return (
        <>
            <div className={defaultClassName}>
                <label className='form-label m-0 mb-1' htmlFor={id}>
                    {t(label)}
                </label>
                <IntlTelInput
                    defaultValue={defaultValue}
                    preferredCountries={['us', 'kr']}
                    //style={{ display: 'block', width: '100%' }}
                    autoPlaceholder
                    containerClassName='intl-tel-input'
                    inputClassName='form-control'
                    onPhoneNumberChange={inputChange}
                    onSelectFlag={flagChange}
                />
                {message && (
                    <small
                        className={
                            messageClassName ? messageClassName : 'text-danger'
                        }
                    >
                        {message}
                    </small>
                )}
            </div>
        </>
    );
};

export default CountryCodeInputs;
