import { AlarmListParams } from 'api/record';
import { DeviceinfoDisplayProps } from 'containers/location/addDeviceContainer';
import { MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TableFragProps {
    head: string[];
    body: AlarmListParams[];
    numberOfRows?: number;
    fullHeight?: boolean;
    addClick?: (event) => void;
    deviceInfoDisplayProps?: DeviceinfoDisplayProps;
    alarmCount: number;
    loader: boolean;
}

interface AlarmTableProps {
    body: AlarmListParams[];
    loader: boolean;
    rowSelectClickHandler;
}

const AlarmTable = (props: AlarmTableProps) => {
    const { body, loader, rowSelectClickHandler } = props;

    const { t, i18n } = useTranslation(['trans']);
    
    const [maxHeight, setMaxHeight] = useState<string>('500px');
    const [sm, setSm] = useState<boolean>(false);

    // @ts-ignore
    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-height: 800px)').matches) {
            setMaxHeight('250px');
            setSm(true);
        } else {
            setMaxHeight('500px');
            setSm(false);
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    const dummy = [
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
    ];

    const actionData = {
        columns: [
            { label: '#', field: 'index' },
            {
                label: t("LOCAD0020", "확인여부"),
                field: "checked",
                width: 50,
            },
            { label: t("LOCAD0021", "날짜"), field: "localeDate" },
            {
                label: t("LOCAD0022", "내용"),
                field: "message",
                /*
                attributes: {
                    style: {
                        textAlign: "left", // 인라인 스타일로 왼쪽 정렬
                    },
                },
                */
                 className: 'table-columns-align-left'  // 특정 열에 왼쪽 정렬 적용
            },
        ],
        rows: (body ? body : []).map((row: AlarmListParams, index: number) => {
            return {
                index: index+1,
                ...row,
                checked: row.checked ? t("LOCAD0038", "확인") : t("LOCAD0039", "미확인"),
            };
        }),
    };

    return (
        <>
            <MDBDatatable
                multi
                selectable
                maxHeight={maxHeight}
                isLoading={loader}
                fixedHeader
                fullPagination
                hover
                sm={sm}
                data={actionData}
                entriesOptions={[10, 20, 50]}
                onSelectRow={rowSelectClickHandler}
                dark
                className='custom-dark-table'
            />
            {/**
        <MDBDatatable
            multi
            selectable
            maxHeight={maxHeight}
            isLoading={loader}
            fixedHeader
            fullPagination
            hover
            sm={sm}
            data={actionData}
            entriesOptions={[10, 20, 50]}
            onSelectRow={rowSelectClickHandler}
        />
             */}
        </>
    );
};

export default AlarmTable;
