import {
    queryNotificationSmsDelete,
    queryNotificationSmsGet,
    queryNotificationSmsPost,
    UserParam
} from 'api/user';
import { useAppDispatch } from 'app/hooks';
import { Auth } from 'aws-amplify';
import ProfileDetailsDisplay from 'components/display/userProfileDetailsDisplay';
import {
    ProfileDetailsInputListProps,
    profileDetailsInputs
} from 'constants/profileDetails';
import { changeAuthState, CheckedUser } from 'features/user-slice';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CountryData } from 'react-intl-tel-input';

interface ProfileDetailsProps {
    userInfo?: UserParam;
    checkedUser: CheckedUser;
    language: string;
}

interface SaveProps {
    username?: string;
    name: string;
    phone_number: string;
}

const ProfileDetailsContainer = (props: ProfileDetailsProps) => {
    const { checkedUser, language } = props;

    /* from redux */
    const dispatch = useAppDispatch();

    /* local state */
    const [profileDetailsInputList, setProfileDetailsInputList] = useState<
        ProfileDetailsInputListProps[]
    >([]);
    const [saveLoader, setSaveLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);

    useEffect(() => {
        if (!errorMessage) return;

        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (showError) return;
        setErrorMessage('');
    }, [showError]);

    /**
     * sms를 서비스가 등록되어 있는 핸드폰을 조회
     * @param principalId 
     * @returns 
     */
    async function getNotificationSms(principalId) {
        const result = await queryNotificationSmsGet(principalId)
            .then((response) => response.data)
            .catch((error) => console.info(error));

        return (result as string[]) || null;
    }

    /**
     * sms 서비스가 등록되어 있는 핸드폰을 삭제함
     * @param principalId 
     * @param oldPhone 
     * @returns 
     */
    async function deleteNotificationSms(
        principalId: string,
        oldPhone: string
    ) {
        return await queryNotificationSmsDelete(principalId, [oldPhone]).catch(
            (error) => console.info(error)
        );
    }

    /**
     * sms 서비스를 받기 위한 핸드폰을 등록
     * @param principalId 
     * @param newPhone 
     * @returns 
     */
    async function postNotificationSms(principalId: string, newPhone: string) {
        return await queryNotificationSmsPost(principalId, [newPhone]).catch(
            (error) => console.info(error)
        );
    }

    /* 이전에 등록 돼 있는 핸드폰을 삭제하고 변경한 핸드폰으로 sms alarm을 받도록 변경 */
    useEffect(() => {
        async function run() {
            const notificationSmsResult = await getNotificationSms(
                checkedUser.principalId
            );

            if (!notificationSmsResult) return;

            const oldPhone = notificationSmsResult[0];
            const newPhone = checkedUser.phone_number;

            if (oldPhone !== newPhone) {
                await deleteNotificationSms(checkedUser.principalId, oldPhone);
                await postNotificationSms(checkedUser.principalId, newPhone);
            }
        }

        if (!checkedUser) return;

        run();
    }, [checkedUser]);

    // 현재 로그인한 유저의 정보를 토대로, input field의 값을 유저 정보로 기재함
    useEffect(() => {
        if (_.isEmpty(checkedUser)) return;

        // from contants (empty user info)
        const newProfileDetailsInputList = _.cloneDeep(profileDetailsInputs);

        // checkedUser와 key-value를 비교하여, 매칭되는 key에 현재 접속한 유저의 정보로 value를 설정함.
        Object.keys(checkedUser).forEach((key) => {
            const find = newProfileDetailsInputList.find(
                (inputProp) => inputProp.name === key
            );

            if (!find) return;

            find.value = checkedUser[key];
            find.validation = true;
        });

        setProfileDetailsInputList(newProfileDetailsInputList);
    }, [checkedUser]);

    /* handler */

    /**
     * phone_number 가 변화할 때마다 profileDetailsInputList를 udpate함
     * @param isValid
     * @param value
     * @param selectedCountryData
     * @param fullNumber
     */
    function phoneNumberChangeHandler(
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber
    ) {
        const newProfileDetailsInputList = [...profileDetailsInputList];

        const findIndex = newProfileDetailsInputList.findIndex(
            (inputProp) => inputProp.name === 'phone_number'
        );
        const find = { ...newProfileDetailsInputList[findIndex] };

        const formattedNumber = `${fullNumber
            .replaceAll('-', '')
            .replace(' ', '')}`;

        find.value = formattedNumber;
        find.validation = isValid;
        // 유효하지 않으면 errorMessage 출력
        find.errorMessage = isValid ? '' : `It's not valid format`;

        newProfileDetailsInputList[findIndex] = find;
        setProfileDetailsInputList(newProfileDetailsInputList);
    }

    /**
     * flag를 select할 때마다 inputProp의 validation을 변화시킴
     * @param currentNumber
     * @param selectedCountryData
     * @param fullNumber
     * @param isValid
     */
    function flagChangeHandler(
        currentNumber: string,
        selectedCountryData: CountryData,
        fullNumber: string,
        isValid: boolean
    ) {
        const newProfileDetailsInputList = [...profileDetailsInputList];

        const findIndex = newProfileDetailsInputList.findIndex(
            (inputProp) => inputProp.name === 'phone_number'
        );
        const find = { ...newProfileDetailsInputList[findIndex] };

        find.validation = isValid;
        find.errorMessage = isValid ? '' : `It's not valid format`;

        newProfileDetailsInputList[findIndex] = find;

        setProfileDetailsInputList(newProfileDetailsInputList);
    }

    /**
     * input element가 변화할 때마다 profileDetailsInputList를 update함
     * @param event
     */
    function inputChangeHandler(event?) {
        const newProfileDetailsInputList = [...profileDetailsInputList];

        const findIndex = newProfileDetailsInputList.findIndex(
            (inputProp) => inputProp.name === event.target.name
        );
        const find = { ...newProfileDetailsInputList[findIndex] };

        find.value = event.target.value;
        find.validation = !!find.value;

        newProfileDetailsInputList[findIndex] = find;
        setProfileDetailsInputList(newProfileDetailsInputList);
    }

    async function profileDetailSaveHandler(event) {
        event.preventDefault();

        setSaveLoader(true);
        setSaved(false);

        const { name, phone_number } = profileDetailsInputList.reduce(
            (acc, cur) => {
                acc[cur.name] = cur.value;

                return acc;
            },
            {}
        ) as SaveProps;

        let user = await Auth.currentAuthenticatedUser().catch((error) => {
            setErrorMessage(error.message || error);
        });

        let result = await Auth.updateUserAttributes(user, {
            phone_number,
            name,
        }).catch((error) => {
            setErrorMessage(error.message || error);
        });

        setSaveLoader(false);

        if (!result) return;
        setSaved(true);
        // 정보 변경이후 sign-in 화면으로 넘어가지 않게함
        dispatch(changeAuthState('signedIn'));
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    /**
     * validation이 하나라도 false가 있다면 button을 disable 함.
     */
    const activateSaveButton = !!profileDetailsInputList.find(
        (inputProp) => !inputProp.validation
    );

    return (
        <ProfileDetailsDisplay
            language={language}
            flagChange={flagChangeHandler}
            checkedUser={props.checkedUser}
            phoneNumberChange={phoneNumberChangeHandler}
            inputChange={inputChangeHandler}
            inputList={profileDetailsInputList}
            activateSaveButton={activateSaveButton}
            profileDetailSave={profileDetailSaveHandler}
            closeError={closeErrorHandler}
            showError={showError}
            errorMessage={errorMessage}
            saveLoader={saveLoader}
            saved={saved}
        />
    );
};

export default ProfileDetailsContainer;
