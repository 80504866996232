import IconBtn from 'components/fragment/iconBtn';
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit';

export interface UpdateLocationModal {
    modalTitle: string;
    showModal: boolean;
    edit?: boolean;
    toggleModalHandler: () => void;
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
    children?: any;
    btnText?: string;
    paymentModalSubmitHandler: (event?) => void;
}

const PaymentModal = ({
    modalTitle,
    showModal,
    toggleModalHandler,
    setShowModal,
    children,
    btnText,
    paymentModalSubmitHandler,
}: UpdateLocationModal) => {
    return (
        <form onSubmit={paymentModalSubmitHandler}>
            <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
                <MDBModalDialog centered size='sm' scrollable>
                    <MDBModalContent>
                        <MDBModalHeader className='modal-header'>
                            <span></span>
                            <MDBModalTitle className='modal-title'>
                                {modalTitle}
                            </MDBModalTitle>
                            <IconBtn
                                type='close'
                                ariaLabel='Close'
                                onClick={toggleModalHandler}
                            />
                        </MDBModalHeader>
                        <MDBModalBody className='p-4'>{children}</MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </form>
    );
};

export default PaymentModal;
