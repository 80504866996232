export interface RegisterInputListProps {
    type: string;
    id: string;
    name: string;
    label: string;
    placeholder: string;
    required: boolean;
    value: string;
    message?: string;
    validation?: boolean;
    addressFind?: boolean;
}

const registerInputs = [
    {
        type: 'email',
        id: 'email',
        name: 'username',
        label: 'Email',
        placeholder: 'email@email.com',
        required: true,
        value: '',
        validation: null,
    },
    {
        type: 'text',
        id: 'name',
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        required: true,
        value: '',
        validation: null,
    },
    {
        type: 'tel',
        id: 'phone_number',
        name: 'phone_number',
        label: 'Phone Number',
        placeholder: '010-0000-0000',
        required: true,
        value: '',
        validation: null,
    },
    {
        type: 'text',
        id: 'address',
        name: 'address',
        label: 'Address',
        placeholder: 'Address',
        required: true,
        addressFind: true,
        value: '',
        validation: null,
    },
    {
        type: 'text',
        id: 'addressDetail',
        name: 'addressDetail',
        label: 'Address-Detail',
        placeholder: 'Address-Detail',
        required: true,
        value: '',
        validation: null,
    },
    {
        type: 'password',
        id: 'password',
        name: 'password',
        label: '비밀번호',
        placeholder: 'Password',
        required: true,
        message: '',
        value: '',
        validation: null,
    },
    {
        type: 'password',
        id: 'passwordConfirm',
        name: 'passwordConfirm',
        label: 'Password Confirm',
        placeholder: 'Password',
        required: true,
        message: '',
        value: '',
        validation: null,
    },
];

export { registerInputs };
