import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface signupState {
    username: string;
}

const initialState: signupState = {
    // 가입 후 username을 저장
    username: '',
};

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        updateUsername(state, action: PayloadAction<string>) {
            state.username = action.payload;
        },
    },
});

export const { updateUsername } = signupSlice.actions;
export default signupSlice.reducer;
