import TermsOfService from 'components/display/termsOfService';
import DashBoard from 'components/layout/dashBoard';
import DeviceListContainer from 'containers/deviceListContainer';
import LocationContainer from 'containers/location/locationContainer';
import PhsLogin from 'containers/phsLogin';
import RegisterContainer from 'containers/registerContainer';
import ReportContainer from 'containers/reportContainer';
import UserProfileContainer from 'containers/userProfileContainer';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { HubPayload } from '@aws-amplify/core';
import { queryUserGet } from 'api/user';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Amplify, Auth, Hub } from 'aws-amplify';
import config from 'config';
import AlarmContainer from 'containers/alarmContainer';
import ConfirmSignUpCoantainer from 'containers/confirmSignUpContainer';
import ListReportContainer from 'containers/listReportContainer';
import LocationMapContainer from 'containers/location/locationMapContainer';
import PasswordResetContainer from 'containers/passwordResetContainer';
import ReportListContainer from 'containers/reportListContainer';
import {
    changeAuthState,
    CheckedUser,
    setCheckedUser,
    updateUserData,
    UserData
} from 'features/user-slice';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import ProfilePaymentContainer from 'containers/profile/profilePaymentContainer';
import { COLOR_MAIN_BG } from 'constants/common';
import DeviceSettingsContainer from 'containers/deviceSettingsContainer';

Amplify.configure({
    Auth: config.awsAuth,
    API: config.awsAPI,
    Storage: config.awsStorage,
});

function App() {
    const [principalId, setPrincipalId] = useState<string>();

    const authState = useAppSelector((state) => state.authState.authState);
    const userData = useAppSelector((state) => state.authState.userData);
    const language = useAppSelector((state) => state.language.language);

    const dispatch = useAppDispatch();

    // const authState = useAppSelector((state) => state.authState.authState);
    const navigate = useNavigate();

    function onAuthEvent(response: {
        channel?: string;
        payload?: HubPayload;
        source?: string;
        patternInfo?: string[];
        event?: any;
    }) {
        switch (response.payload.event) {
            case 'signIn':
                loadUser();
                break;
            case 'tokenRefresh':
                loadUser();
                break;
            case 'signUp':
                break;
            case 'signOut':
                dispatch(changeAuthState('signIn'));
                break;
            case 'signIn_failure':
                dispatch(changeAuthState('signIn'));
                break;
            case 'configured':
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        Hub.listen('auth', (response) => {
            onAuthEvent(response);
        });
    }, [authState]);

    useEffect(() => {
        loadUser();
    }, []);


    /**
     * 유저정보 조회 
     * (주로 profile에서 사용됨)
     * @param principalId 
     * @returns 
     */
    async function getUser(principalId: string) {
        const userResult = await queryUserGet(principalId)
            .then((response) => response.data)
            .catch((error) => console.info(error));

        return (userResult as UserData) || null;
    }

    /**
     * 유저정보 조회 후 가공하여 redux-state에 저장
     */
    async function loadUser() {
        const userResult = await Auth.currentAuthenticatedUser().catch(
            (error) => {
                console.info(error);
            }
        );

        let checkedUser: CheckedUser = {
            username: '',
            name: '',
            principalId: '',
        };

        if (userResult?.attributes) {
            // Cognitor login
            const {
                email: username,
                name,
                sub: principalId,
            } = userResult.attributes;

            checkedUser = {
                ...userResult.attributes,
                username,
                name,
                principalId,
            };
        } else if (userResult?.id) {
            // TODO : 구글 로그인시 username 체크 !!!
            const { username, id: principalId } = userResult;

            checkedUser = {
                ...userResult.attributes,
                username,
                principalId,
            };
        }

        const userGetResult = await getUser(checkedUser.principalId);

        if (userResult) {
            dispatch(changeAuthState('signedIn'));
            dispatch(setCheckedUser(checkedUser));
            dispatch(updateUserData(userGetResult));
            setPrincipalId(principalId);
        }

        if (!userResult) {
            dispatch(changeAuthState('signIn'));
            navigate('/', { replace: true });
        }
    }

    return (
        <div className="App" 
        style={{
            backgroundColor: COLOR_MAIN_BG
        }}>
            <Toaster position="top-center" reverseOrder={false} />
            <Routes>
                <Route path="/" element={<Navigate to="/phs-login" />} />
                <Route
                    path="/phs-login"
                    element={authState === "signedIn" ? <Navigate to="/dashboard/location" /> : <PhsLogin />}
                />
                <Route path="/register" element={<RegisterContainer />} />
                <Route path="/confirm-signup" element={<ConfirmSignUpCoantainer />} />
                <Route path="/reset-password" element={<PasswordResetContainer />} />
                <Route path="/policy" element={<TermsOfService />} />
                <Route path="/dashboard" element={<DashBoard showSideNav />}>
                    <Route index element={<Navigate to="location" />} />
                    {/* <Route path='location' element={<LocationContainer />} /> */}
                    <Route path="location" element={<LocationContainer />} />
                    <Route path="alarms" element={<AlarmContainer />} />
                    <Route path="reports" element={<ReportContainer />} />
                    <Route path="location-map" element={<LocationMapContainer />} />
                    <Route path="deviceSetting" element={<DeviceSettingsContainer />} />
                </Route>

                <Route path="/device" element={<DashBoard showSideNav={true} />}>
                    <Route index element={<DeviceListContainer />}></Route>
                </Route>
                <Route path="/report-list" element={<DashBoard showSideNav={true} />}>
                    <Route index element={<ReportListContainer />} />
                    <Route path="report" element={<ListReportContainer />} />
                </Route>
                <Route path="profile/*" element={<DashBoard showSideNav={false} />}>
                    <Route path="*" element={<UserProfileContainer principalId={principalId} />} />
                </Route>
                <Route path="billing" element={<DashBoard showSideNav={false} />}>
                    <Route index element={<ProfilePaymentContainer userData={userData} language={language} />} />
                </Route>
                <Route path="*" element={<Navigate to="phs-login" />} />
            </Routes>
        </div>
    );
}

export default App;
