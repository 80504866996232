import { useAppDispatch } from 'app/hooks';
import { Auth } from 'aws-amplify';
import PasswordResetDisplay from 'components/display/passwordResetDisplay';
import {
    PasswordResetInputListProps,
    passwordResetInputs,
} from 'constants/passwordResetInputs';

import { updateUsername } from 'features/signup-slice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ResetPasswordProps {
    username: string;
    password: string;
    confirmationCode: string;
}

const PasswordResetContainer = (props) => {
    /* from react-router */
    const navigate = useNavigate();

    /* from redux */
    const dispatch = useAppDispatch();

    /* local state */
    const [passwordResetInputList, setPasswordResetInputList] = useState<
        PasswordResetInputListProps[]
    >([...passwordResetInputs]);
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    useState<boolean>(false);
    const [codeSentLoader, setCodeSentLoader] = useState<boolean>(false);
    const [activateResetSubmitButton, setActivateResetSubmitButton] =
        useState<boolean>(false);
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);

    /* submit 이후에 error가 발생하여 error message를 변화시킬 경우 error popup 띄움 */
    useEffect(() => {
        if (!errorMessage) return;

        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (showError) return;
        setErrorMessage('');
    }, [showError]);

    /* unmount 될 때 error message를 off함 */
    useEffect(() => {
        return () => setShowError(false);
    }, []);

    // input의 change event 가 발생될 때마다, validation check를 하고, 만약 validation이 true일 경우 버튼을 activate 함
    useEffect(() => {
        const find = passwordResetInputList.find((input) => {
            return input.hasOwnProperty('validation') && !input.validation;
        });

        find
            ? setActivateResetSubmitButton(false)
            : setActivateResetSubmitButton(true);
    }, [passwordResetInputList]);

    // reset-code를 성공적으로 보낼 시, readonly로 되어 있던 input들을 readonly를 해제함.
    useEffect(() => {
        const newPasswordResetInputList = [...passwordResetInputList];

        //code를 보낼 때 codeSent property를 가진 object를 not-readonly로 변경함
        newPasswordResetInputList.forEach((input) => {
            input.hasOwnProperty('codeSent') && (input.codeSent = codeSent);
        });

        setPasswordResetInputList(newPasswordResetInputList);
    }, [codeSent]);

    // 비밀번호 validation
    function validatePassword(
        password: string,
        passwordProp: PasswordResetInputListProps
    ) {
        if (passwordProp.name !== 'password') return;

        const strongRegex = new RegExp(
            '^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
        );

        if (!password.match(strongRegex)) {
            passwordProp.message =
                '비밀번호는 반드시 8자 이상, 특수문자, 번호, 알파벳이 포함되어야 합니다.';
            passwordProp.validation = false;
        } else {
            passwordProp.message = '';
            passwordProp.validation = true;
        }
    }

    // 비밀번호 재입력 validation
    function validatePasswordConfirm(
        password: string,
        passwordConfirm: string,
        passwordConfirmProp: PasswordResetInputListProps
    ) {
        if (passwordConfirmProp.name !== 'passwordConfirm') return;

        if (password === passwordConfirm) {
            passwordConfirmProp.message = '';
            passwordConfirmProp.validation = true;
        } else {
            passwordConfirmProp.message = '비밀번호가 일치하지 않습니다.';
            passwordConfirmProp.validation = false;
        }
    }



    /* handler */
    
    async function resetPasswordSubmitHandler(event) {
        event.preventDefault();
        setSubmitLoader(true);

        const resetPasswordProps = [...passwordResetInputList].reduce(
            (acc, cur) => {
                acc[cur.name] = cur.value;

                return acc;
            },
            {}
        ) as ResetPasswordProps;

        const { username, confirmationCode, password } = resetPasswordProps;

        const resetPasswordResult = await Auth.forgotPasswordSubmit(
            username,
            confirmationCode,
            password
        ).catch((error) => setErrorMessage(error.message || error));

        setSubmitLoader(false);

        if (!resetPasswordResult) return;
        dispatch(updateUsername(username));
        navigate('/');
    }

    async function resendCodeHandler(event?) {
        setCodeSentLoader(true);
        setCodeSent(false);

        const newPasswordResetInputList = [...passwordResetInputList];

        const usernameIndex = newPasswordResetInputList.findIndex(
            (input) => input.name === 'username'
        );

        const username = {
            ...newPasswordResetInputList[usernameIndex],
        };

        //message를 초기화하여 display 하지 않고,
        username.message = '';
        username.errorMessage = '';

        const codeSentResult = await Auth.forgotPassword(username.value).catch(
            (error) => {
                setErrorMessage(error.message || error);
                username.errorMessage = error.message || error;
            }
        );

        setCodeSentLoader(false);

        if (codeSentResult) {
            username.message = 'Code sent Successfully.';
            setCodeSent(true);
        }

        newPasswordResetInputList[usernameIndex] = username;
        setPasswordResetInputList(newPasswordResetInputList);
    }

    function inputChangeHandler(event) {
        const input = event?.target;
        const inputList = [...passwordResetInputList];

        const findIndex = inputList.findIndex(
            (inputProp) => inputProp.name === input.name
        );

        const find = {
            ...inputList[findIndex],
        };

        find.value = input.value;
        input.value ? (find.validation = true) : (find.validation = false);

        /* password 관련 */
        const passwordFind = inputList.find(
            (inputProp) => inputProp.name === 'password'
        );

        validatePassword(input.value, find);
        validatePasswordConfirm(passwordFind.value, input.value, find);
        /* password 관련 */

        inputList[findIndex] = find;
        setPasswordResetInputList(inputList);
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    return (
        <PasswordResetDisplay
            submitLoader={submitLoader}
            resetPasswordLoader={submitLoader}
            codeSentLoader={codeSentLoader}
            activateResetSubmitButton={activateResetSubmitButton}
            resetPasswordSubmit={resetPasswordSubmitHandler}
            resendCode={resendCodeHandler}
            showError={showError}
            errorMessage={errorMessage}
            codeSent={codeSent}
            passwordResetInputList={passwordResetInputList}
            inputChangeHandler={inputChangeHandler}
            closeError={closeErrorHandler}
        />
    );
};
export default PasswordResetContainer;
