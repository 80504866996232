import StyledDeviceFooterButton from "components/common/styledDeviceFooterButton";
import { COLOR_MAIN_BG } from "constants/common";
import { MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import imgConnected from 'assets/img/connected.png';
import imgDisconnected from 'assets/img/disconnected.png';
import { findTrait } from "utils";

const StyledMDBCard = styled(MDBCard)`
    width: 260px !important; /* 카드 넓이 설정 */
    background-color: ${COLOR_MAIN_BG};
    margin: 5px; /* 카드 사이의 간격 설정 */
    flex-shrink: 0; /* 카드 크기가 줄어들지 않도록 설정 */
    border-radius: 0 !important;
    border: 1px solid white;
    color: white;

    transition: transform 0.3s ease-in-out; /* 부드러운 전환 효과 */
    &:hover {
        transform: scale(1.02); /* 마우스 오버 시 102%로 확대 */
    }
    box-shadow: ${props => props.isError ? '0 0 0 7px red' : 'none'};
    animation: ${props => props.isError ? 'blink-border 1s step-end infinite' : 'none'};
`;
const StyledMDBCardHeader = styled(MDBCardHeader)`
    height: 38px;
    padding: 0; /* MDBCardHeader의 내부 여백 제거 */
    margin: 0; /* MDBCardHeader의 외부 여백 제거 */
    position: relative; /* 자식 요소의 absolute 배치 지원 */

    /* 내부요소 세로 가운데정렬 */
    display: flex;
    align-items: center;
`;

const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end; /* 이미지를 우측 끝으로 배치 */
    width: 100%;
    position: relative; /* Model의 중앙 배치 지원을 위해 relative 설정 */
    margin: 0; /* HeaderTitle 내부 여백 제거 */
    padding: 0; /* HeaderTitle 내부 여백 제거 */
`;

const Nickname = styled.div`
    font-weight: bold;
    font-size: 1.4rem;
    text-align: center;
    flex: 1;
    //border: 1px solid red;
`;

const Model = styled.div`
    font-weight: bold;
    font-size: 1.4rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* 부모 기준 가로 중앙 정렬 */
`;

const ImgWrapper = styled.div`
    margin-right: 10px;
`;

const Serial = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    flex: 1;
`;
const StyledMDBCardBody = styled(MDBCardBody)`
    height: 200px;
    padding: 0; /* MDBCardHeader의 내부 여백 제거 */
    margin: 0; /* MDBCardHeader의 외부 여백 제거 */
`;

const TraitsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
`;

const DeviceCardCommon = (props) => {
    const { t, i18n } = useTranslation(["trans"]);

    const { isError, device, children, deviceButtonClick } = props;
    const [modelCodeString, setModelCodeString] = useState("");
    const [connected, setConnected] = useState(false);

    //useEffect(() => console.log("device", device), [device]);

    useEffect(() => {
        if (device) {
            if (device.hasOwnProperty("traits")) {
                const tr = findTrait(device?.traits, "connected");
                setConnected(tr?.currentValue);
            }
        }
    }, [device]);

    useEffect(() => {
        // 서버에서 내려오는 데이터중에 쓸만한 것이 있을때.
        //setModelCodeString(device?.modelCode);

        // 쓸만한것이 없고 새롭게 정의할때
        let code = device?.modelCode;
        switch (device?.modelCode) {
            case "r9iot-ssphs":
                code = "AS512-502";
                break;
            case "r9iot-thermometer":
                code = "AS512-501";
                break;
            case "r9iot-heatcable":
                code = "AS512-503"; // 아닐 수 있음.
                break;
            default:
                code = device?.modelCode;
                break;
        }
        setModelCodeString(code);
    }, [device?.modelCode]);

    return (
        <StyledMDBCard className="border" isError={isError}>
            {/** 메인아이콘 */}
            {/** 
             * 아이콘 없는 디자인 요청 - 202406 미팅 
            <div className="text-center my-3">
                <i className="fas fa-leaf icon-card-logo text-success"></i>
            </div>
            */}
            {/** 카드 헤더 */}
            <StyledMDBCardHeader>
                <HeaderTitle>
                    <Nickname>{device?.nickname}</Nickname>
                </HeaderTitle>
            </StyledMDBCardHeader>

            <StyledMDBCardHeader>
                <HeaderTitle>
                    <Model> {modelCodeString}</Model>
                    {/** connected img : 같은 크기의 이미지 파일만 assets 폴더 내부에서 변경하면 바로적용 */}
                    <ImgWrapper>
                        <img src={connected ? imgConnected : imgDisconnected} alt=""></img>
                    </ImgWrapper>
                </HeaderTitle>
            </StyledMDBCardHeader>

            {/** 카드 바디 */}
            <StyledMDBCardBody>
                {/** traits */}
                <TraitsContainer>{children}</TraitsContainer>
            </StyledMDBCardBody>

            {/** 하단 버튼 그룹 */}
            <MDBCardFooter
                className="d-flex justify-content-center align-items-center p-0"
                style={{ borderTop: "none", marginBottom: "1rem" }}
            >
                <NavLink to="/dashboard/alarms">
                    <StyledDeviceFooterButton onClick={deviceButtonClick}>{t("알람")}</StyledDeviceFooterButton>
                </NavLink>
                <NavLink to="/dashboard/reports">
                    <StyledDeviceFooterButton onClick={deviceButtonClick}>
                        {t("LOCAD0012", "보고서")}
                    </StyledDeviceFooterButton>
                </NavLink>
                <NavLink to="/dashboard/deviceSetting">
                    <StyledDeviceFooterButton onClick={deviceButtonClick}>
                        {t("LOCAD0013", "설정")}
                    </StyledDeviceFooterButton>
                </NavLink>
                {/**
                <StyledButton onClick={toggleSettingsModalHandler}>{t("LOCAD0013", "설정")}</StyledButton>
                 */}
            </MDBCardFooter>
        </StyledMDBCard>
    );
};
export default DeviceCardCommon;