const addDeviceTitle = '장치 추가';

const addDeviceDisplaySetup = [
    {
        title: addDeviceTitle,
        label: '장치 모델 선택',
        type: 'select',
        activePrev: false,
        btnText: '다음', /* 'NEXT' */
    },
    {
        title: addDeviceTitle + ' - 허브', /* title: addDeviceTitle + ' - GW pairing', */
        label: '허브(게이트웨이) ID',
        type: 'email',
        deviceModel: 'gw',
        activePrev: true,
        placeholder: '1234@phs.com',
        btnText: 'SEARCH',
        btnTextChanged: 'REFRESH',
        search: true,
    },
    {
        title: addDeviceTitle + '- Wifi Connect',
        label: 'Device ID',
        deviceModel: 'wifi',
        type: 'email',
        activePrev: true,
        placeholder: '1234@phs.com',
        btnText: 'Connect',
    },
];

const addDeviceListHeader = [
    '#',
    'Model',
    'Serial',
    'IP',
    'HW',
    'Firmware',
    '',
];

export { addDeviceDisplaySetup, addDeviceListHeader };
