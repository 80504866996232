import { MDBSwitch } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface Props {
    label: string;
    name: string;
    checked?: Boolean;
    id: string;
    children?: any;
    inline?: boolean;
    className?: string;
    toggleSwitchHandler?: (id: string) => void;
}

const SwitchSection = (props: Props) => {
    const {
        label,
        checked,
        id,
        name,
        toggleSwitchHandler,
    }: Props = props;

    const { t, i18n } = useTranslation(['trans']);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div>{t(label)}</div>
            <div>
                <MDBSwitch
                    id={id}
                    inline={true}
                    label={""}
                    name={name}
                    onChange={() => toggleSwitchHandler(name)}
                    // defaultChecked={checked ? true : false}
                    checked={checked ? true : false}
                />
            </div>
        </div>
    );
};

export default SwitchSection;
