/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

// Merge a `source` object to a `target` recursively
export const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (let key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);

  return target
};

export const formatMoney = (price) => {
  // return n.toFixed().replace(/\d(?=(\d{3})+\.)/g, '$&,');

  let n = 0;
  let x = 3;

  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return price.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');

};

export const priceFormat = new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' });


export const sortbyNickname = (array, desc = false) => {
  array.sort((a, b) => {
    if (desc) {
      return (a.nickname > b.nickname) ? -1 : (a.nickname < b.nickname) ? 1 : 0;
    } else {
      return (a.nickname < b.nickname) ? -1 : (a.nickname > b.nickname) ? 1 : 0;
    }
  });
};

export const propName = (prop, value) => {
  for(let i in prop) {
    if (prop[i] == value){
      return i;
    }
  }
  return false;
};

export const findTrait = (traits, name) => {
  for(let uuid in traits) {
    let tr = traits[uuid];
    if (tr.name === name) {
      return {
        uuid,
        ...tr
      };
    }
  }
  return undefined;
};

export const queryParams = (params) => {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
};

export function convertUnit2String(unit) {
  if (unit) {
    return unit === 'C' ? '°C' : unit;
  }
  return '';
}

/*
// usage let prop = propName(() => {obj.name}); // myProperty
 */
// export const propName = (f) => /\.([^\.;]+);?\s*\}$/.exec(f.toString())[1];

