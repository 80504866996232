import { DeviceParams } from 'api/device';
import { PaymentParams } from 'api/payment';
import ProfileAlarmSettingContainer from 'containers/profile/profileAlarmSettingContainer';
import ProfileChangePasswordContainer from 'containers/profile/profileChangePasswordContainer';
import ProfileDetailsContainer from 'containers/profile/profileDetailsContainer';
import dayjs from 'dayjs';
import { CheckedUser, UserData } from 'features/user-slice';
import {
    MDBBadge,
    MDBContainer,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane,
} from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface UserProfileProps {
    checkedUser: CheckedUser;
    userData: UserData;
    language: string;
    userPayment: PaymentParams;
    devices: DeviceParams[];
}

type backgroundColor =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'white'
    | 'transparent';

const ratingColorType = {
    free: 'secondary',
    basic: 'primary',
    premium: 'danger',
};

// 전체 프로필 박스 스타일
const StyledProfileHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; /* 각 섹션 사이에 공간 확보 */
  background-color: #f4f4f4; /* 밝은 배경색 */
  border: 1px solid #dcdcdc; /* 옅은 테두리 */
  border-radius: 1px; /* 둥근 모서리 */
  padding: 1rem; /* 내용물과의 간격 */
  color: #000; /* 텍스트를 어두운 색으로 */
  width: 100%; /* 전체 너비 사용 */
`;

// 무료 계정 배지와 업그레이드 링크 스타일
const ProfileHeaderBadge = styled.div`
  display: flex;
  //flex-direction: column;
  align-items: center;

  .badge {
    background-color: #bbb; /* 배지 배경은 밝은 회색 */
    color: #fff; /* 텍스트는 어두운 색 */
    padding: 0.2rem 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5px; /* 배지를 둥글게 */
    text-decoration: none;
    font-size: 1rem;
  }

  .upgrade-link {
    background-color: #333; /* 어두운 배경 */
    color: #fff; /* 흰색 텍스트 */
    padding: 0.2rem 0.5rem;
    text-decoration: none;
    border-radius: 5px; /* 링크 배지도 둥글게 */
    font-size: 1rem;
  }
`;

// 사용자 정보와 기기 정보 스타일
const ProfileUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-left: 1rem; /* 좌측 여백 추가 */
  color: #333; /* 약간 어두운 텍스트 색 */
`;



// TabItem에 사용할 Props 타입 정의
interface TabItemProps {
  active: boolean;
}
// 전체 탭 컨테이너 스타일
const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #2c2c2c; /* 다크 배경 */
  padding: 1rem 2rem; /* 내부 여백 */
  margin: 2rem 0 2rem 0;
  color: #fff; /* 흰색 텍스트 */
  border-bottom: 1px solid #444; /* 하단 테두리 */
`;

// 각 탭 항목 스타일
const TabItem = styled.div<TabItemProps>`
  margin-right: 2rem; /* 각 탭 항목 사이의 간격 */
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')}; /* 활성 탭일 경우 볼드 */
  color: ${({ active }) => (active ? '#ffffff' : '#aaaaaa')}; /* 활성 탭일 경우 흰색, 그렇지 않으면 밝은 회색 */
  position: relative;

  &:after {
    content: "";
    display: ${({ active }) => (active ? 'block' : 'none')}; /* 활성 탭일 경우 밑줄 표시 */
    position: absolute;
    bottom: -17px; /* 텍스트 아래에 밑줄 위치 */
     left: 50%; /* 왼쪽 기준점을 가운데로 */
    transform: translateX(-50%); /* 왼쪽에서 절반만큼 이동하여 중앙 정렬 */
    width: 150%;
    height: 2px;
    background-color: #ffffff; /* 밑줄은 흰색 */
  }
`;



const UserProfileDisplay = ({ checkedUser, userData, language, userPayment, devices }: UserProfileProps) => {
    /* from  i18n */
    const { t, i18n } = useTranslation(["trans"]);

    const [basicActive, setBasicActive] = useState<string>("profile");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const arrayedPathname = location?.pathname.split("/");
        const lastPathname = arrayedPathname[arrayedPathname.length - 1];

        lastPathname === "billing" && setBasicActive("billing");
    }, [basicActive, location]);

    const handleBasicClick = (value: string) => {
        //event.preventDefault();

        if (value === basicActive) {
            return;
        }

        setBasicActive(value);

        /*
        if (value === 'profile') {
            return navigate('/profile', { replace: false });
        }

        navigate(`${value}`, { replace: false });
        */
    };

    const badgeColor = (userData && (`${ratingColorType[userData.rating]}` as backgroundColor)) || "secondary";

    return (
        <MDBContainer fluid className="subpage-container py-3" style={{ width: "35%", margin: "0 auto" }}>
            <div className="label-header">
                <div className="d-flex align-items-center">
                    <div>
                    <h2 className="m-0">{/* <h2 className="m-3"> */}
                            {t("LOCAD0050", "프로필")}</h2>
                    </div>
                </div>
            </div>
            <div className="table-box" style={{ width: "650px" }}> {/* <div className="table-box"> */}
                <StyledProfileHeaderBox>
                    {/* 사용자 이메일 */}
                    <ProfileUserInfo>
                        <div>
                            <strong>{checkedUser?.username}</strong>
                        </div>
                    </ProfileUserInfo>

                    {/* 무료 배지와 업그레이드 버튼 */}
                    <ProfileHeaderBadge>
                        <span className="badge">{userData && userData.rating}</span>
                        {userData?.rating !== "premium" && (
                            <NavLink to="/profile/billing" className="upgrade-link">
                                Upgrade to Pro
                            </NavLink>
                        )}
                    </ProfileHeaderBadge>

                    {/* 사용자 전화번호와 기기 사용 정보 */}
                    <ProfileUserInfo>
                        <div>
                            <strong>{checkedUser?.phone_number}</strong>
                        </div>
                        <div>
                            <strong>{`${t("사용 기기 수")}: ${devices.length}`}</strong>
                        </div>
                    </ProfileUserInfo>
                </StyledProfileHeaderBox>

                <TabsContainer>
                    {/* 프로필 설정 탭 */}
                    <TabItem active={basicActive === "profile"} onClick={() => handleBasicClick("profile")}>
                        {t("LOCAD0051", "프로필 설정")}
                    </TabItem>

                    {/* 비밀번호 변경 탭 */}
                    <TabItem
                        active={basicActive === "change-password"}
                        onClick={() => handleBasicClick("change-password")}
                    >
                        {t("비밀번호 변경")}
                    </TabItem>

                    {/* 알람 설정 탭 */}
                    <TabItem active={basicActive === "alarm"} onClick={() => handleBasicClick("alarm")}>
                        {t("알람 설정")}
                    </TabItem>
                </TabsContainer>
                {/**
                <MDBTabs className="mb-3">
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick("profile")} active={basicActive === "profile"}>
                            {t("LOCAD0051", "프로필 설정")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink
                            onClick={() => handleBasicClick("change-password")}
                            active={basicActive === "change-password"}
                        >
                            {t("비밀번호 변경")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick("alarm")} active={basicActive === "alarm"}>
                            {t("알람 설정")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>
                 */}

                <MDBTabsContent
                    style={
                        {
                            //border: "1px solid red",
                        }
                    }
                >
                    <MDBTabsPane show={basicActive === "profile"}>
                        <ProfileDetailsContainer checkedUser={checkedUser} language={language} />
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === "change-password"}>
                        <ProfileChangePasswordContainer checkedUser={checkedUser} language={language} />
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === "alarm"}>
                        <ProfileAlarmSettingContainer checkedUser={checkedUser} language={language} />
                    </MDBTabsPane>
                </MDBTabsContent>
            </div>
        </MDBContainer>
    );
};

export default UserProfileDisplay;
