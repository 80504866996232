import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DateProps {
    string: string;
    date: any;
}

export interface SearchState {
    startDate: DateProps;
    endDate: DateProps;
}

const initialState: SearchState = {
    // 검색할 시작 날짜
    startDate: null,
    // 검색할 종료 날짜
    endDate: null,
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateStartDate(state, action: PayloadAction<DateProps>) {
            state.startDate = action.payload;
        },

        updateEndDate(state, action: PayloadAction<DateProps>) {
            state.endDate = action.payload;
        },

        resetSearchState(state) {
            return initialState;
        },
    },
});

export const { updateStartDate, updateEndDate, resetSearchState } =
    searchSlice.actions;
export default searchSlice.reducer;
