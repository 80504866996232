import PhsStyledButton from "./PhsStyledButton";

const StyledDeviceFooterButton = ({ children, onClick }) => {
    return (
        <PhsStyledButton onClick={onClick} width={"76px"}>
            {children}
        </PhsStyledButton>
    );
};

export default StyledDeviceFooterButton;
