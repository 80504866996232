import LabeledSwitchSection from 'components/fragment/labeledSwitchSection';
import { CheckedUser, UserData } from 'features/user-slice';
import {
    MDBAlert,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

export interface ProfileAlarmSettingProps {
    checkedUser: CheckedUser;
    showError: boolean;
    errorMessage: string;
    closeError: () => void;
    userData: UserData;
    toggleAlarmSetting: (event, label: string) => void;
    language: string;
}

const ProfileAlarmSettingDisplay = ({
    checkedUser,
    showError,
    errorMessage,
    userData,
    closeError,
    toggleAlarmSetting,
    language,
}: ProfileAlarmSettingProps) => {
    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    return (
        <>
            <MDBAlert
                color="warning"
                position="top-center"
                className="w-100 d-flex justify-content-between"
                offset={0}
                // delay={2000}
                appendToBody
                show={showError}
            >
                <div>{errorMessage}</div>
                <MDBBtn className="btn-close" noRipple outline color="none" onClick={closeError} />
            </MDBAlert>
            <div style={{
                margin: "2rem 3rem 2rem 3rem"
            }}>
                <LabeledSwitchSection
                    id="pushNotification"
                    name="pushNotification"
                    label={t("앱 푸쉬")}
                    toggleSwitchHandler={(event) => toggleAlarmSetting(event, "App Push")}
                    checked={!!userData?.pushNotification}
                />
                <div className="d-flex">
                    <LabeledSwitchSection
                        id="emailNotification"
                        name="emailNotification"
                        label={t("이메일")}
                        checked={!!userData?.emailNotification}
                        toggleSwitchHandler={(event) => toggleAlarmSetting(event, "Email")}
                    />
                    <div className="w-50" style={{ fontSize: "14px" }}>
                        <div className="d-flex justify-content-start text-grayish">
                            <span>{checkedUser?.username}</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex"
                >
                    <LabeledSwitchSection
                        id="smsNotification"
                        name="smsNotification"
                        label={t("SMS")}
                        checked={!!userData?.smsNotification}
                        toggleSwitchHandler={(event) => toggleAlarmSetting(event, "SMS")}
                    />
                    <div className="w-50" style={{ fontSize: "14px" }}>
                        <div className="d-flex justify-content-start text-grayish">
                            <span>{checkedUser?.phone_number}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileAlarmSettingDisplay;
