/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';
import { GooglePlaceInfo } from 'containers/updateLocationContainer';
import config from '../config';

const apiName = 'RaonixCloud';

/* 
{
    "4696761f-b044-40b8-967e-6317ab845f13": {
        "nickname": "장소 테스트",
        "placeId": "",
        "address": "",
        "latitude": null,
        "longitude": null,
        "application": ""
    }
}

key - uuid
*/

export interface PlaceParams {
    uuid?: string;
    nickname: string;
    placeId: string;
    address: string;
    latitude: string;
    longitude: string;
    application: string;
    isGeocoding?: boolean;
}

export const initialPlaceParams: PlaceParams = {
    uuid: '',
    nickname: '',
    address: '',
    placeId: '',
    longitude: null,
    application: '',
    latitude: null,
};

export function queryPlacesPost(
    principalId,
    place: PlaceParams | GooglePlaceInfo
) {
    let path = `/places`;

    return API.post(apiName, path, {
        headers: {
            principalId,
        },
        body: place,
        response: true,
    });
}

export function queryGetPlaces(principalId) {
    let path = `/places`;

    return API.get(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
    });
}

/**
 * placeUuid를 입력하여 uuid에 해당하는 place의 detail data를 반환
 * @param principalId
 * @param placeUuid
 * @returns
 */
export function queryGetPlaceOne(principalId, placeUuid) {
    let path = `/places/${placeUuid}`;

    return API.get(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
    });
}

export function queryPutPlace(
    principalId,
    uuid,
    params: PlaceParams | GooglePlaceInfo
) {
    let path = `/places/${uuid}`;
    return API.put(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
        body: params,
    });
}

export function queryDeletePlace(principalId, uuid) {
    let path = `/places/${uuid}`;
    return API.del(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
    });
}

/**
 * placeUuid를 parameter로 받아, place에 귀속되어 있는 device uuid를 반환
 * @param principalId
 * @param uuid placeUuid
 * @returns
 */
export function queryGetPlaceDevices(principalId: string, uuid: string) {
    let path = `/places/${uuid}/devices`;

    return API.get(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
    });
}

/**
 * place에 저장돼 있는 device uuid 목록을 가져옴
 * @param userPrincipalId
 * @param locationId
 * @returns
 */
export async function getPlaceDevices(
    userPrincipalId: string,
    locationId: string
) {
    const result = await queryGetPlaceDevices(userPrincipalId, locationId);

    if (result) return result.data;
}
