export const DEVICE_TYPE_SSPHS = {
    DUST : 1,
    PH : 2,
    CO2 : 3,
    NH3 : 4,
    SOIL : 5,
    EXTERNAL : 6,

    DEFAULT: 0,
    NONE: 7,
};