import styled from 'styled-components';

interface ToggleContainerProps {
  styleType: 'style1' | 'style2';
}

const COLOR_DARK_GREY = "#202020";
const COLOR_LIGHT_GREY = "#999"; /* "#ddd"; */
const COLOR_NEMOAHN_GREEN = "#91a93d";

const ToggleContainer = styled.div<ToggleContainerProps>`
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 50px;
    height: 24px;
    border-radius: 38px;

    ${(props) =>
      props.styleType === 'style1'
        ? `
          background-color: ${COLOR_LIGHT_GREY};
          background-color: ${COLOR_DARK_GREY};
          border: 1px solid ${COLOR_LIGHT_GREY};
        `
        : `
          background-color: transparent;
          border: 2px solid ${COLOR_LIGHT_GREY};
        `}

    transition: 0.5s;
  }

  > .toggle-container-checked {
    ${(props) =>
      props.styleType === 'style1'
        ? `
          background-color: ${COLOR_DARK_GREY};
          border: 1px solid ${COLOR_NEMOAHN_GREEN}; /* border: 2px solid ${COLOR_NEMOAHN_GREEN}; */
        `
        : `
          background-color: ${COLOR_NEMOAHN_GREEN};
          border: 2px solid ${COLOR_LIGHT_GREY};
        `}
  }

  > .toggle-circle {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;

    ${(props) =>
      props.styleType === 'style1'
        ? `
          background-color: ${COLOR_LIGHT_GREY};
        `
        : `
          background-color: ${COLOR_LIGHT_GREY};
        `}

    transition: 0.5s;
  }

  > .toggle-circle-checked {
    left: 28px;

    ${(props) =>
      props.styleType === 'style1'
        ? `
          background-color: ${COLOR_NEMOAHN_GREEN};
        `
        : `
          /* 다른 스타일을 필요에 따라 추가 */
        `}
  }
`;

interface ToggleProps {
  isSelected: boolean;
  handleToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleProps> = ({ isSelected, handleToggle }) => {

    return (
        <ToggleContainer onClick={handleToggle} styleType="style1">
            <div className={`toggle-container ${isSelected ? "toggle-container-checked" : ""}`} />
            <div className={`toggle-circle ${isSelected ? "toggle-circle-checked" : ""}`} />
        </ToggleContainer>
    );
};

export default ToggleSwitch;
