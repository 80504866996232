import React, { useState, useRef, useEffect } from 'react';
import { MDBIcon, MDBInfiniteScroll } from 'mdb-react-ui-kit';
import { AlarmListParams } from 'api/record';
import { MenuItem } from '@mui/material';

interface InfiniteScrollProps {
    items?;
    numberOfRendered: number;
    scrollAmount: number;
    children?: any;
    itemClick?: (event?: any) => void;
}

const InfiniteScroll = (props: InfiniteScrollProps) => {
    const { items, numberOfRendered, scrollAmount, itemClick } = props;

    const infiniteRef = useRef<HTMLDivElement>(null);

    const [itemIndex, setItemIndex] = useState(numberOfRendered);
    const [rendered, setRendered] = useState(null);
    const [renderedIcons, setRenderedIcons] = useState([
        'Angry',
        'Dizzy',
        'Flushed',
        'Frown',
        'Grimace',
        'Grin',
    ]);

    const icons = [
        'Sad-Tear',
        'Meh-Blank',
        'Smile-Wink',
        'Tired',
        'Surprise',
        'Kiss-Beam',
        'Laugh-Squint',
    ];

    useEffect(() => {
        if (!items) return;

        const firstRendered = [...items].splice(0, numberOfRendered);

        setRendered(firstRendered);
    }, [items, numberOfRendered]);

    const handleScroll = () => {
        if (itemIndex > icons.length - 1) {
            return;
        }

        setRenderedIcons([...renderedIcons, icons[itemIndex]]);

        setItemIndex(itemIndex + 1);
    };

    const handleScroll2 = () => {
        if (itemIndex > items.length - 1) {
            return;
        }

        const nextItems = [...items].splice(itemIndex, scrollAmount);

        setRendered([...rendered, ...nextItems]);
        setItemIndex(itemIndex + scrollAmount);
    };

    return (
        <MDBInfiniteScroll
            infiniteScrollRef={infiniteRef}
            className='container list-group'
            style={{ maxHeight: '300px', overflowY: 'scroll' }}
            onInfiniteScroll={() => {
                handleScroll();
                handleScroll2();
            }}
        >
            {/* {renderedIcons.map((icon) => (
                <li
                    key={icon.toLocaleLowerCase()}
                    className='list-group-item d-flex align-items-center'
                >
                    <i
                        className={`far fa-${icon.toLowerCase()} fa-3x me-4`}
                    ></i>
                    {icon}
                </li>
            ))} */}
            {rendered?.map((item: AlarmListParams, index: number) => {
                return (
                    <MenuItem
                        key={item.date + index}
                        style={{ color: '#4f4f4f', fontSize: '14px' }}
                        onClick={() => itemClick(item)}
                    >
                        {/**
                        <MDBIcon className='me-3' fas icon='fas fa-bell' />
                         */}
                        {item.message}
                    </MenuItem>
                );
            })}
        </MDBInfiniteScroll>
    );
};

export default InfiniteScroll;
