import BillingCard from 'components/fragment/billingCard';
import PaymentPlanList from 'components/fragment/paymentPlanList';
import { localized_language } from 'constants/localizer';
import { UserData } from 'features/user-slice';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface ProfilePaymentDisplay {
    toggleModalHandler: () => void;
    paymentButtonClickHandler: (event) => void;
    userData: UserData;
    language: string;
}

const ProfilePaymentDisplay = ({
    toggleModalHandler,
    userData,
    paymentButtonClickHandler,
    language,
}) => {
    /* from i18n */
    const { t, i18n } = useTranslation(['trans']);

    const planList = [
        {
            rating: 'free',
            themeColor: 'secondary',
            title: 'Free plan',
            planList: localized_language[`${language}_free_plan_list`],
            btnText: localized_language[`${language}_available_free`],
            price: 0,
        },
        {
            rating: 'basic',
            themeColor: 'primary',
            title: 'Basic plan',
            planList: localized_language[`${language}_basic_plan_list`],
            btnText: `${localized_language[
                `kor_basic_price`
            ].toLocaleString()} ${localized_language[`kor_price_unit`]} / ${
                localized_language[`${language}_month`]
            }`,
            price: 9900,
            payment: true,
        },
        {
            rating: 'premium',
            themeColor: 'danger',
            title: 'Premium plan',
            planList: localized_language[`${language}_premium_plan_list`],
            border: true,
            btnText: `${localized_language[
                `kor_premium_price`
            ].toLocaleString()} ${localized_language[`kor_price_unit`]} / ${
                localized_language[`${language}_month`]
            }`,
            price: 19800,
            payment: true,
        },
    ];

    return (
        <MDBCard className='mt-4 profile-under-payment-card'>
            <MDBCardBody>
                <MDBCardHeader className='p-1'>
                    <MDBCardTitle className='w-100 m-0'>
                        <strong>{t('LOCAD0006', "결제")}</strong>
                    </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody className='paymentCard-body'>
                    {planList.map((plan, key) => (
                        <BillingCard
                            key={plan.rating}
                            className={`border ${
                                plan.border &&
                                `border-2 border-${plan.themeColor}`
                            }`}
                            title={plan.title}
                            headerColor={plan.themeColor}
                        >
                            <PaymentPlanList
                                planList={plan.planList}
                                checkIconColor={plan.themeColor}
                                buttonText={plan.btnText}
                                rating={plan.rating}
                                planItem={plan}
                                buttonClickHandler={(event) => {
                                    if (!plan.payment) return;
                                    toggleModalHandler();
                                    paymentButtonClickHandler(plan);
                                }}
                                // @ts-ignore
                                buttonColor={plan.themeColor}
                            ></PaymentPlanList>
                        </BillingCard>
                    ))}
                </MDBCardBody>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ProfilePaymentDisplay;
