// @ts-nocheck
/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    zoomPlugin
);

const config = {
    plugins: {
        htmlLegend: {
            containerID: "legend-container",
        },
        legend: {
            display: false, // 범례 표시 여부
            position: "top", // 범례 위치: 'top', 'bottom', 'left', 'right'
            labels: {
                color: "white", // 범례 텍스트 색상
                font: {
                    size: 12, // 폰트 크기
                },
                usePointStyle: true, // 포인트 스타일 사용
                boxWidth: 20, // 범례 박스 너비
                padding: 15, // 범례 간격
            },
            onClick: (e, legendItem, legend) => {
                /*
                // 범례 클릭 시 사용자 정의 동작 수행 가능
                const index = legendItem.datasetIndex;
                const chart = legend.chart;
                const meta = chart.getDatasetMeta(index);

                // 클릭된 데이터셋 토글
                meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
                chart.update();
                */
            },
        },
        title: {
            text: "온습도계 통계",
            display: false,
        },
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true,
                },
                drag: {
                    enabled: true,
                },
                mode: "x",
            },
        },
    },
    animation: {
        duration: 0, // 애니메이션 비활성화
    },
    //spanGaps: true,
    scales: {
        date: {
            position: "bottom",
            title: {
                display: true,
                text: "Date",
            },
        },
        temperature: {
            title: {
                display: true,
                text: "Temperature",
            },
            position: "left",
            grid: {
                borderDash: [5, 5],
                drawBorder: false,
            },
            suggestedMin: 0,
            suggestedMax: 100,
            beginAtZero: true,
        },
        humidity: {
            position: "right",
            title: {
                display: true,
                text: "Humidity",
            },
            grid: {
                borderDash: [5, 5],
                drawBorder: false,
            },
            suggestedMin: 0,
            suggestedMax: 100,
            beginAtZero: true,
        },
    },
    elements: {
        point: {
            pointStyle: "line",
            radius: 3,
            //tension: 0.2 // line 차트에서 사용됨.
        },
    },
    maintainAspectRatio: false, // 가로 세로 비율 유지 비활성화
};


const ThermometerChart = (props) => {
    const { data, type } = props;

    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector("ul");

        if (!listContainer) {
            listContainer = document.createElement("ul");
            listContainer.style.display = "flex";
            listContainer.style.flexDirection = "row";
            listContainer.style.margin = 0;
            listContainer.style.padding = 0;

            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    // 기존 체크박스 대신 슬라이딩 스위치를 사용합니다.
const createSwitchElement = (item, chart, updateChart) => {
    const label = document.createElement("label");
    label.className = "switch";

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = !item.hidden;

    // 체크박스 변경 시 동작 설정
    checkbox.onchange = () => {
        const { type } = chart.config;
        if (type === "pie" || type === "doughnut") {
            chart.toggleDataVisibility(item.index);
        } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        updateChart();
    };

    const slider = document.createElement("span");
    slider.className = "slider";

    label.appendChild(checkbox);
    label.appendChild(slider);

    return label;
};

    const htmlLegendPlugin = {
        id: "htmlLegend",
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);

            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach((item) => {
                const li = document.createElement("li");
                li.style.alignItems = "center";
                //li.style.cursor = "pointer";
                li.style.display = "flex";
                li.style.flexDirection = "row";
                li.style.marginLeft = "10px";

                // 슬라이딩 스위치 생성
                const switchElement = createSwitchElement(item, chart, () => chart.update());

                /*
                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === "pie" || type === "doughnut") {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };
                */

                // Color box
                const boxSpan = document.createElement("span");
                boxSpan.style.background = item.fillStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + "px";
                boxSpan.style.display = "inline-block";
                boxSpan.style.flexShrink = 0;
                boxSpan.style.height = "20px";
                boxSpan.style.marginRight = "10px";
                boxSpan.style.width = "20px";

                // Text
                const textContainer = document.createElement("p");
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                //textContainer.style.textDecoration = item.hidden ? "line-through" : "";

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                //li.appendChild(boxSpan);
                li.appendChild(textContainer);
                li.appendChild(switchElement);
                ul.appendChild(li);
            });
        },
    };
    // shallow copy 하면 "Cannot read property 'getBasePixel' of undefined..." 에라 발생
    // deep copy를 해야 함.
    let tempConfig = _.cloneDeep(config);

    // @ts-ignore
    return (
        <>
            <div
                id="legend-container"
                style={{
                    display: "flex",
                    justifyContent: "center", // 가로 중앙 정렬
                    alignItems: "center", // 세로 중앙 정렬
                    //border: "1px solid red", // 디버깅을 위한 테두리
                    margin: "0 auto",
                    width: "fit-content", // 콘텐츠 크기에 맞게 부모 요소 너비 설정
                    padding: "10px", // 여백 추가
                }}
            ></div>
            <div style={{height: "400px"}}>

            <Chart type="bar" data={data} options={tempConfig} plugins={[htmlLegendPlugin]} />
            </div>
        </>
    );

};

export default ThermometerChart;
