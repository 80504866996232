/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';
import { CheckedUser } from 'features/user-slice';
import config from '../config';

const apiName = 'RaonixCloud';

/**
 *   device
 */

export interface Attributes {
    device_type?: number;
}

export interface DeviceInfo {
    serial: string;
    manufacturer?: string;
    model?: string;
    hwVersion?: string;
    swVersion?: string;
}

export interface Traits {
    label?: string;
    type?: string;
    name?: string;
    nickname?: string;
    record?: number | string;
    max?: number;
    min?: number;
    unit?: string;
    currentValue?: string | number;
}

export interface TraitsResult {
    [prop: string]: Traits;
}

export interface DeviceParams {
    uuid: string;
    id: string;
    type: string;
    name: string;
    nickname: string;
    record: true;
    modelCode: string;
    deviceInfo?: DeviceInfo;
    attributes?: Attributes;
    traits: { [prop: string]: Traits };
    createdAt: string;
    updatedAt: string;
    subscribed?: boolean;
    serial?: string;
    placeUuid?: string;
    placeNickname?: string;
}

export interface DeviceToPlaceParams {
    placeUuid: string;
}

// subscribe와 상관 없이 모든 device 정보를 가져옴
export function queryGetDevice(principalId, uuid) {
    if(principalId === undefined) return;
    let path = `/devices/${uuid}`;
    const response =  API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        response: true,
    });
    return response;
}

export function queryPutDeviceSetting(principalId, uuid, params) {
    if(principalId === undefined) return;
    let path = `/devices/${uuid}`;
    return API.put(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
        body: params,
    });
}

export function queryDevices(principalId) {
    let path = '/devices';
    if(principalId === undefined) return;
    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
    });
}

export function queryDeleteDevice(principalId, deviceUuid) {
    if(principalId === undefined) return;
    let path = `/devices/${deviceUuid}`;
    return API.del(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
    });
}

/**
 *
 * @param principalId
 * @param deviceUuid
 * @param userId
 * @returns
 */
export function queryDeviceSubscribe(principalId, deviceUuid, userId = '') {
    if(principalId === undefined) return;
    let path = `/devices/${deviceUuid}/users`;

    return API.post(apiName, path, {
        headers: {
            principalId,
        },
        body: {
            userId: userId,
        },
        response: true,
    });
}

/**
 *
 * @param principalId
 * @param deviceUuid
 * @returns
 */
export function queryDeviceUnsubscribe(principalId, deviceUuid) {
    if(principalId === undefined) return;
    let path = `/devices/${deviceUuid}/users`;
    return API.del(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
    });
}

/**
 *   trait
 */
export function queryDeviceTraits(principalId, deviceUuid) {
    if(principalId === undefined) return;
    let path = `/devices/${deviceUuid}/traits`;
    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        response: true,
    });
}

export function queryDeviceControl(principalId, deviceUuid, settings) {
    if(principalId === undefined) return;
    let path = `/devices/${deviceUuid}/traits`;

    return API.put(apiName, path, {
        headers: {
            principalId,
        },
        body: settings,
    })
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.error(`error: ${JSON.stringify(error)}`);
            return error;
        });
}

/**
 *   Place
 */
export function queryGetPlace(principalId, uuid) {
    if(principalId === undefined) return;
    let path = `/devices/${uuid}/place`;
    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        response: true,
    });
}

export function queryPutPlace(principalId, uuid, params: DeviceToPlaceParams) {
    if(principalId === undefined) return;
    let path = `/devices/${uuid}/place`;
    return API.put(apiName, path, {
        headers: {
            principalId,
        },
        response: true,
        body: params,
    });
}

export async function getDeviceDetailList(
    principalId: string,
    deviceList: DeviceToPlaceParams[]
) {
    if (principalId === undefined) return;

    const deviceDetailList = deviceList.map(async (info) => {
        return queryGetDevice(principalId, info);
    });

    const newDeviceDetailList: DeviceParams[] = [];

    const deviceDetailResult = await Promise.all(deviceDetailList).catch(
        (error) => {
            alert('Fail to fetch device info list');
            console.info(error);
        }
    );

    if (!deviceDetailResult) return;

    deviceDetailResult.forEach((response) => {
        newDeviceDetailList.push(response.data);
    });

    return (newDeviceDetailList as DeviceParams[]) || null;
}

export async function getDeviceDetailTraits(
    principalId: string,
    deviceList: DeviceToPlaceParams[]
) {
    if (principalId === undefined) return;

    const promiseList = deviceList.map(async (deviceUuid) => {
        const response = await queryDeviceTraits(principalId, deviceUuid);
        return { uuid: deviceUuid, ...response.data };
    });

    const result = await Promise.all(promiseList).catch((error) =>
        console.info('Fail to fetch current location device traits')
    );

    return (result as TraitsResult[]) || null;
}
