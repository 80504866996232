//@ts-ignore
import { ReactNode } from 'react';
import { CSVLink } from 'react-csv';

interface CsvDownloadProps {
    className: string;
    btnChildren: ReactNode;
    csvDownloadClickHandler: (event?) => boolean;
    body: any;
    csvFileName?: string;
    type: 'alarm' | 'report';
}

const headerType = {
    alarm: [
        { label: 'Date', key: 'date' },
        { label: 'Checked', key: 'checked' },
        { label: 'Message', key: 'message' },
    ],
    report: [
        { label: 'Date', key: 'date' },
        { label: 'Temperature Date', key: 'checked' },
        { label: 'Message', key: 'message' },
    ],
};

const CsvDownload = (props: CsvDownloadProps) => {
    const {
        className,
        btnChildren,
        csvDownloadClickHandler,
        body,
        csvFileName,
        type,
    } = props;

    return (
        <CSVLink
            onClick={csvDownloadClickHandler}
            className={className}
            filename={csvFileName}
            data={body || []}
            headers={headerType[type]}
        >
            {btnChildren || 'Download me'}
        </CSVLink>
    );
};

export default CsvDownload;
