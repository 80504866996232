import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user-slice';
import displayReducer from 'features/display-slice';
import searchReducer from 'features/search-slice';
import signupSlice from 'features/signup-slice';
import paymentSlice from 'features/payment-slice';
import languageSlice from 'features/language-slice';

export const store = configureStore({
    reducer: {
        authState: userReducer,
        display: displayReducer,
        search: searchReducer,
        signup: signupSlice,
        payment: paymentSlice,
        language: languageSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['search/updateEndDate'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['payload.date'],
                // Ignore these paths in the state
                ignoredPaths: ['search.endDate.date', 'search.startDate.date'],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
