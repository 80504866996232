import { queryWriteReport } from "api/report";
import { useAppSelector } from "app/hooks";
import PreviewPdfDisplay from "components/display/previewPdfDisplay";
import { useEffect, useState } from "react";

const PreviewPdfContainer = (props) => {
    const { showModal, setShowModal, preview, toggleModalHandler } = props;

    /* from redux */
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState(null);

    async function writeReportPdf(principalId: string, reportUuid: string, writer: string) {
        return (
            (await queryWriteReport(principalId, reportUuid, writer, {
                type: "custom",
                title: preview.title,
                start: preview.startDate,
                end: preview.endDate,
                step: 1,
            })
                .then((response) => {
                    console.log("response", response.data);
                    return response.data
                })
                .catch((err) => {
                    console.error(err);
                })) || null
        );
    }

    useEffect(() => {
        async function run() {
            const reportResult = await writeReportPdf(checkedUser.principalId, preview.uuid, preview.writer);

            setFile(reportResult);
        }
        //console.log("checkedUser", checkedUser);
        //console.log("preview", preview);
        //console.log("showModal", showModal);

        checkedUser && showModal && preview && run();
    }, [checkedUser, preview, showModal]);

    return (
        <PreviewPdfDisplay
            showModal={showModal}
            setShowModal={setShowModal}
            modalTitle={"Report Viewer"}
            toggleModalHandler={toggleModalHandler}
            file={file}
        />
    );
};

export default PreviewPdfContainer;
function setState<T>(arg0: boolean): [any, any] {
    throw new Error("Function not implemented.");
}
