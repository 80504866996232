/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';

const apiName = 'RaonixCloud';

export interface UserParam {
    uuid?: string;
    id?: string;
    type?: string;
    rating?: string;
    timeZone?: string;
    emailNotification?: number;
    pushNotification?: number;
    smsNotification?: number;
    smsCount?: number;
    smsMax?: number;
    smsCharge?: number;
    createdAt?: string;
    updatedAt?: string;
    aud?: any;
}

/* {"uuid": "8f3c369d-5a8a-4c9c-879a-a1bbf7216c4a",
    "id": "jaso26@cloversw.com",
    "type": "user",
    "rating": "free",
    "timeZone": "Asia/Seoul",
    "emailNotification": 0,
    "pushNotification": 0,
    "smsNotification": 0,
    "smsCount": 0,
    "smsMax": 0,
    "smsCharge": 0,
    "createdAt": "2022-07-18T05:07:21.000Z",
    "updatedAt": "2022-07-18T05:07:21.000Z",
    "aud": null} */

export function queryUserGet(principalId, payment = false) {
    let path = `/users/${principalId}`;

    return API.get(apiName, path, {
        queryStringParameters: {
            payment,
        },
        headers: {
            principalId,
        },
        response: true,
    });
}

/**
 * @param {string} principalId
 * param
    "uuid": "8f3c369d-5a8a-4c9c-879a-a1bbf7216c4a",
    "id": "jaso26@cloversw.com",
    "type": "user",
    "rating": "free",
    "timeZone": "Asia/Seoul",
    "emailNotification": 0,
    "pushNotification": 0,
    "smsNotification": 0,
    "smsCount": 0,
    "smsMax": 0,
    "smsCharge": 0,
    "createdAt": "2022-07-18T05:07:21.000Z",
    "updatedAt": "2022-07-18T05:07:21.000Z",
    "aud": null
} 
 */
export function queryUserPost(principalId: string, params: UserParam) {
    let path = `/users`;
    return API.post(apiName, path, {
        headers: {
            principalId,
        },
        body: params,
        response: true,
    });
}

export function queryUserPut(principalId: string, params: UserParam) {
    let path = `/users/${principalId}`;
    return API.put(apiName, path, {
        headers: {
            principalId,
        },
        body: params,
        response: true,
    });
}

export function queryNotificationEmailGet(principalId: string) {
    let path = `/users/notification/email`;
    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        response: true,
    });
}

export function queryNotificationEmailPost(principalId, emails) {
    let path = `/users/notification/email`;
    return API.post(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        body: [...emails],
        response: true,
    });
}

export function queryNotificationEmailDelete(principalId, emails) {
    let path = `/users/notification/email`;
    return API.del(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        body: [...emails],
        response: true,
    });
}

/**
 * 현재 alarm을 받기위한 핸드폰 번호를 return 받음
 * @param principalId
 * @returns [+01000000000...]
 */
export function queryNotificationSmsGet(principalId: string) {
    let path = `/users/notification/sms`;
    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        response: true,
    });
}

export function queryNotificationSmsPost(
    principalId: string,
    phones: string[]
) {
    let path = `/users/notification/sms`;
    return API.post(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        body: [...phones],
        response: true,
    });
}

export function queryNotificationSmsDelete(
    principalId: string,
    phones: string[]
) {
    let path = `/users/notification/sms`;
    return API.del(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        body: [...phones],
        response: true,
    });
}
