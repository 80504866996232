import styled from '@emotion/styled';
import { Slider } from '@mui/material';
import { AlarmCondition } from 'api/alarm';
import { Traits } from 'api/device';
import { useAppSelector } from 'app/hooks';
import { SYMBOL } from 'constants/common';
import { DEVICE_TYPE_SSPHS } from 'constants/deviceTypeConst';
import { useEffect, useState } from 'react';

function valueText(value: number, index) {
    return `${value}°C`;
}

interface MultiRangeSectionProps {
    trait: Traits;
    type: string;
    label: string;
    name?: string;
    slide;
    alarmCondition: AlarmCondition;
    disabled: boolean;
}

const MultiRangeSectionContainer = styled.div`
    display: flex;
    width: 100%;
`;

const LabelContainer = styled.div`
    /* flex: 1; */
    width: 100px;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    color: white;
    padding: 0rem; /* 1rem; */
`;

const SliderContainer = styled.div`
    /* flex: 3; */
    width: 770px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
`;

const ChipContainer = styled.div`
    /* flex: 1; */
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0rem 1rem;
`;

const StyledChip = styled.div`
    background-color: #5c5c5c; /* 회색 배경색 */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 50px; /* 타원형으로 보이게 큰 라운드 */
    text-align: center;
    width: 90px; /* 고정된 너비 */
    height: 60px; /* 고정된 높이 */
    display: flex;
    flex-direction: column; /* 텍스트를 위아래로 배치 */
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.2;
`;

const ChipLabel = styled.div`
    font-size: 1rem;
    margin-bottom: 0.1rem;
`;

const ChipValue = styled.div`
    font-size: 1rem;
    font-weight: normal;
`;

const tempUnitValue = { unit: SYMBOL.TEMP, min: -30, max: 90 }; /* 온도 */
const humiUnitValue = { unit: SYMBOL.HUMI, min: 0, max: 100 }; /* 습도 */
const dustUnitValue = { unit: SYMBOL.DUST, min: 100, max: 1000 }; /* 미세먼지 */
const co2SensorUnitValue = { unit: SYMBOL.CO2, min: 400, max: 5000 }; /* CO2 */

export const temperatureRange = [tempUnitValue.min, -15, 0, 15, 30, 45, 60, 75, tempUnitValue.max].map((value) => ({
    value: value,
    label: value + SYMBOL.TEMP,
}));

export const humidityRange = [humiUnitValue.min, 20, 40, 60, 80, humiUnitValue.max].map((value) => ({
    value: value,
    label: value + SYMBOL.HUMI,
}));

const dustRange = [dustUnitValue.min, 250, 400, 600, 800, dustUnitValue.max].map((value) => ({
    value: value,
    label: value + SYMBOL.DUST,
}));

const co2Range = [co2SensorUnitValue.min, 1000, 2000, 3000, 4000, co2SensorUnitValue.max].map((value) => ({
    value: value,
    label: value + SYMBOL.CO2,
}));

interface SensorType {
    temperature: typeof tempUnitValue;
    humidity: typeof humiUnitValue;
    sensor1?: typeof dustUnitValue | typeof co2SensorUnitValue | typeof tempUnitValue;
    sensor2?: typeof dustUnitValue | typeof tempUnitValue;
    sensor3?: typeof dustUnitValue;
}

interface RangeType {
    temperature: typeof temperatureRange;
    humidity: typeof humidityRange;
    sensor1?: typeof dustRange | typeof co2Range | typeof temperatureRange;
    sensor2?: typeof dustRange | typeof temperatureRange;
    sensor3?: typeof dustRange;
}

const MultiRangeSection = (props) => {
    const { trait, label, slide, alarmCondition, disabled }: MultiRangeSectionProps = props;
    const name = trait?.name;

    const currentDevice = useAppSelector((state) => state.display.currentDevice);
    const [value, setValue] = useState<number[]>([alarmCondition?.min || 20, alarmCondition?.max || 90]);
    const [type, setType] = useState({});
    const [range, setRange] = useState({});

    const [minValue, maxValue] = value;

    const handleChangeSlide = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    const unitMap = {
        temperature: tempUnitValue.unit,
        humidity: humiUnitValue.unit,
        sensor1: dustUnitValue.unit,
        sensor2: dustUnitValue.unit,
        sensor3: dustUnitValue.unit,
        sensor4: co2SensorUnitValue.unit,
    };

    const selectedUnit = name ? unitMap[name] || "" : "";

    useEffect(() => {
        // 기본 값 설정
        const defaultType: SensorType = {
            temperature: tempUnitValue,
            humidity: humiUnitValue,
        };

        const defaultRange: RangeType = {
            temperature: temperatureRange,
            humidity: humidityRange,
        };

        // 타입과 범위를 저장할 객체
        let newType: SensorType = { ...defaultType };
        let newRange: RangeType = { ...defaultRange };

        // 디바이스 타입별 처리
        if (currentDevice?.modelCode === "r9iot-ssphs") {
            switch (currentDevice?.attributes?.device_type) {
                case DEVICE_TYPE_SSPHS.DUST:
                    newType = {
                        ...newType,
                        sensor1: dustUnitValue,
                        sensor2: dustUnitValue,
                        sensor3: dustUnitValue,
                    };
                    newRange = {
                        ...newRange,
                        sensor1: dustRange,
                        sensor2: dustRange,
                        sensor3: dustRange,
                    };
                    break;
                case DEVICE_TYPE_SSPHS.PH:
                    // 기본 값 사용
                    break;
                case DEVICE_TYPE_SSPHS.CO2:
                    newType = {
                        ...newType,
                        sensor1: co2SensorUnitValue,
                    };
                    newRange = {
                        ...newRange,
                        sensor1: co2Range,
                    };
                    break;
                case DEVICE_TYPE_SSPHS.NH3:
                case DEVICE_TYPE_SSPHS.SOIL:
                    // 기본 값 사용
                    break;
                case DEVICE_TYPE_SSPHS.EXTERNAL:
                    newType = {
                        ...newType,
                        sensor1: tempUnitValue,
                        sensor2: tempUnitValue,
                    };
                    newRange = {
                        ...newRange,
                        sensor1: temperatureRange,
                        sensor2: temperatureRange,
                    };
                    break;
                default:
                    // 기본 값 사용
                    break;
            }
        }

        // 상태 업데이트
        setType(newType);
        setRange(newRange);
    }, [currentDevice?.attributes?.device_type, currentDevice?.modelCode]);

    return (
        <MultiRangeSectionContainer>
            {/* 첫 번째 섹션 (20%) */}
            <LabelContainer>
                <label>{label}</label>
            </LabelContainer>

            {/* 두 번째 섹션 (60%) */}
            <SliderContainer>
                <Slider
                    disabled={disabled}
                    sx={{
                        width: "90%",
                        height: 3,
                        color: "#fff", // 기본 색상
                        "& .MuiSlider-thumb": {
                            backgroundColor: "#fff", // 핸들 색상
                        },
                        "& .MuiSlider-track": {
                            backgroundColor: "#fff", // 트랙 색상
                        },
                        "& .MuiSlider-rail": {
                            backgroundColor: "#ccc", // 트랙 뒤의 색상
                        },
                        // marks의 레이블 (슬라이더 점 위에 표시되는 텍스트)
                        "& .MuiSlider-markLabel": {
                            color: "#fff", // marks의 글자 색상 변경
                        },

                        // Disabled 상태일 때의 스타일링
                        "&.Mui-disabled": {
                            color: "#999", // 슬라이더 색상 회색으로
                            "& .MuiSlider-thumb": {
                                backgroundColor: "#999", // 핸들 색상 회색으로
                            },
                            "& .MuiSlider-track": {
                                backgroundColor: "#999", // 트랙 색상 회색으로
                            },
                            "& .MuiSlider-markLabel": {
                                color: "#999", // marks의 글자 색상 회색으로
                            },
                        },
                    }}
                    getAriaLabel={() => "Temperature range"}
                    value={value}
                    name={name}
                    onChange={handleChangeSlide}
                    onChangeCommitted={slide}
                    valueLabelDisplay="auto"
                    getAriaValueText={valueText}
                    marks={range[name]}
                    min={type.hasOwnProperty(name) ? type[name]?.min : 0}
                    max={type.hasOwnProperty(name) ? type[name]?.max : 100}
                />
            </SliderContainer>

            {/* 세 번째 섹션 (20%) */}
            <ChipContainer>
                <StyledChip>
                    <ChipLabel>min</ChipLabel>
                    <ChipValue>{`${minValue}${selectedUnit}`}</ChipValue>
                </StyledChip>
                <StyledChip>
                    <ChipLabel>max</ChipLabel>
                    <ChipValue>{`${maxValue}${selectedUnit}`}</ChipValue>
                </StyledChip>
            </ChipContainer>
        </MultiRangeSectionContainer>
    );
};

export default MultiRangeSection;
