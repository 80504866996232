import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Auth } from 'aws-amplify';
import ConfirmSignupDisplay from 'components/display/confirmSignupDisplay';
import { changeAuthState } from 'features/user-slice';
import { useEffect } from 'react';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const ConfirmSignUpCoantainer = () => {
    /* from react-router */
    const navigate = useNavigate();

    /* from redux */
    const dispatch = useAppDispatch();
    const signupState = useAppSelector((state) => state.signup);

    /* local state */
    const [code, setCode] = useState<string>('');
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [confirmLoader, setConfirmLoader] = useState<boolean>(false);
    const [codeSentLoader, setCodeSentLoader] = useState<boolean>(false);
    const [activateConfirmButton, setActivateRegisterButton] =
        useState<boolean>(false);

    /* confirm-signup submit 이후에 error가 발생하여 error message를 변화시킬 경우 error popup 띄움 */
    useEffect(() => {
        if (!errorMessage) return;

        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (showError) return;
        setErrorMessage('');
    }, [showError]);

    /* unmount 될 때 error message를 off함 */
    useEffect(() => {
        return () => setShowError(false);
    }, []);

    /* code와 email 이 null 값이면 confirm 버튼이 활성화되지 않음 */
    useEffect(() => {
        signupState?.username && code
            ? setActivateRegisterButton(true)
            : setActivateRegisterButton(false);
    }, [signupState, code]);

    function codeInputHandler(event) {
        setCode(event.target.value);
    }

    async function confirmSubmitHandler(event) {
        event.preventDefault();

        setConfirmLoader(true);

        const confirmSignupResult = await Auth.confirmSignUp(
            signupState.username,
            code
        ).catch((error) => setErrorMessage(error.message || error));

        setConfirmLoader(false);

        if (!confirmSignupResult) return;
        dispatch(changeAuthState('signIn'));
        navigate('/');
    }

    async function resendCodeHandler(event?) {
        setCodeSentLoader(true);

        const codeSentResult = await Auth.resendSignUp(
            signupState?.username
        ).catch((error) => setErrorMessage(error.message || error));

        setCodeSentLoader(false);

        if (!codeSentResult) return;
        setErrorMessage('Code sent');
        setCodeSent(true);
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    return (
        <ConfirmSignupDisplay
            showError={showError}
            closeError={closeErrorHandler}
            errorMessage={errorMessage}
            confirmLoader={confirmLoader}
            codeSentLoader={codeSentLoader}
            username={signupState?.username}
            code={code}
            codeSent={codeSent}
            confirmSubmit={confirmSubmitHandler}
            resendCode={resendCodeHandler}
            codeInput={codeInputHandler}
            activateConfirmButton={activateConfirmButton}
        />
    );
};

export default ConfirmSignUpCoantainer;
