import { MDBIcon } from 'mdb-react-ui-kit';
import { NavLink, useNavigate } from 'react-router-dom';
import { IconBtnProps } from './iconBtn';

export const PrevBtn = (props: IconBtnProps) => {
    const navigate = useNavigate();

    const { ariaLabel, onClick } = props;

    return (
        <span
            className='icon-btn'
            aria-label={ariaLabel}
            onClick={() => navigate(-1)}
        >
            <MDBIcon fas icon='chevron-left' />
        </span>
    );
};
