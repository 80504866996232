import { localized_language } from './localizer';

export interface ChangePasswordInputListProps {
    type: string;
    id: string;
    name: string;
    label: string;
    placeholder: string;
    required: boolean;
    value: string;
    message?: string;
    errorMessage?: string;
    validation?: boolean;
}
const changePasswordInputs: ChangePasswordInputListProps[] = [
    {
        type: 'password',
        id: 'oldPassword',
        name: 'oldPassword',
        label: localized_language[`en_current_password_label`],
        placeholder: localized_language[`en_current_password_label`],
        required: true,
        message: '',
        value: '',
        validation: null,
        errorMessage: '',
    },
    {
        type: 'password',
        id: 'newPassword',
        name: 'newPassword',
        label: localized_language[`en_new_password_label`],
        placeholder: localized_language[`en_new_password_label`],
        required: true,
        message: '',
        value: '',
        validation: null,
        errorMessage: '',
    },
    {
        type: 'password',
        id: 'passwordConfirm',
        name: 'passwordConfirm',
        label: localized_language[`en_confirm_password_label`],
        placeholder: localized_language[`en_confirm_password_label`],
        required: true,
        message: '',
        value: '',
        validation: null,
    },
];

export { changePasswordInputs };
