import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    // @ts-ignore
} from 'react-places-autocomplete';

interface AddressInputSelectProps {
    value: string;
    errorMessage?: string;
    onChange: (value: string) => void;
    onSelect: (value: string) => void;
    searchOptions;
    edit: boolean;
}

const AddressInputSelect = (props: AddressInputSelectProps) => {
    const { t, i18n } = useTranslation(['trans']);
    const { value, onChange, onSelect, searchOptions, errorMessage, edit } =
        props;

    const inputElement = useRef(null);
    const inputElementLocation = inputElement.current?.getBoundingClientRect();

    return (
        <PlacesAutocomplete
            value={value || ''}
            onChange={onChange}
            onSelect={onSelect}
            searchOptions={searchOptions}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => (
                <div className='input-section horizontal'>
                    <label className='form-label'>{t('Address')}</label>
                    <div className='input-group'>
                        <input
                            ref={inputElement}
                            {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'form-control w-100',
                            })}
                            required
                            // disabled={edit}
                        />
                        <div
                            className='autocomplete-dropdown-container'
                            style={{
                                maxHeight: '200px',
                                overflow: 'auto',
                                width: inputElement.current?.offsetWidth || 0,
                                position: 'fixed',
                                top:
                                    inputElementLocation?.y +
                                        inputElement.current?.offsetHeight || 0,
                                zIndex: 5000,
                                borderRadius: '5px',
                                background: '#fff',
                                /* boxShadow:
                                    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)', */
                            }}
                        >
                            {loading && (
                                <div
                                    style={{
                                        padding: '8px',
                                    }}
                                >
                                    Loading...
                                </div>
                            )}
                            {suggestions?.length > 0 && (
                                <div className='border'>
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active border-bottom'
                                            : 'suggestion-item border-bottom';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {
                                                  backgroundColor:
                                                      'rgba(0, 0, 0, 0.04)',
                                                  cursor: 'pointer',
                                                  padding: '8px',
                                              }
                                            : {
                                                  backgroundColor: '#ffffff',
                                                  cursor: 'pointer',
                                                  padding: '8px',
                                              };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                        className,
                                                        style,
                                                    }
                                                )}
                                                key={suggestion.placeId}
                                            >
                                                <span>
                                                    {suggestion.description}
                                                </span>
                                            </div>
                                        );
                                    })}
                                    <div
                                        style={{
                                            padding: '8px',
                                        }}
                                        className='text-end'
                                    >
                                        <div>
                                            <h6>powered by Google</h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default AddressInputSelect;
