export interface DeviceModels {
    value: string;
    text: string;
    useYn: boolean;
}

export const deviceModels = [
    { text: 'MODEL : GW', value: 'gw', useYn: true },
    { text: 'MODEL : WIFI', value: 'wifi', useYn: false},
];
