import logo from 'assets/img/raonix_logo.png';
import NormalInputSection from 'components/fragment/normalInputSection';
import { PrevBtn } from 'components/fragment/prevBtn';
import Spinner from 'components/fragment/spinner';
import { PasswordResetInputListProps } from 'constants/passwordResetInputs';

import {
    MDBAlert,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBContainer,
    MDBIcon,
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface PasswordResetDisplayProps {
    resetPasswordLoader: boolean;
    codeSentLoader: boolean;
    submitLoader: boolean;
    activateResetSubmitButton: boolean;
    resetPasswordSubmit: (event?) => void;
    resendCode: (event?) => void;
    inputChangeHandler: (event?) => void;
    showError: boolean;
    errorMessage: string;
    codeSent: boolean;
    passwordResetInputList: PasswordResetInputListProps[];
    closeError: () => void;
}

const loginUINew = true;
const PasswordResetDisplay = (props: PasswordResetDisplayProps) => {
    const {
        passwordResetInputList,
        showError,
        submitLoader,
        activateResetSubmitButton,
        codeSent,
        errorMessage,
        inputChangeHandler,
        resendCode,
        resetPasswordSubmit,
        closeError,
    } = props;

    const { t, i18n } = useTranslation(["trans"]);
    const spinner = <Spinner size='sm' inline color='primary' />;

    const inputs = passwordResetInputList?.map((inputElement, index) => {
        const key = inputElement.id + index;

        if (inputElement.name === 'username') {
            return (
                <NormalInputSection
                    key={key}
                    horizontal={true}
                    type={inputElement.type}
                    id={inputElement.id}
                    label={inputElement.label}
                    placeholder={inputElement.placeholder}
                    required={inputElement.required}
                    name={inputElement.name}
                    inputElementInput={inputChangeHandler}
                    message={inputElement.message || inputElement.errorMessage}
                    messageClassName={
                        inputElement.errorMessage
                            ? 'text-danger'
                            : 'text-success'
                    }
                >

                    <MDBBtn
                        type='button'
                        onClick={resendCode}
                        color={codeSent ? 'light' : 'light'}
                    >
                        <MDBIcon fas icon='paper-plane' />
                    </MDBBtn>
                </NormalInputSection>
            );
        }

        return (
            <NormalInputSection
                key={key}
                horizontal={true}
                type={inputElement.type}
                id={inputElement.id}
                label={inputElement.label}
                placeholder={inputElement.placeholder}
                required={inputElement.required}
                name={inputElement.name}
                inputElementInput={inputChangeHandler}
                message={inputElement.message}
                readonly={!inputElement.codeSent}
            />
        );
    });

    return (
        <>
            <MDBAlert
                color="warning"
                position="top-center"
                className="w-100 d-flex justify-content-between"
                offset={0}
                // delay={2000}
                appendToBody
                show={showError}
            >
                <div>{errorMessage}</div>
                <MDBBtn className="btn-close" noRipple outline color="none" onClick={closeError} />
            </MDBAlert>
            {loginUINew ? (
                <MDBContainer>
                    <MDBCard className="login-reset-card">
                        <form onSubmit={resetPasswordSubmit}>
                            <MDBCardHeader className="login-reset-header">
                                <div style={{ display: "inline-flex", alignItems: "center" }}>
                                    <PrevBtn /> {t("LOCAD0030", "뒤로")}
                                </div>
                                <div className="login-reset-header-title">Reset Password</div>
                            </MDBCardHeader>
                            <MDBCardBody className='login-reset-body'>{inputs}</MDBCardBody>
                            <MDBCardFooter className="login-reset-footer">
                                {submitLoader ? spinner : ""}
                                <button
                                    type="submit"
                                    className="reset-submit-button"
                                    disabled={!activateResetSubmitButton}
                                >
                                    SUBMIT
                                </button>
                            </MDBCardFooter>
                        </form>
                    </MDBCard>
                </MDBContainer>
            ) : (
                <MDBContainer>
                    <MDBCard className="phs-login">
                        <form onSubmit={resetPasswordSubmit}>
                            <MDBCardHeader className="d-flex justify-content-between">
                                <div
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <PrevBtn /> Reset Password
                                </div>
                                <img src={logo} alt="raonix_logo" height="40" loading="lazy" className="me-2" />
                            </MDBCardHeader>
                            <MDBCardBody>{inputs}</MDBCardBody>
                            <MDBCardFooter className="d-flex align-items-center">
                                {submitLoader ? spinner : ""}
                                <button type="submit" className="btn btn-primary" disabled={!activateResetSubmitButton}>
                                    submit
                                </button>
                            </MDBCardFooter>
                        </form>
                    </MDBCard>
                </MDBContainer>
            )}
        </>
    );
};

export default PasswordResetDisplay;
