import { API } from 'aws-amplify';
import config from '../config';

const apiName = 'RaonixCloud';

export interface PaymentParams {
    paymentUuid: string;
    userUuid: string;
    cid: string;
    sid: string;
    item_name: string;
    item_code: string | number;
    quantity: string;
    total_amount: number;
    tax_free_amount: number;
    approvedAt: string;
    paidAt: string;
    expiredAt: string;
    createdAt: string;
}

export function queryPaymentSubscriptionGet(principalId) {
    let path = '/kakao/payment/subscription';

    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId,
        },
        response: true,
    });
}

export function queryPaymentReadyPost(principalId, item) {
    let path = '/kakao/payment/ready';

    return API.post(apiName, path, {
        headers: {
            principalId: principalId,
        },
        queryStringParameters: {
            item,
        },
        response: true,
    });
}

export function queryPaymentReadyGet(uuid) {
    let path = '/kakao/payment/ready/' + uuid;

    return API.get(apiName, path, {
        queryStringParameters: {},
        headers: {
            principalId: '',
        },
        response: true,
    });
}

export function queryPaymentApprovePost(json) {
    let path = '/kakao/payment/approve';

    return API.post(apiName, path, {
        headers: {
            principalId: '',
        },
        response: true,
        body: {
            ...json,
        },
    });
}
