import logo from 'assets/img/raonix_logo.png';
import NormalInputSection from 'components/fragment/normalInputSection';
import { PrevBtn } from 'components/fragment/prevBtn';
import Spinner from 'components/fragment/spinner';
import {
    MDBAlert,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBContainer,
    MDBIcon,
} from 'mdb-react-ui-kit';

interface ConfirmSignupDisplayProps {
    username: string;
    code?: string;
    confirmLoader: boolean;
    codeSentLoader: boolean;
    activateConfirmButton: boolean;
    confirmSubmit: (event?) => void;
    resendCode: (event?) => void;
    codeInput: (event?) => void;
    closeError: () => void;
    showError: boolean;
    errorMessage: string;
    codeSent: boolean;
}

const ConfirmSignupDisplay = (props: ConfirmSignupDisplayProps) => {
    const {
        username,
        code,
        showError,
        confirmLoader,
        codeSentLoader,
        activateConfirmButton,
        codeSent,
        errorMessage,
        codeInput,
        resendCode,
        confirmSubmit,
        closeError,
    } = props;

    const spinner = <Spinner size='sm' inline color='primary' />;

    const successIcon = (
        <MDBIcon fas icon='check-circle' className='ms-2 text-success' />
    );

    let icon;

    if (codeSentLoader) {
        icon = spinner;
    } else if (codeSent) {
        icon = successIcon;
    } else {
        icon = '';
    }

    return (
        <>
            <MDBAlert
                color='warning'
                position='top-center'
                className='w-100 d-flex justify-content-between'
                offset={0}
                // delay={2000}
                appendToBody
                show={showError}
            >
                <div>{errorMessage}</div>
                <MDBBtn
                    className='btn-close'
                    noRipple
                    outline
                    color='none'
                    onClick={closeError}
                />
            </MDBAlert>
            <MDBContainer>
                <MDBCard className='phs-login'>
                    <form onSubmit={confirmSubmit}>
                        <MDBCardHeader className='d-flex justify-content-between'>
                            <div
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                            >
                                <PrevBtn /> Confirm SignUp
                            </div>
                            <img
                                src={logo}
                                alt='raonix_logo'
                                height='40'
                                loading='lazy'
                                className='me-2'
                            />
                        </MDBCardHeader>
                        <MDBCardBody>
                            <NormalInputSection
                                type='text'
                                id='username'
                                label='Email'
                                required={true}
                                name={'username'}
                                value={username}
                                readonly
                            />
                            <NormalInputSection
                                id={'confirmationCode'}
                                label={'Confirmation code'}
                                type={'text'}
                                name={'confirmationCode'}
                                inputElementInput={codeInput}
                                value={code}
                            />
                            <div className='mt-3'>
                                <small
                                    className='form-text text-primary'
                                    style={{ cursor: 'pointer' }}
                                    onClick={resendCode}
                                >
                                    Lost your code? Resend Code
                                    {icon}
                                </small>
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter className='d-flex align-items-center'>
                            {/* <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id='readTermsCheck'
                                disabled={!readTerms ?? false}
                            />
                            <label
                                className='form-check-label'
                                htmlFor='readTermsCheck'
                            >
                                I have read and agree to the{' '}
                                <NavLink to='/policy'>Terms of Service</NavLink>
                            </label>
                        </div> */}
                            {confirmLoader ? spinner : ''}
                            <button
                                type='submit'
                                className='ms-2 btn btn-primary'
                                disabled={!activateConfirmButton}
                            >
                                confirm
                            </button>
                        </MDBCardFooter>
                    </form>
                </MDBCard>
            </MDBContainer>
        </>
    );
};

export default ConfirmSignupDisplay;
