import { localized_language } from './localizer';

export interface ProfileDetailsInputListProps {
    type: string;
    id: string;
    name: string;
    label: string;
    placeholder: string;
    required: boolean;
    value: string;
    message?: string;
    validation?: boolean;
    readonly?: boolean;
    errorMessage?: string;
}

const profileDetailsInputs: ProfileDetailsInputListProps[] = [
    {
        type: 'email',
        id: 'email',
        name: 'username',
        label: 'Email',
        placeholder: 'email@email.com',
        required: true,
        value: '',
        validation: null,
        readonly: true,
    },
    {
        type: 'text',
        id: 'name',
        name: 'name',
        label: 'Name',
        placeholder: 'Andre Joe',
        required: true,
        value: '',
        validation: null,
    },
    {
        type: 'tel',
        id: 'phone_number',
        name: 'phone_number',
        label: 'Phone Number',
        placeholder: '010-0000-0000',
        required: true,
        value: '',
        validation: null,
        message: '',
    },
];

function localizeProfileDetailInputs(language: string) {
    return [
        {
            type: 'email',
            id: 'email',
            name: 'username',
            label: localized_language[`${language}_email_label`],
            placeholder: 'email@email.com',
            required: true,
            value: '',
            validation: null,
            readonly: true,
        },
        {
            type: 'text',
            id: 'name',
            name: 'name',
            label: localized_language[`${language}_name_label`],
            placeholder: 'Andre Joe',
            required: true,
            value: '',
            validation: null,
        },
        {
            type: 'tel',
            id: 'phone_number',
            name: 'phone_number',
            label: localized_language[`${language}_phone_number_label`],
            placeholder: '010-0000-0000',
            required: true,
            value: '',
            validation: null,
            message: '',
        },
    ];
}

export { profileDetailsInputs, localizeProfileDetailInputs };
