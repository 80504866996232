import { DeviceParams } from 'api/device';
import { MDBBtn, MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface HubDeviceTableProps {
    body?: any;
    loader?: boolean;
    showModal?: boolean;
    toggleModalHandler?: (event?) => void;
    selectHubDeviceListHandler?: (temp?) => void;
    subscribeClick: (device: DeviceParams) => void;
    unsubscribeClick: (device: DeviceParams) => void;
    currentDeviceSettingClickHandler: (device: DeviceParams) => void;
    toggleSettingModalHandler: (event?) => void;
}

const HubDeviceTable = (props: HubDeviceTableProps) => {
    const {
        body,
        loader,
        toggleModalHandler,
        selectHubDeviceListHandler,
        subscribeClick,
        unsubscribeClick,
        currentDeviceSettingClickHandler,
        toggleSettingModalHandler,
    } = props;
    const { t, i18n } = useTranslation(['trans']);

    const [maxHeight, setMaxHeight] = useState<string>('500px');
    const [sm, setSm] = useState<boolean>(false);

    // @ts-ignore
    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-height: 800px)').matches) {
            setMaxHeight('250px');
            setSm(true);
        } else {
            setMaxHeight('500px');
            setSm(false);
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    const actionData = {
        columns: [
            { label: t("LOCAD0044","이름"), field: 'nickname' },
            { label: t("LOCAD0045","모델"), field: 'modelCode' },
            { label: t("LOCAD0046","아이디"), field: 'serial' },
            { label: t("LOCAD0047", "장소"), field: 'placeNickname' },
            { label: '', field: 'buttons', sort: false },
        ],

        rows: (body ? body : []).map((row: DeviceParams, index: number) => {
            row.serial = row.deviceInfo.serial;

            return {
                index: index + 1,
                ...row,
                buttons: (
                    <div style={{ textAlign: "right" }}>
                        {row.subscribed ? (
                            <MDBBtn className="btn-danger" size="sm" onClick={() => unsubscribeClick(row)}>
                                {t("LOCAD0055","구독취소")}
                            </MDBBtn>
                        ) : (
                            <MDBBtn className="btn-secondary" size="sm" onClick={() => subscribeClick(row)}>
                                {t("LOCAD0054","구독")}
                            </MDBBtn>
                        )}

                        <NavLink to="/dashboard/deviceSetting">
                            <MDBBtn
                                size="sm"
                                className="ms-3 btn-secondary"
                                floating
                                onClick={() => {
                                    currentDeviceSettingClickHandler(row);
                                    //toggleSettingModalHandler();
                                }}
                            >
                                <MDBIcon fas icon="cog" />
                            </MDBBtn>
                        </NavLink>
                    </div>
                ),
            };
        }),
    };

    return (
        <MDBDatatable
            maxHeight={maxHeight}
            isLoading={loader}
            fixedHeader
            fullPagination
            hover
            entries={10}
            sm={sm}
            data={actionData}
        />
    );
};

export default HubDeviceTable;
