import {
    DeviceParams,
    queryDevices,
    queryDeviceSubscribe,
    queryDeviceUnsubscribe,
    queryGetDevice,
    queryGetPlace,
} from 'api/device';
import { PlaceParams, queryGetPlaceOne, queryGetPlaces } from 'api/place';
import r9iot, { HubParams } from 'api/r9iot';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import DeviceListDisplay from 'components/display/deviceListDisplay';
import { updateAddedHub, updateAllDeviceList, updateCurrentDevice } from 'features/display-slice';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import DeviceSettingsContainer from './deviceSettingsContainer';

export interface ChildDeviceList {
    [prop: string]: HubParams;
}

interface ConnectedDevice {
    hubId: string;
    connectedDevice: { [prop: string]: DeviceParams };
}

const DeviceListContainer = (props) => {
    /* from redux */
    const dispatch = useAppDispatch();
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const allDeviceList = useAppSelector((state) => state.display.allDeviceList);
    const addedHub = useAppSelector((state) => state.display.addedHub);

    /* local state */
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const deleteLoader = useState<boolean>(false);
    const [deviceListLoader, setDeviceListLoader] = useState<boolean>(false);
    const [showSettingModal, setShowSettingModal] = useState<boolean>(false);

    const [hubs, setHubs] = useState<DeviceParams[]>();
    const [childDeviceList, setChildDeviceList] = useState({});
    const [selectedHub, setSelectedHub] = useState<DeviceParams>();
    const [currentHubDevices, setCurrentHubDevices] = useState<DeviceParams[]>();
    const [selectedDevice, setSelectedDevice] = useState<DeviceParams>();

    async function getAllHubDevices(deviceList: DeviceParams[]) {
        if (!hubs) return;

        const promiseList = hubs?.map((device) => {
            return r9iot.getHubDevices(checkedUser.principalId, device.id).then((response) => {
                return { hubId: device.id, connectedDevice: response.data };
            });
        });

        const result = await Promise.all(promiseList).catch((error) => console.info(error));

        return result as ConnectedDevice[];

        // let res = await r9iot.getHubDevices(userUuid, hubId);

        /* let children = Object.keys(res.data).map((uuid) => {
            return res.data[uuid];
        }); */
    }

    /**
     * 모든 device list를 가져옴 && hub device 들을 저장함
     */
    useEffect(() => {
        async function run() {
            setLoader(true);

            const result = await queryDevices(checkedUser?.principalId).catch((error) => console.info(error));

            setLoader(false);

            if (!result) return;

            const deviceList: DeviceParams[] = Object.values(result);
            const hubDevices: DeviceParams[] = [];

            deviceList.forEach((device: DeviceParams) => {
                if (device.type === "gateway") {
                    return hubDevices.push(device);
                }
            });

            dispatch(updateAllDeviceList(deviceList));
            setHubs(hubDevices);
        }

        run();
    }, [checkedUser, addedHub]);

    useEffect(() => {
        async function run() {
            const hubDevices = await getAllHubDevices(allDeviceList || []);
            let duplicateHubs = {};

            hubDevices?.forEach((hub) => {
                if (_.isEmpty(hub.connectedDevice)) return;

                duplicateHubs = {
                    ...duplicateHubs,
                    [hub.hubId]: hub.connectedDevice,
                };
            });

            setChildDeviceList(duplicateHubs);

            // setChildDeviceList(hubDevices);
        }

        run();
    }, [allDeviceList]);

    /**
     * device list에서 허브-list 버튼을 클릭할 시 hub에 연결돼 있는 device를 불러옴
     */
    async function getConnectedHubDevices(hub: DeviceParams) {
        const hubDevicesResult = await r9iot
            .getHubDevices(checkedUser.principalId, hub.id)
            .then((response) => response.data)
            .catch((error) => console.info(error));

        if (!hubDevicesResult) return;

        const promiseList = Object.keys(hubDevicesResult)?.map(async (key: string) => {
            const response = await queryGetDevice(checkedUser.principalId, key);
            return response.data;
        });

        const devicesInfoResult = await Promise.all(promiseList).catch((error) => console.info(error));

        return (devicesInfoResult as DeviceParams[]) || null;
    }

    function addSubscribedProperty(hubDevices: DeviceParams[], allDeviceList: DeviceParams[]) {
        hubDevices.forEach((device) => {
            const find = allDeviceList.find((item) => item.uuid === device.uuid);

            if (find) return (device.subscribed = true);

            device.subscribed = false;
        });

        return hubDevices;
    }

    async function getDevicePlaces(deviceList: DeviceParams[]) {
        const promiseList = deviceList.map((device) => {
            return queryGetPlace(checkedUser.principalId, device.uuid)
                .then((response) => {
                    device.placeUuid = response.data?.placeUuid;
                    return device;
                })
                .catch((error) => {
                    if (error.response.status !== 404) {
                        console.info(error);
                    }

                    return device;
                });
        });

        const result = await Promise.all(promiseList).catch((error) => console.info(error));

        return (result as DeviceParams[]) || null;
    }

    async function getPlaceDetail(deviceList: DeviceParams[]) {
        const promiseList = deviceList.map((device) => {
            if (!device.placeUuid) {
                return new Promise((resolve, reject) => {
                    resolve(device);
                });
            }

            return queryGetPlaceOne(checkedUser.principalId, device?.placeUuid)
                .then((response) => {
                    const placeDetail: PlaceParams = response.data;
                    device.placeNickname = placeDetail.nickname;
                    return device;
                })
                .catch((error) => {
                    if (error.response.status !== 404) {
                        console.info(error);
                    }

                    return device;
                });
        });

        const result = await Promise.all(promiseList).catch((error) => console.info(error));

        return (result as DeviceParams[]) || null;
    }

    useEffect(() => {
        if (!selectedHub) return;

        //device를 data를 state에 저장하기 전에, all device와 비교를 통해 subscribe되어 있는지 아닌지에 대해 확인 후 subscribed property를 부여
        //location 정보를 같이 부여함
        async function run() {
            const result = await getConnectedHubDevices(selectedHub);
            const subscribedAddedCurrentDevices = addSubscribedProperty(result, allDeviceList);

            const deviceAddedPlaceUuidResult = await getDevicePlaces(subscribedAddedCurrentDevices);
            const deviceAddedPlaceDetailResult = await getPlaceDetail(deviceAddedPlaceUuidResult as DeviceParams[]);

            setCurrentHubDevices(deviceAddedPlaceDetailResult);
        }

        run();
    }, [allDeviceList, selectedHub]);

    /**
     * add device에서 hub를 추가하면 hub list와 hub device list 를 refresh 함
     * hub 추가 한 후 바로 hub device list 호출하도록 구현함
     */
    useEffect(() => {
        if (!addedHub) return;

        const findHub = allDeviceList?.find((device) => device.uuid === addedHub?.uuid);

        selectHubDeviceListHandler(findHub);
        setShowModal(!showModal);
        dispatch(updateAddedHub(null));
    }, [addedHub, allDeviceList]);

    /* handler */
    function toggleModalHandler() {
        setShowModal(!showModal);
    }

    function selectHubDeviceListHandler(hub: DeviceParams) {
        setSelectedHub(hub);
    }

    /**
     * device 구독
     * @param deviceUuid
     */
    async function subscribeClickHandler(device: DeviceParams) {
        const result = await queryDeviceSubscribe(checkedUser.principalId, device.uuid);

        const devices = [...currentHubDevices];

        if (result.status === 200) {
            const findDevice = devices.find((duplicatedDevice) => device.uuid === duplicatedDevice.uuid);

            findDevice.subscribed = true;

            return setCurrentHubDevices(devices);
        }

        console.info("Failed to subscribe device");
        alert("Failed to subscribe device. please refresh the page.");
    }

    /**
     * device 구독해제
     * @param deviceUuid
     */
    async function unsubscribeClickHandler(device: DeviceParams) {
        const result = await queryDeviceUnsubscribe(checkedUser.principalId, device.uuid);

        const devices = [...currentHubDevices];

        if (result.status === 200) {
            const findDevice = devices.find((duplicatedDevice) => device.uuid === duplicatedDevice.uuid);

            findDevice.subscribed = false;

            return setCurrentHubDevices(devices);
        }

        console.info("Failed to unsubscribe device.");
        alert("Failed to unsubscribe device. please refresh the page.");
    }

    async function hubDeleteClickHandler() {
        const duplicatedHubs = [...hubs];
        const children =
            (await r9iot.getHubDevices(checkedUser.principalId, selectedHub.id).then((result) => {
                return Object.keys(result.data).map((uuid) => result.data[uuid]);
            })) || [];

        const promiseList = [selectedHub, ...children].map((device: HubParams | DeviceParams) => {
            return queryDeviceUnsubscribe(checkedUser.principalId, device.uuid);
        });

        const result = await Promise.all(promiseList);

        if (result) {
            const findSelectedHubIndex = duplicatedHubs.findIndex((hub) => hub.uuid === selectedHub.uuid);

            duplicatedHubs.splice(findSelectedHubIndex, 1);
            setHubs(duplicatedHubs);
        }
    }

    function currentDeviceSettingClickHandler(device: DeviceParams) {
        setSelectedDevice(device);
        dispatch(updateCurrentDevice(device));
    }

    function toggleSettingModalHandler(event) {
        setShowSettingModal(!showSettingModal);
    }

    return (
        <>
            <DeviceListDisplay
                loader={loader}
                hubs={hubs}
                childDeviceList={childDeviceList}
                allDeviceList={allDeviceList}
                showModal={showModal}
                setShowModal={setShowModal}
                toggleModalHandler={toggleModalHandler}
                selectedHub={selectedHub}
                selectHubDeviceListHandler={selectHubDeviceListHandler}
                currentHubDevices={currentHubDevices}
                subscribeClickHandler={subscribeClickHandler}
                unsubscribeClickHandler={unsubscribeClickHandler}
                hubDeleteClickHandler={hubDeleteClickHandler}
                currentDeviceSettingClickHandler={currentDeviceSettingClickHandler}
                toggleSettingModalHandler={toggleSettingModalHandler}
            />
            {/**
             * asdfasdfasdf
            <DeviceSettingsContainer
                showModal={showSettingModal}
                setShowModal={setShowSettingModal}
                toggleModalHandler={toggleSettingModalHandler}
                currentDevice={selectedDevice}
            />
             */}
        </>
    );
};

export default DeviceListContainer;
