const kor_price_unit = '₩';
const en_price_unit = '$';

const kor_company = '네모안 클라우드';
const en_company = 'Nemoan Cloud';

const kor_month = '월';
const en_month = 'Month';

const kor_location = '장소';
const en_location = 'location';

const kor_free_price = 0;
const kor_basic_price = 9900;
const kor_premium_price = 19800;
const en_free_price = 0;
const en_basic_price = 9.9;
const en_premium_price = 19.9;

const kor_product_name_label = '상품명';
const kor_service_label = '서비스';
const kor_price_label = '가격';
const kor_free = '무료';
const kor_basic = '베이직';
const kor_premium = '프리미엄';
const kor_free_plan_list = [
    '실시간 기기 모니터링 및 제어',
    '10개 기기 등록',
    '앱 push 알람',
];

const kor_basic_plan_list = [
    'Free plan +',
    '20개 기기 등록',
    'e-mail 알람',
    '알람 메시지 저장',
    '기기 실시간 상태값이 클라우드 데이터베이스에 저장 주) 데이터는 3년 동안 유지되며 3년이후 데이타는 1일 단위로 과거 데이터 부터 삭제',
    '리포트 작성 기능',
];
const kor_premium_plan_list = [
    'Basic plan +',
    '50개 기기 등록',
    'SMS(문자 서비스) 100개/월 알람 주)추가구매 100개/월: ₩3,000',
];

const en_product_name_label = 'Product Name';
const en_service_label = 'Service';
const en_price_label = 'Price';
const en_free = 'Free';
const en_basic = 'Basic';
const en_premium = 'Premium';

const en_free_plan_list = [
    'Live monitoring and control',
    '10 device available',
    'App push alarm',
];
const en_basic_plan_list = [
    'Free plan +',
    '20 device available',
    'E-mail alarm',
    'Saving alarm message',
    '기기 실시간 상태값이 클라우드 데이터베이스에 저장 주) 데이터는 3년 동안 유지되며 3년이후 데이타는 1일 단위로 과거 데이터 부터 삭제',
    'Writing report',
];
const en_premium_plan_list = [
    'Basic plan +',
    '50 device available',
    'SMS(문자 서비스) 100개/월 알람 주)추가구매 100개/월: ₩3,000',
];

/* no result 결과 없음 */
const kor_no_result = '검색된 결과가 없습니다.';
const en_no_result = 'No results found';

/* location 장소 관련 */
const kor_no_location =
    '추가된 장소가 없습니다. \n 장소를 먼저 추가해 주십시오.';
const en_no_location = 'There is no location. \n Please Add Location first.';

/* navigation button 네비게이션 버튼 */
const kor_nav_location_button = '장소 추가';
const kor_nav_location_map_button = '맵으로 이동';
const en_nav_location_button = 'add location';
const en_nav_location_map_button = 'move to map';

/* display header 페이지 제목 */
const kor_profile_header = '프로필';
const kor_location_header = '장소';
const kor_location_map_header = '장소 맵';
const kor_report_header = '리포트';
const kor_report_List_header = '리포트 목록';
const kor_alarm_header = '알람 목록';
const kor_device_header = '장치 목록';
const kor_signout_header = '로그아웃';
const en_profile_header = 'Profile';
const en_location_header = 'Location';
const en_location_map_header = 'Location Map';
const en_report_header = 'Report';
const en_report_List_header = 'Report List';
const en_alarm_header = 'Alarms';
const en_device_header = 'Device';
const en_signout_header = 'Sign-out';

/* add device 기기 추가 */
const kor_add_device_button = '기기 추가';
const kor_device_model_select_label = '기기 모델 선택';
const kor_device_next_button = '다음';
const kor_gateway_label = '게이트웨이 ID';
const en_add_device_button = 'Add Device';
const en_device_model_select_label = 'Device Model Select';
const en_device_next_button = 'NEXT';
const en_gateway_label = 'GATEWAY ID';

/* edit, delete 수정 삭제 */
const kor_delete_button = '삭제';
const kor_edit_button = '수정';
const en_delete_button = 'Delete';
const en_edit_button = 'Edit';

/* available on free 무료 사용 가능 */
const kor_available_free = '무료 사용 가능!';
const en_available_free = 'Free to use!';

/* profile header */
const kor_profile_detail_header = '프로필';
const kor_change_password_header = '비밀번호 변경';
const kor_profile_alarm_header = '알람 설정';
const kor_billing_header = '결제';
const kor_payment_header = '결제';
const en_profile_detail_header = 'Profile Detail';
const en_change_password_header = 'Change Password';
const en_profile_alarm_header = 'Alarm Setting';
const en_billing_header = 'Billing';
const en_payment_header = 'Payment';

/* password change 비밀번호 변경  */
const kor_current_password_label = '현재 비밀번호';
const kor_new_password_label = '새 비밀번호';
const kor_confirm_password_label = '비밀번호 확인';
const kor_change_password_button = '변경';
const en_current_password_label = 'Current Password';
const en_new_password_label = 'New Password';
const en_confirm_password_label = 'Confirm Password';
const en_change_password_button = 'Change';

/* profile details 프로필 변경 */
const kor_email_label = '이메일';
const kor_name_label = '이름(닉네임)';
const kor_phone_number_label = '휴대전화(핸드폰)';
const kor_profile_details_button = '저장';
const en_email_label = 'Email';
const en_name_label = 'Name';
const en_phone_number_label = 'Phone Number';
const en_profile_details_button = 'Save';

/* alarm setting 알람 설정 */
const kor_app_push_label = '앱 push';
const en_app_push_label = 'app push';

const localized_language = {
    en_company,
    kor_month,
    en_month,
    kor_location,
    en_location,
    en_free_price,
    en_basic_price,
    en_premium_price,

    /* 한국어 */

    kor_free,
    kor_basic,
    kor_premium,
    kor_company,
    kor_price_unit,
    kor_product_name_label,
    kor_service_label,
    kor_price_label,
    kor_free_price,
    kor_basic_price,
    kor_premium_price,
    kor_free_plan_list,
    kor_basic_plan_list,
    kor_premium_plan_list,
    kor_payment_header,

    /* 영어 */
    en_free,
    en_basic,
    en_premium,
    en_price_unit,
    en_product_name_label,
    en_service_label,
    en_price_label,
    en_free_plan_list,
    en_basic_plan_list,
    en_premium_plan_list,
    en_payment_header,

    /* page headers 페이지 제목 */
    kor_profile_header,
    kor_location_header,
    kor_location_map_header,
    kor_report_header,
    kor_report_List_header,
    kor_alarm_header,
    kor_device_header,
    kor_signout_header,
    en_profile_header,
    en_location_header,
    en_location_map_header,
    en_report_header,
    en_report_List_header,
    en_alarm_header,
    en_device_header,
    en_signout_header,

    /* navigation button 네비게이션 버튼 */
    kor_nav_location_button,
    kor_nav_location_map_button,
    en_nav_location_button,
    en_nav_location_map_button,

    /* edit, delete 수정 삭제 */
    kor_delete_button,
    kor_edit_button,
    en_delete_button,
    en_edit_button,

    /* available on free 무료 이용 가능*/
    kor_available_free,
    en_available_free,

    /* profile header 프로필 제목*/
    kor_profile_detail_header,
    kor_change_password_header,
    kor_profile_alarm_header,
    kor_billing_header,
    en_profile_detail_header,
    en_change_password_header,
    en_profile_alarm_header,
    en_billing_header,

    /* password change 비밀번호 변경 */
    kor_current_password_label,
    kor_new_password_label,
    kor_confirm_password_label,
    kor_change_password_button,
    en_current_password_label,
    en_new_password_label,
    en_confirm_password_label,
    en_change_password_button,

    /* profile detail 프로필*/
    kor_email_label,
    kor_name_label,
    kor_phone_number_label,
    kor_profile_details_button,
    en_email_label,
    en_name_label,
    en_phone_number_label,
    en_profile_details_button,

    /* alarm setting 알람 설정 */
    kor_app_push_label,
    en_app_push_label,

    /* add device 기기 추가 */
    kor_add_device_button,
    kor_device_model_select_label,
    kor_device_next_button,
    kor_gateway_label,
    en_add_device_button,
    en_device_model_select_label,
    en_device_next_button,
    en_gateway_label,

    /* no result 결과 없음 */
    kor_no_result,
    en_no_result,

    /* location 장소 관련 */
    kor_no_location,
    en_no_location,
};

export { localized_language };

export interface languageType {
    name?: string;
    i18nType?: string;
}

export const Languages = {
    KOR: {
        name: "한국어",
        i18nType: "kor",
    },
    ENG: {
        name: "English",
        i18nType: "en",
    },
};
