import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import {
    resetSearchState,
    updateEndDate,
    updateStartDate,
} from 'features/search-slice';
import { MDBDatepicker } from 'mdb-react-ui-kit';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chips from '../components/fragment/chips';

interface DateSearchProps {
    searchSubmit?;
}

interface DateProps {
    date: Date;
    string: string;
}

interface DateRange {
    startDate: DateProps;
    endDate: DateProps;
}

export interface DateSearchQuery {
    uuid?: string;
    start?: string;
    end?: string;
}

//const format = 'DD/MM/YYYY';
const format = 'YYYY-MM-DD';

const DateSearchContainer = (props: DateSearchProps) => {
    const { searchSubmit } = props;

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    /* from redux */
    const dispatch = useAppDispatch();
    const startDate = useAppSelector((state) => state.search.startDate);
    const endDate = useAppSelector((state) => state.search.endDate);
    const currentDevice = useAppSelector((state) => state.display.currentDevice);

    /* ref */
    const searchButton = useRef(null);
    const defaultChip = useRef(null);

    /* state */
    const [selectedChip, setSelectedChip] = useState(null);

    const maxDate = dayjs(startDate?.date).add(29, "day").startOf("day")["$d"];

    const chipType = [
        { name: t("LOCAD0015", "오늘") },
        { name: t("LOCAD0016", "7일전"), default: true },
        { name: t("LOCAD0017", "1개월") },
        { name: t("LOCAD0018", "이번달") },
        { name: t("LOCAD0019", "지난달") },
    ];

    /* 초기 date 값 1주일로 설정 */
    useEffect(() => {
        if(!currentDevice) return;
        if (endDate || startDate ) return;

        const yesterday = dayjs().add(-1, "day").startOf("day");
        const sevenDaysAgo = dayjs().add(-7, "day").startOf("day");

        const start = sevenDaysAgo;
        const end = yesterday;

        let dateRange = {
            startDate: {
                date: start.format(),
                string: start.format(format),
            },
            endDate: {
                date: end.format(),
                string: end.format(format),
            },
        };

        console.log("start, end date initialize update");
        dispatch(updateStartDate(dateRange.startDate));
        dispatch(updateEndDate(dateRange.endDate));

        /*
        // 화면 들어오자마자 검색되도록 아래 함수를 실행함.
        // 이부분이 주석으로 처리되었었는데 이유는 모르겠음.
        */
       console.log("auto searchSubmit");
        searchSubmit(null, {
            uuid: currentDevice?.uuid,
            start: dateRange.startDate.date,
            end: dateRange.endDate.date
        });
    }, [dispatch, endDate, startDate, currentDevice, searchSubmit]);

    /* 초기에 'last 7 days' chip을 click함 */
    useEffect(() => {
        if (endDate || startDate) return;

        defaultChip.current.click();
    }, [endDate, startDate]);

    useEffect(() => {
        if (startDate?.date && endDate?.date && !startDate?.string && !endDate?.string) {
            searchButton.current.click();
            dispatch(resetSearchState());
        }
    }, [startDate, endDate]);

    /**
     * startDate가 endDate 보다 큰 input 입력되었을 때 서로 바꿔줌
     */
    useEffect(() => {
        if (!startDate || !endDate) return;

        if (startDate.date > endDate.date) {
            dispatch(updateStartDate({ ...endDate }));
            dispatch(updateEndDate({ ...startDate }));
        }
    }, [dispatch, endDate, startDate]);

    /**
     * unmount - reset search section
     */
    useEffect(() => {
        return () => {
            dispatch(resetSearchState());
        };
    }, [dispatch]);

    /**
     * 최대 검색범위를 30일로 지정함.
     */
    /**
     * mdb eventHandler -> render, re-render 시에도 componentDidMount 처럼 작동함(event 발동여부와는 관계없이)
     */
    const changeStartDateHandler = useCallback(
        (string, date) => {
            if (!string) return;

            const dayjsStartDate = dayjs(date).startOf("day");
            const dayjsEndDate = dayjs(dayjsStartDate).add(29, "day").startOf("day");

            date = dayjsStartDate.format();

            dispatch(updateStartDate({ date, string }));

            // 만약 endDate 가 변경한 startDate의 30일을 더한 날짜보다 초과한 상태일 경우 endDate를 startDate의 30일 더한 날짜로 변경함.
            if (endDate?.date > dayjsEndDate.format())
                dispatch(
                    updateEndDate({
                        date: dayjsEndDate.format(),
                        string: dayjsEndDate.format(format),
                    })
                );
        },
        [dispatch]
    );

    const changeEndDateHandler = useCallback(
        (string, date) => {
            if (!string) return;

            date = dayjs(date).endOf("day").format();

            dispatch(updateEndDate({ date, string }));
        },
        [dispatch]
    );

    /**
     * startDate와 endDate를 가진 object를 반환
     * @param type:string
     * @param format:string
     * @returns startDate: DateProps, endDate: DateProps
     */
    function setDateRange(type: string, format: string): DateRange {
        const today = dayjs().endOf("day");
        const todayStart = dayjs().startOf("day");
        const yesterday = dayjs().add(-1, "day").startOf("day");
        const sevenDaysAgo = dayjs().add(-6, "day").startOf("day");
        const thirtyDaysAgo = dayjs().add(-29, "day").startOf("day");
        const thisMonthDates = dayjs().startOf("month");
        const lastMonth = dayjs().add(-1, "month").startOf("month");
        const lastMonthEnd = dayjs().add(-1, "month").endOf("month");

        const dateType = {
            [t("LOCAD0015", "오늘")]: [todayStart, today],
            [t("LOCAD0016", "7일전")]: [sevenDaysAgo, today],
            [t("LOCAD0017", "1개월")]: [thirtyDaysAgo, today],
            [t("LOCAD0018", "이번달")]: [thisMonthDates, today],
            [t("LOCAD0019", "지난달")]: [lastMonth, lastMonthEnd],
        };

        const start = dateType[type][0];
        const end = dateType[type][1];

        let dateRange = {
            startDate: {
                date: start.toDate(),
                string: start.format(format),
            },
            endDate: {
                date: end.toDate(),
                string: end.format(format),
            },
        };

        return dateRange;
    }

    /* handler start */
    /**
     *
     * @param type
     */
    function clickDaysButtonHandler(type: string) {
        const dateRange = setDateRange(type, format);

        dispatch(updateStartDate(dateRange.startDate));
        dispatch(updateEndDate(dateRange.endDate));

        setSelectedChip(type);
    }

    /**
     * reset datepicker's state and inputs
     */
    function resetClickHandler() {
        dispatch(resetSearchState());
    }
    /* handler end */

    const chipsDisplay = chipType.map((chip) => (
        <Chips
            key={chip.name}
            chipClickHandler={(event) => clickDaysButtonHandler(chip.name)}
            defaultChip={chip.default}
            ref={chip.default && defaultChip}
            selectedChip={selectedChip}
        >
            {chip.name}
        </Chips>
    ));


    const query: DateSearchQuery = {
        uuid: currentDevice?.uuid,
        start: startDate?.date,
        end: endDate?.date,
    };

    return (
        <>
            {/**
        <form
            onSubmit={(event) => {
                searchSubmit(event, query);
            }}
        >
            <div className="date-search-wrapper">
                <div className="date-search-section">
                    <div className="date-search-input-group d-flex align-items-center justify-content-center">
                        <MDBDatepicker
                            inline
                            onChange={changeStartDateHandler}
                            value={startDate?.string || ""}
                            format="yyyy-mm-dd"
                        ></MDBDatepicker>
                        <span className="mx-2">-</span>
                        <MDBDatepicker
                            inline
                            onChange={changeEndDateHandler}
                            value={endDate?.string || ""}
                            format="yyyy-mm-dd"
                            min={startDate ? new Date(startDate.date) : null}
                            max={startDate ? new Date(maxDate) : null}
                        ></MDBDatepicker>
                        <button id="dateSearch" ref={searchButton} type="submit" className="btn ms-2">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div className="horizontal-line"></div>
                <div className="chips-container mt-2 d-flex">{chipsDisplay}</div>
            </div>
        </form>
             */}
            <div className="date-search-wrapper">
                <div className="date-search-section">
                    <div className="date-search-input-group d-flex align-items-center justify-content-center">
                        <MDBDatepicker
                            inline
                            onChange={changeStartDateHandler}
                            value={startDate?.string || ""}
                            format="yyyy-mm-dd"
                        ></MDBDatepicker>
                        <span className="mx-2">-</span>
                        <MDBDatepicker
                            inline
                            onChange={changeEndDateHandler}
                            value={endDate?.string || ""}
                            format="yyyy-mm-dd"
                            min={startDate ? new Date(startDate.date) : null}
                            max={startDate ? new Date(maxDate) : null}
                        ></MDBDatepicker>
                        <button
                            id="dateSearch"
                            ref={searchButton}
                            type="submit"
                            className="btn ms-2"
                            onClick={(event) => {
                                searchSubmit(event, query);
                            }}
                        >
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div className="horizontal-line"></div>
                <div className="chips-container mt-2 d-flex">{chipsDisplay}</div>
            </div>
        </>
    );
};

export default memo(DateSearchContainer);
