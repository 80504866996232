import { MDBIcon } from 'mdb-react-ui-kit';

export interface IconBtnProps {
    ariaLabel?: string;
    type?: string;
    onClick?: (event) => void;
    size?: string;
    children?: any;
    className?: string;
}

const type = {
    prev: 'chevron-left',
    close: 'times',
    search: 'search',
    down: 'caret-down',
    up: 'caret-up',
    settings: 'cog',
    report: 'chart-bar',
    alarm: 'bell',
    edit: 'edit',
    delete: 'trash-alt',
};

const size = {
    sm: 'sm',
    lg: 'lg',
};

const IconBtn = (props: IconBtnProps) => {
    const { ariaLabel, onClick } = props;

    const iconElement = props.children ? (
        <MDBIcon className='me-1' fas icon={type[props.type]} />
    ) : (
        <MDBIcon fas icon={type[props.type]} />
    );

    return (
        <span
            className={`${props.className || ''} icon-btn ${size[props.size]}`}
            aria-label={ariaLabel}
            onClick={onClick}
        >
            {iconElement}
            {props.children}
        </span>
    );
};

export default IconBtn;
