import { DeviceParams } from 'api/device';
import DeviceAddTable from 'components/fragment/deviceAddTable';
import { DeviceinfoDisplayProps } from 'containers/location/addDeviceContainer';
import { ReactElement } from 'react';

export interface AddDeviceListDisplayProps {
    deviceInfoList: DeviceParams[];
    searchResult?: any;
    addClick?: (event) => void;
    onClickSubscribe?: (event) => void;
    deviceInfoDisplayProps?: DeviceinfoDisplayProps;
}

const AddDeviceListDisplay = (props: AddDeviceListDisplayProps) => {
    const { deviceInfoList, addClick, onClickSubscribe, deviceInfoDisplayProps } = props;

    return (
        <DeviceAddTable
            body={deviceInfoList}
            addClick={addClick}
            onClickSubscribe={onClickSubscribe}
            deviceInfoDisplayProps={deviceInfoDisplayProps}
        />
    );
};

export default AddDeviceListDisplay;
