import { MDBBtn } from "mdb-react-ui-kit";
import styled from "styled-components";


const StyledButton = styled(MDBBtn)<{ inverted?: boolean }>`
    background-color: ${(props) => (props.inverted ? "#fff" : "#292929")} !important;
    color: ${(props) => (props.inverted ? "#292929" : "#fff")} !important;
    border: 1px solid ${(props) => (props.inverted ? "#b0b0b0" : "#404040")} !important;
    border-radius: 10px !important; /* 약간 둥근 모서리 */
    padding: 0.3rem 0rem; /* 적절한 패딩 추가 */
    box-shadow: none !important; /* 그림자 제거 */
    font-size: 1rem;
    margin: 0rem;

    &:hover {
        background-color: ${(props) => (props.inverted ? "#e0e0e0" : "#404040")} !important;
        border-color: ${(props) => (props.inverted ? "#a0a0a0" : "#505050")} !important;
    }
`;

// Props 타입 정의 (width가 선택적임)
type PhsStyledButtonProps = {
    children: React.ReactNode;
    onClick?: () => void;
    width?: string; // width는 선택적 속성
    inverted?: boolean;
    disabled?: boolean;
};

// PhsStyledButton 컴포넌트 정의
const PhsStyledButton: React.FC<PhsStyledButtonProps> = ({ children, onClick, width, inverted, ...rest }) => {
    return (
        <StyledButton
            onClick={onClick}
            inverted={inverted}
            style={{ width: width ? width : "auto" }} // width가 있을 때만 적용하고 없으면 기본값으로
            {...rest}
        >
            {children}
        </StyledButton>
    );
};

export default PhsStyledButton;
