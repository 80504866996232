import { MDBSpinner } from 'mdb-react-ui-kit';

export interface SpinnerProps {
    size?: string;
    fluid?: boolean;
    inline?: boolean;
    color?: string;
    tag?: string;
}

const size = {
    sm: 'sm',
    lg: 'lg',
};

const color = {
    primary: 'primary',
    danger: 'danger',
};

const Spinner = (props: SpinnerProps) => {
    let spinner = (
        <MDBSpinner role='status' size={size[props.size]}>
            <span className='visually-hidden text-white'>Loading...</span>
        </MDBSpinner>
    );

    if (props.fluid) {
        spinner = (
            <div className='spinner-container'>
                <MDBSpinner
                    role='status'
                    size={size[props.size]}
                    color={color[props.color]}
                >
                    <span className='visually-hidden text-white'>
                        Loading...
                    </span>
                </MDBSpinner>
            </div>
        );
    } else if (props?.inline) {
        spinner = (
            <span>
                <MDBSpinner role='status' size={size[props.size]}>
                    <span className='visually-hidden text-white'>
                        Loading...
                    </span>
                </MDBSpinner>
            </span>
        );
    }

    return <>{spinner}</>;
};

export default Spinner;
